import * as LIB from '_LIB';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Table, TableBody, TableCell, TableContainer, Menu, Paper, MenuItem, 
    TableRow, TableHead, Button, TableSortLabel,Link } from '@mui/material';
import { CSSProperties } from 'react';
import * as Icons from '@mui/icons-material/';
import * as React from 'react';
import * as App from 'AppReferences';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import * as XGrid from '@mui/x-data-grid';

export class XGridHelper {


    constructor() {

    }

  /*   
  public static getObjectChildPropertyColumnShortDate(label: string, name: string, childPropertyName: string, filter: boolean = false): GridColDef {

    var customBodyRender = (value: any, tableMeta: any, updateValue: any) => {

      var propVal = LIB.MUITableHelper.getObjectChildPropertyValue(value, childPropertyName);
      if (LIB.Common.isNullOrEmpty(propVal))
        return;

      return LIB.DateHelper.getFormattedShortDate(propVal);

    };
    var column = LIB.MUITableHelper.getColumnSetup(label, name, filter, "", customBodyRender);
    return column;
  } */


    public static getColumnSetup(label: string, name: string): GridColDef {

        var col: GridColDef = {
            headerName: label,
            field: name 
          }

          return col;

    } 
      
    public static formatNumber(value: any):any {

        if(!value)
            return;

        var val = parseFloat(value);

        if(isNaN(val))
            return 0; 
        // return val;
        return val?.toLocaleString('en-US');
    }


    public static renderLink(label: string, params: XGrid.GridRenderCellParams) {

        return <Link style={{ textDecoration: 'none' }} >
            {label}
            <span style={{ marginLeft: 3, verticalAlign: 'middle' }}>
                <Icons.InfoOutlined style={{ fontSize: 22 }} />
            </span>
        </Link>

    }


    public static getActionsButton(menuItems: any[]) {

        //console.log('getActionsButton:',tableMeta.rowData);
        //var requestId = value;
        //console.log(tableMeta);
    
        return (
          <PopupState variant="popover"  >
            {(popupState) => (
    
              <div  >
                <Button
                  {...bindTrigger(popupState)}
    
                >
                  <Icons.Menu />
                  <Icons.ArrowDropDown />
    
                </Button>
                <Menu  {...bindMenu(popupState)}
                  onClick={popupState.close}
                  id="actionMenu"
                  //getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
    
                >
                  {menuItems}
    
    
                </Menu>
              </div>
            )}
          </PopupState>
        );
    
      }


}