import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';




interface LoadingProps {
    show?: boolean;
    isModal?: boolean;
    style?: React.CSSProperties;
}


export class Loading extends React.Component<LoadingProps, {}> {


    public getModalLoading() {
        return <Dialog
            open={true}
            style={{
                position:'absolute', textAlign:"center", boxShadow:'none',
                verticalAlign:"top", zIndex: 5502,
                marginTop:'25vh', backgroundColor:'transparent', height:150
            }}
            BackdropProps={{ style: { opacity: 0.2, backgroundColor:'transparent'   } }}
            PaperProps={{ style: {  boxShadow:'none', backgroundColor:'transparent' } }}
        >
            <DialogContent style={{  boxShadow:'none', overflow:'hidden' }}   >

                <CircularProgress  style={{  boxShadow:'none'  }} size="3rem"  />

            </DialogContent>

        </Dialog>
    } 
    public render() {



        if (this.props.show === undefined || this.props.show === true) {

            if (this.props.isModal)
                return this.getModalLoading();
            else
                return <div>
                    <CircularProgress style={this.props.style} />
                </div>;
        }

        return null;
    }
}
