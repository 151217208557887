//import 'bootstrap/dist/css/bootstrap.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
//import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as App from 'AppReferences';
import MainApp from './App';
import * as LIB from '_LIB';


import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { createTheme , ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from "react-router-dom";

import Test from 'Pages/Test/Test';

//import registerServiceWorker from './registerServiceWorker';

// Create browser history to use in the Redux store
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;

const baseUrl = process.env.REACT_APP_APP_PATH;
const history = createBrowserHistory({ basename: baseUrl });



// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export default interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => App.AppState): void;
}


const theme = createTheme ({
  components: {
    // Name of the component
    MuiTextField: {
      defaultProps: {
        // The props to change the default for.
        variant: 'standard',  
      },
    },
  },
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#f44336',
    }

  },
});


var store = App.AppStore.configureStore(history);
App.AppBase.init(store, history, theme);

var rootElement: any = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <ScopedCssBaseline>
    <Provider store={store}>
    {/*    <ConnectedRouter history={history}>  
            <LIB.CustomRouter  history={App.AppBase.history} > 
     */}   
       <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SnackbarProvider  >
            <MainApp />
          </SnackbarProvider>
        </ThemeProvider>
       </BrowserRouter>
   {/* 
      </LIB.CustomRouter >  
   </ConnectedRouter>  
    */}  
    </Provider>

  </ScopedCssBaseline>
);

//registerServiceWorker();