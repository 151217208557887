import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


import { MyAccountJobListTable } from './MyAccountJobListTable';
import { MyAccountJobsAddEditModal } from './MyAccountJobsAddEditModal';



class MyAccountJobList extends React.Component<App.MyAccountState, {}> {

  public myListingsService: App.MyAccountService;

  constructor(props: any) {
    super(props);
    this.myListingsService = new App.MyAccountService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('MyAccountJobList componentDidMount');

    if (!this.props.hasJobsDataLoaded) {
      console.log('MyAccountJobList getMyAccountJobListData');
      this.myListingsService.getJobListData();
 
      this.forceUpdate();
    }


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('MyAccountJobList componentDidUpdate');

   

  } 

  
  public onCreateClick() {
    console.log('onCreateClick');
    var state = this.props;
    var vm = state.vm;
 
    vm.setSelectedJob();

    vm.isJobsAddOpen = true;
    this.forceUpdate();


  }

  
  public onDeleteConfirmClick() {
    console.log('onDeleteConfirmClick');
 
    var state = this.props;
    var vm = state.vm;
  
    var itemId = vm.selectedJob?.jobId;

    console.log('onDeleteConfirmClick itemId',itemId);

    App.AppStore.dispatch(App.ActionTypes.MY_ACCOUNT_JOB_DELETE_REQUEST,itemId);
  
    vm.isActionInProgress=true;

    this.forceUpdate();
 
  }

   

  public render() {

    console.log('MyAccountJobList render');

    var state = this.props;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;

    console.log('MyAccountJobList hasJobsDataLoaded', state.hasJobsDataLoaded);
    console.log('MyAccountJobList isServiceCallPending',state.isServiceCallPending);
    console.log('MyAccountJobList vm.isActionInProgress',vm.isActionInProgress);
 
    var createBtn: any = null;

    if (currentUser.hasRole(App.UserRoleTypeEnum.Vendor)) {
      createBtn = <Button
                  id="btnCreate"
                  variant="contained"
                  style={{ marginBottom: 10 }}
                  color="primary"

                  onClick={() => this.onCreateClick()}
                >Create</Button>
    }


    return <App.MainPage
      pageTitle="My Jobs"
      state={vm} 
      hasDataLoaded={state.hasJobsDataLoaded}
      loading={(state.isServiceCallPending || this.myListingsService.isServiceCallPending)}// && !vm.isActionInProgress)}
      errorMessage={state.apiErrorMessage}
     // isChildPage={true}
     showLeftMenu={true} 
    >
      <div>
   
        
        <div style={{ textAlign: 'right', width: '100%' }} >

          {createBtn}

        </div>

        <MyAccountJobListTable state={state} onChange={() => this.forceUpdate()} />



        <MyAccountJobsAddEditModal state={state} onChange={() => this.forceUpdate()} />


      </div>

      
      <LIB.ConfirmModal 
    visible={vm.isJobsDeleteOpen}
    onCancelClick={ ()=>{vm.isJobsDeleteOpen=false;this.forceUpdate();}}
    onConfirmClick={()=>this.onDeleteConfirmClick()}
    title={`Confirm Delete: ${vm.selectedJob?.jobId}`}
    content={`Are you sure you want to delete "${vm.selectedJob?.title}" job?`}
    confirmButtonLabel="Delete" 
    disabled={vm.isActionInProgress}
    />
 

    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.myAccountState })
)(MyAccountJobList as any);

