import * as LIB from '_LIB';


export class FormHelper {
 
    //multiple versions
    // example name=userRole, should set item.userRoleTypes and item.userRoleIds 
    public static setAutocompleteChangeForMultipleTypes(name:string, value:any, selectedTypes: any, item: any): string {


      if (LIB.Common.isNullOrEmpty(item)) {
        console.log("setAutocompleteChangeForMultipleTypes item is empty!");
        return '';
      }
      if (LIB.Common.isNullOrEmpty(name)) {
        console.log("setAutocompleteChangeForMultipleTypes name is empty!");
        return '';
      } 
  
      var result:string='';
      
      var idsProperty = name + "Ids";
      var typesProperty = name + "Types";
  

      item[idsProperty]=value;
      item[typesProperty]=selectedTypes;

      console.log("setAutocompleteChangeForMultipleTypes item:", item);
      console.log("setAutocompleteChangeForMultipleTypes value:", value);
      console.log("setAutocompleteChangeForMultipleTypes name:", name);
      console.log("setAutocompleteChangeForMultipleTypes selectedTypes:", selectedTypes);
  
      if (!Array.isArray(item[typesProperty])) {
        
        item[typesProperty]=[];
      }
       
   
      return name;
  
    }
      //new version, use this going forward 
      public static setAutocompleteChangeForType(
        name:string, value:any,
        selectedItem: any, selectedTypes: any,
        valueProperty: string): string {
  
  
        if (LIB.Common.isNullOrEmpty(selectedItem)) {
          console.log("onAutocompleteChange selectedItem is empty!");
          return '';
        }
        if (LIB.Common.isNullOrEmpty(name)) {
          console.log("onAutocompleteChange name is empty!");
          return '';
        }
       
    
        var result:string='';
        
        //console.log("onAutocompleteChange selectedTypes:", selectedTypes);
        //console.log("onAutocompleteChange valueProperty:", valueProperty);
        //console.log("onAutocompleteChange name:", name);
        //console.log("onAutocompleteChange value:", value);
    
        if (valueProperty !== name && !Array.isArray(selectedTypes) && !LIB.Common.isNullOrEmpty(selectedTypes)) {
          
          var idProperty = name + "Id";
    
          selectedItem[valueProperty] = selectedTypes[valueProperty];
          selectedItem[idProperty] = selectedTypes[valueProperty];
          selectedItem[name]=selectedTypes;
        }
        else{
          selectedItem[name]=value;
        }
     
        return name;
    
      }
      
  public static setAutocompleteChangeForIdAndType(name: string, selectedItem: any, selectedTypes: any,
    valueProperty: string = ''): string {
    //console.log("setAutocompleteChangeData");

    if (LIB.Common.isNullOrEmpty(selectedItem) || LIB.Common.isNullOrEmpty(name)) {
      console.log("setAutocompleteChangeForIdAndType selectedItem or name is empty!");
      return '';
    }

    //Part 1 - Update ID column
    var idProperty = name + "Id";

    //set id
    var selectedId = {
      name: idProperty,
      value: null,
    };

    //console.log("setAutocompleteChangeForIdAndType selectedTypes:", selectedTypes);
   // console.log("setAutocompleteChangeForIdAndType valueProperty:", valueProperty);

    if (!LIB.Common.isNullOrEmpty(selectedTypes) && !Array.isArray(selectedTypes)) {

      if (!LIB.Common.isNullOrEmpty(valueProperty))
        selectedId.value = selectedTypes[valueProperty];
      else
        selectedId.value = selectedTypes[idProperty];
    }

    //console.log("setAutocompleteChangeForIdAndType  selectedId.value:", selectedTypes[valueProperty]);

  
    ///console.log("setAutocompleteChangeForIdAndType selectedId:", selectedId,selectedItem);

    var key = LIB.FormHelper.setInputChangeData(selectedId, selectedItem);
    //--------------------- end ID update

    //Part 2 - update type object
    var selectedType = { name: name, value: selectedTypes };

   // console.log("setAutocompleteChangeForIdAndType selectedType:", selectedType);
   // console.log("setAutocompleteChangeForIdAndType key:", key);

    var key2 = LIB.FormHelper.setInputChangeData(selectedType, selectedItem);

    return key;
  }
 

  public static setInputChangeData(target: any, data: any): string {

    if (LIB.Common.isNullOrEmpty(target) || LIB.Common.isNullOrEmpty(data)) {
      return "";
    }
    var key = target.name;

    if (LIB.Common.isNullOrEmpty(key))
      key = target.id;

    if (LIB.Common.isNullOrEmpty(key))
      return "";
 
    // console.log('setInputChangeData data[key]', typeof(data[key]));
 
    if (target.type === 'checkbox')
      data[ key ] = target.checked;
    else
      data[ key ] = target.value;


    return key;
  }
  
  
   
 



}