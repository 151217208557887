//https://v4.mui.com/components/tree-view/#tree-view

import React from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import Label from '@mui/icons-material/Label';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SvgIconProps } from '@mui/material/SvgIcon';

import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';
import AdminIcon from '@mui/icons-material/PeopleAlt';
import { Drawer, Toolbar, List, Divider, ListItem, ListItemIcon, ListItemText, Button, Box,Badge } from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import { BrowserRouter, Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import * as App from 'AppReferences';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import { styled } from '@mui/material/styles';
import { CSSProperties } from "react";
import { connect } from 'react-redux';

import * as LIB from '_LIB';
import * as Api from 'app-api';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}


interface AccountTreeMenuProps {
  params: any;
  location: any;
  state: App.GlobalState

}
export const AccountTreeMenu: React.FC<AccountTreeMenuProps> = (props) => {
  //export const SmartTabs: React.FC<SmartTabsProps> = (props) => {


  var vm = props.state.globalUIState;
  var selectedTab: string | undefined;
  var locationState = props.location.state;
  var menuItems = getMenuItems();
  var rootMenuId = '/MyAccount';
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);


  React.useEffect(() => {
    console.log("AccountTreeMenu useEffect update");
   
    checkSetSelected();
  },[]);



  function checkSetSelected() {
    console.log("AccountTreeMenu checkSetSelected, vm", vm);

    console.log("AccountTreeMenu checkSetSelected params:", props.params);
    console.log("AccountTreeMenu checkSetSelected location:", props.location);
    console.log("AccountTreeMenu checkSetSelected selectedMyAccountRootMenuIds 1:", vm.selectedMyAccountRootMenuIds);

    
   /*  if(!LIB.Common.isNullOrEmpty(vm.selectedMyAccountRootMenuIds))
        return;
 */
    /*   if (!vm.selectedMyAccountMenuIds)
        vm.selectedMyAccountMenuIds = [];
    */
    var pathname = props.location?.pathname;

    console.log("AccountTreeMenu changePath 1:", pathname);
    console.log("AccountTreeMenu changePath 2:", vm.selectLeftMenuItem);

    if(!LIB.Common.isNullOrEmpty(vm.selectLeftMenuItem)){
      pathname = vm.selectLeftMenuItem.toString();

      console.log("AccountTreeMenu changePath:", pathname);
      vm.selectLeftMenuItem='';

      console.log("AccountTreeMenu changePath vm:", vm);
    }
    
      //pathname = '/MyAccount/ResumeRequests';

    var changed: boolean = selectOpenRoot(pathname);


  
    console.log("AccountTreeMenu checkSetSelected selectedMyAccountRootMenuIds 2:", vm.selectedMyAccountRootMenuIds);


    var isMenuItem = (menuItems.findIndex(x => x.props.nodeId == pathname) > -1);

    // console.log("AccountTreeMenu checkSetSelected isMenuItem:",isMenuItem,menuItems);

    //if (pathname?.includes('/MyAccount/ResumeRequests') && !vm.selectedMyAccountMenuIds?.includes('/MyAccount/ResumeRequests')) {
    if (isMenuItem && !vm.selectedMyAccountMenuIds?.includes(pathname)) {
      console.log("AccountTreeMenu checkSetSelected Menu Item Changed true:", vm.selectedMyAccountMenuIds);
      console.log("AccountTreeMenu checkSetSelected Menu Item Changed pathname:", pathname);

      vm.selectedMyAccountMenuIds = [ pathname ];
      changed = true;
    }
    else if (!isMenuItem) {
      vm.selectedMyAccountMenuIds = [];
    }



    if (changed && LIB.Common.isNullOrEmpty(vm.selectLeftMenuItem) ) {
      console.log("AccountTreeMenu checkSetSelected changed:", changed);
      App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);
      //forceUpdate();
    }

  }


  function selectOpenRoot(nodeId:any): boolean {

    var changed: boolean = false;
 
    console.log("AccountTreeMenu selectOpenRoot nodeId:", nodeId);
    console.log("AccountTreeMenu selectOpenRoot selectedMyAccountRootMenuIds 0:", vm.selectedMyAccountRootMenuIds);
    console.log("AccountTreeMenu selectOpenRoot rootMenuId:",rootMenuId);

    if ((nodeId?.includes(rootMenuId) || nodeId?.includes('/Account/Profile')) && vm.selectedMyAccountRootMenuIds != rootMenuId) {
      vm.selectedMyAccountRootMenuIds = rootMenuId;
      console.log("AccountTreeMenu selectOpenRoot MyAccount Root Open: YES");
      changed = true;
    }
    else if(vm.selectedMyAccountRootMenuIds == rootMenuId && nodeId==rootMenuId) {
      console.log("AccountTreeMenu selectOpenRoot MyAccount Root Open/Close",vm.selectedMyAccountRootMenuIds);
      changed = true;
      vm.selectedMyAccountRootMenuIds = '';
    
    }
    else  {
      
     vm.selectedMyAccountRootMenuIds = '';
     
    }

    console.log("AccountTreeMenu selectOpenRoot changed:",changed);

    return changed;

  }

  
  function onNodeSelect(event: React.SyntheticEvent, nodeId: any) {

    console.log('AccountTreeMenu onNodeSelect nodeIds', nodeId);
    console.log('AccountTreeMenu onNodeSelect event', event);

    var vm = props.state.globalUIState;
    var changed: boolean = false;

    if (nodeId == rootMenuId) {

      console.log('AccountTreeMenu onNodeSelect nodeId is Root....', nodeId);

      changed = selectOpenRoot(nodeId);
 

    }
    else {
      vm.selectedMyAccountMenuIds = [ nodeId ];
      App.RouteManager.routeToPath(nodeId);
      if (vm.isMobile) {
        vm.isMobileMenuOpen = false;
      }

    }
    // console.log('AccountTreeMenu.onNodeSelect selectedMyAccountRootMenuIds',vm.selectedMyAccountRootMenuIds);
    // console.log('AccountTreeMenu.onNodeSelect selectedMyAccountMenuIds',vm.selectedMyAccountMenuIds);
 
    //   App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);

    if (changed) {
      console.log("AccountTreeMenu onNodeSelect changed:", changed);
      App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);
    }

  }


  function getMenuItems(): any[] {

    var list: any[] = [

  
      <LIB.StyledTreeItem nodeId="/MyAccount/Jobs" key="/MyAccount/Jobs"
        //onClick={()=>onClick('/MyAccount/Jobs')}  
        labelText="My Jobs" labelIcon={Icons.Work} iconColor="#ff2a2a" />
      ,

  
      <LIB.StyledTreeItem  
         key="/Account/Profile"
        nodeId="/Account/Profile"
        // onClick={() => onClick('/Account/Profile')}
        labelText="Settings" labelIcon={Icons.Settings} iconColor="#ff2a2a" />

    ];
    return list;

  }

  function onClick(navigateTo: string) {
    console.log('AccountTreeMenu.onClick navigateTo:', navigateTo);

    var vm = props.state.globalUIState;

    App.RouteManager.routeToPath(navigateTo);
    if (vm.isMobile) {
      vm.isMobileMenuOpen = false;
    }

   // App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);

  }

  console.log("AccountTreeMenu RENDER..................");

  console.log("AccountTreeMenu checkSetSelected selectedMyAccountRootMenuIds 3:", vm.selectedMyAccountRootMenuIds);

  if(!LIB.Common.isNullOrEmpty(vm.selectLeftMenuItem)){

    console.log("AccountTreeMenu checkSetSelected selectLeftMenuItem:",vm.selectLeftMenuItem);

 
    checkSetSelected();
  }

  return (
    <TreeView
      //  className={classes.root}
      //  defaultExpanded={['3']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      //   defaultEndIcon={<div style={{ width: 24 }} />}
      onNodeSelect={onNodeSelect}

      // defaultExpanded={vm.selectedMyAccountRootMenuIds}
      expanded={[ vm.selectedMyAccountRootMenuIds ]}
      selected={vm.selectedMyAccountMenuIds}
    >

      <LIB.StyledTreeItem nodeId="/MyAccount" 
      //onClick={()=>onNodeSelect()}
      labelText="My Account" labelIcon={Icons.Home} iconColor="#ff2a2a" isroot={'true'} >

        {menuItems}

      </LIB.StyledTreeItem>

    </TreeView>
  );
}
