import * as React from 'react';
import { CircularProgress, Backdrop, Grid, Snackbar } from '@mui/material';
import * as LIB from '_LIB';
import * as App from 'AppReferences';
import Alert from '@mui/material/Alert';
import * as Api from 'app-api';
import LeftMenu from './Menus/LeftMenu';
import { Padding } from '@mui/icons-material';


interface MainPageProps {
    pageTitle?: any;
    state: any;//LIB.BaseVmModel;
    loading?: boolean;
    inProgress?: boolean;
    showContentWhenInProgress?: boolean;
    hideContentWhenError?: boolean;
    hasDataLoaded?: boolean;
    showModalForLoading?: boolean;
    children?: React.ReactNode
    showError?: boolean;
    errorMessage?: string;
    alignError?: string;
    snackMessage?: string;
    showLeftMenu?: boolean;
    isChildPage?:boolean;
}


export class MainPageState {
    isMobile: boolean=false;
  
  }  

export class MainPage extends React.Component<MainPageProps, MainPageState> {

    //public title:string;

    constructor(props: any) {
        super(props);

        this.state = new MainPageState();
     }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        //console.log('MainPage resize');
        var vm = {...this.state};
        vm.isMobile = (window.innerWidth <= 760);

        this.setState(vm);
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    public getLeftMenu() {

        if (!this.props.showLeftMenu)// || this.state.isMobile)
            return null;


        return <LeftMenu />;
    }


    public getAlert(error: string) {

        var showError = this.props.showError;
        if (LIB.Common.isNullOrEmpty(showError))
            showError = true;

        if (LIB.Common.isNullOrEmpty(error) || !showError)
            return null;

        var alignError: any = 'center';
        var alignMarginLeft: any = 0;
        if (!LIB.Common.isNullOrEmpty(this.props.alignError) && this.props.alignError !== undefined) {


            alignError = this.props.alignError;
            var parts = (alignError as string).split(' ');
            if (parts.length > 1) {
                alignError = parts[ 0 ];
                if (parts[ 1 ] === 'tab') {
                    alignMarginLeft = 200;
                }
            }

        }

        var alertCss: React.CSSProperties = { width: '40vw', wordBreak: 'break-all', marginLeft: alignMarginLeft };

        if (alignError === 'left')
            alignError = 'flex-start';

        //console.log("MainPage.getAlert: " + error);
        return <Grid container
            spacing={0}
            direction="row"
            alignItems={alignError}
            justifyContent={alignError}
            style={{ marginBottom: 20 }}
        >

            <Alert style={alertCss}
                severity="error">{error}</Alert>

        </Grid >

    }

    public getInProgress() {

        var inProgress = this.props.inProgress;
        var error = this.props.state?.apiErrorMessage;
        if (LIB.Common.isNullOrEmpty(inProgress) || !inProgress || !LIB.Common.isNullOrEmpty(error)) {
            return null;
        }

        return <Backdrop style={{ zIndex: 1001, color: '#ffffff', backgroundColor: '#ffffff00' }} open={true}  >
            <CircularProgress color="primary" />
        </Backdrop>
    }


    public getSnackbar() {

        var message = App.AppBase.snackMessage;

        // console.log("MainPage.getSnackbar message: ", message);

        // console.log("MainPage.getSnackbar message: ", message);
        if (LIB.Common.isNullOrEmpty(message) && this.props.snackMessage !== undefined)
            message = this.props.snackMessage;

        if (LIB.Common.isNullOrEmpty(message))
            return null;

        var isError = App.AppBase.snackError;
        var severity: any = 'success';

        if (isError)
            severity = 'error';

       
        return <Snackbar open={!LIB.Common.isNullOrEmpty(message)}
            // anchorOrigin={{vertical:'top', horizontal:'right' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={App.AppBase.snackMessageDuration} 
            onClose={() => { App.AppBase.resetSnackMessages(); this.forceUpdate() }}
            style={{ zIndex: 10000, marginTop: 80, marginRight: 20 }}
        >
            <Alert   onClose={() => { App.AppBase.resetSnackMessages(); this.forceUpdate() }}
             severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    }


    public getErrorMessage(globalState: App.GlobalState): any {
        // console.log("MainPage.getErrorMessage");

        var error = this.props.errorMessage;

        if (LIB.Common.isNullOrEmpty(error))
            error = this.props.state.apiErrorMessage;

        if (LIB.Common.isNullOrEmpty(error)) // if(error===undefined)
            error = globalState?.globalUIState?.apiErrorMessage;

        //console.log("MainPage.getErrorMessage error",error); 
        //console.log("MainPage props",this.props); 

        return error;
    }

    public render() {

        // var isMobile = this.getst
     //    console.log("MainPage.render isMobile:", this.state.isMobile);

        var globalState = App.AppBase.getState().globalData;
        // var error = this.props.state?.apiErrorMessage;

        var error = this.getErrorMessage(globalState);


        var children: any = this.props.children;
        var state = this.props.state;
        var loading = this.props.loading;
        var inProgress = this.props.inProgress;
        var showContentWhenInProgress = this.props.showContentWhenInProgress;
        var hasDataLoaded = this.props.hasDataLoaded;
        var hideContentWhenError = this.props.hideContentWhenError;

        if (hasDataLoaded === undefined)
            hasDataLoaded = true;

        if (loading === undefined)
            loading = false;

        if (!state || !hasDataLoaded || loading) {
            console.log("MainPage.render Data Not Loaded yet");
            children = undefined;
        }
        if ((!LIB.Common.isNullOrEmpty(error) && hideContentWhenError)) {
            console.log("MainPage.render error, and hideContentWhenError : ", error);
            children = undefined;
        }


        // console.log("MainPage.render loading", loading);
        // console.log("MainPage.render children", children);


        if (showContentWhenInProgress)
            children = this.props.children;

        var title: any = "";

        if (!LIB.Common.isNullOrEmpty(this.props.pageTitle))
            title = <h1>{this.props.pageTitle}</h1>;

        var loginWarning: any = "";


        if (!loading && App.RouteManager.isLoginWarningRoute()) {
            loginWarning = App.AppBase.getLoginToForFullAccessWarning();

        }

        var marginLeft = '250px'; //'13rem';//12rem'; //updated for new left menu
        var layoutClass = "";
        if (!this.props.showLeftMenu || this.state.isMobile){
            marginLeft = '0';
        }
          
        if(!this.props.isChildPage){
            layoutClass='MainLayoutContent';
        }

        return <div >

            {this.getLeftMenu()}


            <div className={layoutClass} style={{ marginLeft: marginLeft }}  >
 
                {loginWarning}
                {title}
                <LIB.Loading show={loading} isModal={this.props.showModalForLoading} />
                {this.getAlert(error)}
                {children}
                {this.getInProgress()}
                {this.getSnackbar()}
                {loginWarning}


            </div>

        </div>


    }
}
