import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


interface EmailUnsubscribeProps {
  //vm: App.ProjectListState;
}

class EmailUnsubscribe extends React.Component<App.GlobalState, {}> {

  public validationManager: App.ValidationManager;
  public emailService: App.EmailService;

  private sm = new Api.UnsubscribeSm();

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();
    this.emailService = new App.EmailService();
  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('EmailUnsubscribe componentDidMount');

    var state = this.props;
    //var sm = state.u
 
    console.log('EmailUnsubscribe componentDidUpdate sm',  this.sm);

    this.emailService.unsubscribe(this.sm);

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('EmailUnsubscribe componentDidUpdate');

   

  }

  public render() {

    console.log("EmailUnsubscribe Render");

    var sm = this.sm;
    sm.name = App.RouteManager.getRouterQueryParam('name');
    sm.email = App.RouteManager.getRouterQueryParam('email');

    console.log("EmailUnsubscribe Render sm", sm);


    if (LIB.Common.isNullOrEmpty(sm.name) || LIB.Common.isNullOrEmpty(sm.email)) {
 
      return <App.QuickMessageContainer
        iconType='error'
        message='Error, invalid Unsubscribe link!'
      >
        <div style={{ marginTop: 30, textAlign: 'center' }}>
         Please <a href={App.AppBase.appInfo.appWebsiteUrl+'/connect/ContactUs'} target={"_blank"}>contact us</a> if you continue receiving this error. 
        </div>
 
      </App.QuickMessageContainer>;
    }

 

      return <App.QuickMessageContainer
        iconType='success'
        message='Successfully Unsubscribed'
      >

        <div> <b>{sm.email}</b> will no longer receive these emails.</div>

        <div style={{ marginTop: 30, textAlign: 'center' }}>

          You can also control these notifications from your profile once logged in.

        </div>

        <div style={{marginTop:30, textAlign:'center'}}>
      <Button variant="contained" color="primary" 
                onClick={(e) => App.RouteManager.routeToPath("/Login")} 
       >Login</Button>
       </div>

      </App.QuickMessageContainer>;



    }
  };
export default connect(
  (state: App.AppState) => ({ ...state.globalData })
) (EmailUnsubscribe as any);
