import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class AdministrationState extends LIB.BaseStateModel {


  public vm: App.AdministrationVm;

  public appUsers: Api.AppUser[];
  public appUsersCount:number=0;
  public userRoleTypes: Api.UserRoleType[];
 
  public appUsersFullList: Api.AppUser[];
  public emailTemplates: Api.EmailTemplate[];
  public emailTemplatesFullList: Api.EmailTemplate[];
  public prospects: Api.Prospect[];
  public prospectsCount:number=0;
  public prospectsFullList: Api.Prospect[];

  public keywords: Api.Keyword[];
  public keywordsCount:number=0;
  public keywordsFullList: Api.Keyword[];
  

  public jobs: Api.Job[];
  public jobsCount:number=0;
  public jobsFullList: Api.Job[];
  public jobSearchFilter: Api.JobFilterVm = new Api.JobFilterVm();


  public appUsersSearchFilter: Api.AdminUserFilterVm = new Api.AdminUserFilterVm();
  public emailTemplateSearchFilter: Api.EmailTemplateFilterVm = new Api.EmailTemplateFilterVm();
  public prospectSearchFilter: Api.ProspectFilterVm = new Api.ProspectFilterVm();
  public keywordSearchFilter: Api.KeywordFilterVm = new Api.KeywordFilterVm();
   
  public hasUserDataLoaded: boolean = false;
  public hasEmailTemplateDataLoaded: boolean = false;
  public hasProspectDataLoaded: boolean = false;
  public hasCandidateDataLoaded: boolean = false;
  public hasJobDataLoaded: boolean = false;
  public hasKeywordDataLoaded: boolean = false;

  
   

  public adminUserSm: Api.AppUser = new Api.AppUser();
  public adminUpdateUserFormSm: Api.AdminUpdateUserFormSm = new Api.AdminUpdateUserFormSm();
  public adminCreateUserFormSm: Api.AdminCreateUserFormSm = new Api.AdminCreateUserFormSm();
  public adminUpdateProspectSm: Api.Prospect = new Api.Prospect();
  public adminCreateProspectSm: Api.Prospect = new Api.Prospect();
  public adminBulkImportProspectsSm: Api.BulkEmailProspectsSm = new Api.BulkEmailProspectsSm();

  public adminUpdateKeywordSm: Api.Keyword = new Api.Keyword();
  public adminCreateKeywordSm: Api.Keyword = new Api.Keyword();
  public adminBulkImportKeywordsSm: Api.BulkKeywordsSm = new Api.BulkKeywordsSm();


  public adminCreateEmailTemplateSm: Api.AdminCreateEmailTemplateSm = new Api.AdminCreateEmailTemplateSm();
  public adminUpdateEmailTemplateSm: Api.EmailTemplate = new Api.EmailTemplate();
  public jobSm: Api.Job = new Api.Job();


  public listingStatusTypes:Api.ListingStatusType[];

  public jobStatusTypes:Api.JobStatusType[];
  public jobTypes:Api.JobType[];
 

  public isSearching: boolean;

  public sailyEmailSm: Api.DailyEmailSm = new Api.DailyEmailSm();


  constructor() {

    super();

    this.vm = new App.AdministrationVm();
    this.sailyEmailSm.testEmail="test@InfluencerWork.com";
    this.adminCreateProspectSm.enableDailyEmailHotCandidates = true;
    this.adminCreateProspectSm.enableDailyEmailHotJobs = true;
    this.adminCreateEmailTemplateSm.isActive = true;


  }



}