import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Snackbar } from '@mui/material';
////import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as LIB from '_LIB';
import Alert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import { Checkbox, FormControlLabel, FormControl, FormHelperText, InputLabel } from '@mui/material';
import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import * as Quill from "quill";
import 'react-quill/dist/quill.snow.css';
import { createStyles, Theme } from '@mui/material/styles';
import { CSSProperties } from "react";


interface MRichTextEditorProps {
  error?: boolean,
  helperText: string;
  height?: number;
  label: string;
  name: string;
  value?: any;
  onChangeHtml?: Function;
  onChangeText?: Function;
  onChange?: Function;
  placeholder?: string;
  formControlStyle?: CSSProperties;
  onBlur?: Function;
  toolbarOptions?: any[];
  disableToolbar?: boolean;
  addTextLineBreaks?: boolean;
}

export class MRichTextEditor extends React.Component<MRichTextEditorProps, {}> {


  private reactQuillRef: ReactQuill | null;

  constructor(props: any) {
    super(props);
    //  this.state = { visible: false, isCancel:false };

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('componentDidUpdate:'+this.state.visible);


  }

  public onChange(value: string, delta: Quill.DeltaStatic, source: Quill.Sources, editor: UnprivilegedEditor) {
    var state = this.props;

    if (state.onChangeHtml) {
      let val = this.getCleanHtml(value);
      state.onChangeHtml(state.name,val);
    }

   
    if (state.onChangeText) {
      let textVal = editor?.getText();

      if(state.addTextLineBreaks)
          textVal = this.getCleanText(textVal);

      state.onChangeText(state.name,textVal);
    }
    if (state.onChange) {
      let val2 = this.getCleanHtml(value);
      state.onChange(state.name, val2, editor);
    }
 

    
  }

  private getCleanText(val: string) {
    console.log('RichText getCleanText val:', val);

    val = LIB.StringHelper.addHtmlLineBreaksToString(val);
 
    console.log('RichText getCleanText val2:', val);

    return val;
  }

  private getCleanHtml(val: string) {


    val = val?.replace(/<p><br><\/p>/g, '<br/>');

    val = val?.replace(/<p>/g, '');
    val = val?.replace(/<\/p>/g, '<br/>');

    return val;
  }

  public onChangeOld(val: any) {
    var state = this.props;

    console.log('RichText onChange val1:', val);
    //val =  val.replace(/ /g, '&nbsp;');
    val = val.replace(/<p><br><\/p>/g, '<br/>');

    val = val.replace(/<p>/g, '');
    val = val.replace(/<\/p>/g, '<br/>');


    // console.log('RichText onChange val2:', val);

    /*  if(this.reactQuillRef!=undefined){
 
       const editor = this.reactQuillRef.getEditor();
       const unprivilegedEditor = this.reactQuillRef.makeUnprivilegedEditor(editor);
       // You may now use the unprivilegedEditor proxy methods
       var text = unprivilegedEditor.getText();
   
     //  console.log("RichText .getText()", text);
 
     } */



    //state.onChange(state.name, val)

  }


  public onBlur(editor: UnprivilegedEditor) {

    if (this.props.onBlur !== undefined)
      this.props.onBlur(this.props.name,editor);

  }

  public render() {


    var state = this.props;

    var height: any = 200;
    if (!LIB.Common.isNullOrEmpty(state.height) && state.height !== undefined)
      height = state.height;

    var reactQ: any;

    // var toolbarOptions = [[]]; //[['bold', 'italic'], ['link', 'image']];
    var toolbarOptions = [ [ 'bold', 'italic' ], [ 'link', 'image' ] ];

    var modules: any = {};

    if (this.props.disableToolbar) {
      modules.toolbar = [];
    }
    else if (this.props.toolbarOptions) {
      modules.toolbar = this.props.toolbarOptions;
    }

    return (
      <FormControl style={state.formControlStyle} error={this.props.error} >

        <InputLabel style={{ marginTop: -50 }}>{state.label}</InputLabel>

        <ReactQuill theme="snow"
          ref={(el) => { this.reactQuillRef = el }}

          //id='my-input'
          modules={modules}
          value={state.value || ''}
          onChange={this.onChange.bind(this)}
          style={{ height: height }}
          placeholder={state.placeholder}

          onBlur={(previousRange: Quill.RangeStatic, source: Quill.Sources,
            editor: UnprivilegedEditor
          ) => this.onBlur(editor)
          }
        />

        <FormHelperText
          style={{ marginTop: 45 }}
        >{state.helperText}</FormHelperText>

      </FormControl>
    );



  }
} 
