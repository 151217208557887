import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Snackbar
} from '@mui/material';
import { Table, Card, CardContent, Paper, Container, Box, Backdrop, CircularProgress, InputAdornment, FormControl, FormHelperText } from '@mui/material';

import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface AdministrationProspectsCreateModalProps {
  state: App.AdministrationState;
  onChange?: Function;
}


export class AdministrationProspectsCreateModal extends React.Component<AdministrationProspectsCreateModalProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationProspectsCreateModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationProspectsCreateModal componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onCloseClick() {
    console.log("onCloseClick");
    var state = this.props.state;
    var vm = state.vm;
    var sm = state.adminCreateProspectSm;

    //reset to defaults
    sm.firstName = '';
    sm.lastName = '';
    sm.email = '';
    sm.isVendor = false;
    sm.enableDailyEmailHotJobs = true;
    vm.isProspectsAddOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

    if (this.props.onChange)
      this.props.onChange();

  }


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = state.adminCreateProspectSm;

    var target: any = e.target;

    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    console.log('onInputChange key', key);
    //console.log('onInputChange item', item);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateProspect(key, item);

    this.forceUpdate();
  }

  public onRegisterClick(e: React.ChangeEvent<{}>) {
    console.log('onRegisterClick');

    var state = this.props.state;
    var vm = state.vm;
    var sm = state.adminCreateProspectSm;

    var isValid = this.validationManager.validateProspect(null, sm);

    if (!isValid) {
      console.log('validateRegister NOT valid, ', App.AppBase.lastErrorMessage);
      this.forceUpdate();
      return;
    }

    //call api
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_PROSPECTS_ADD_REQUEST, sm);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }


  public onCheckClick(e: React.ChangeEvent<{}>) {
    console.log('onCheckClick');

    var state = this.props.state;
    var vm = state.vm;
    var sm = { ...state.adminCreateProspectSm };

    sm.isCheckExistsRequestOnly = true;
    //call api
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_PROSPECTS_ADD_REQUEST, sm);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }



  public getForm() {
    console.log('AdministrationProspectsCreateModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var sm = state.adminCreateProspectSm;

    console.log(sm);



    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    /* var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    }; */

    var formControl: CSSProperties = {
      margin: theme.spacing(2),
      //minWidth:'25rem',
      height: '70px',//set a fixed height so error helpertext does not trigger auto resize
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    /* var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    }; */

    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasProspectDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
      //  errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      {

        <form >
          {/*   add div https://stackoverflow.com/questions/56156589/simple-material-ui-dialog-example-has-unwanted-scrollbar
 */}<div style={{ padding: 15 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>

                <TextField
                  name="firstName"
                  label="First Name"
                  value={sm.firstName || ''}
                  {...App.AppBase.getInputErrorProps('firstName')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}
                />

              </Grid>

              <Grid item xs={12} sm={6} >
                <TextField
                  name="lastName"
                  label="Last Name"
                  value={sm.lastName || ''}
                  {...App.AppBase.getInputErrorProps('lastName')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}

                />
              </Grid>

            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  value={sm.email || ''}
                  {...App.AppBase.getInputErrorProps("email")}
                  onChange={(e: any) => this.onInputChange(e)}
                  ///style={fullWidth}
                  fullWidth
                  style={formControl}
                  //variant="outlined"
                  inputProps={{ style: formInput }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="phone" label="Phone"
                  value={sm.phone || ''}
                  {...App.AppBase.getInputErrorProps('phone')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  fullWidth
                  inputProps={{ style: formInput }}

                />
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 10, marginLeft: 16 }}>

                <LIB.MCheckbox {...App.AppBase.getInputErrorProps('isVendor')}
                  formControlLabel={<FormControlLabel
                    control={
                      <Checkbox
                        name="isVendor"
                        color="primary"
                        checked={sm.isVendor}
                        onChange={(e: any) => this.onInputChange(e)}
                        //style={fullWidth}
                        inputProps={{ style: formInput }}

                      />
                    }
                    style={{ marginLeft: 0 }}
                    labelPlacement="end"
                    label={<span style={{ fontSize: '16px' }}>
                      Is Vendor
                    </span>}
                  />}
                >
                </LIB.MCheckbox>

                 
                <LIB.MCheckbox {...App.AppBase.getInputErrorProps('enableDailyEmailHotJobs')}
                  formControlLabel={<FormControlLabel
                    control={
                      <Checkbox
                        name="enableDailyEmailHotJobs"
                        color="primary"
                        checked={sm.enableDailyEmailHotJobs}
                        onChange={(e: any) => this.onInputChange(e)}
                        //style={fullWidth}
                        inputProps={{ style: formInput }}

                      />
                    }
                    style={{ marginLeft: 0 }}
                    labelPlacement="end"
                    label={<span style={{ fontSize: '16px' }}>
                      Receive Daily Hot Jobs Emails?
                    </span>}
                  />}
                >
                </LIB.MCheckbox>

                
               {/*  <FormControlLabel 
                  control={
                    <Switch
                      checked={sm.isActive}
                      onChange={(e: any) => this.onInputChange(e)}
                      //   inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                      name="isActive"
                    />
                  }
                  label="Is Active"
                /> */}

                
              </Grid>

            </Grid >
          </div>
        </form>

      }

    </App.MainPage>;


  }


  /* public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  } */

  public render() {
    console.log('AdministrationProspectsCreateModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = vm.isProspectsAddOpen;
    var sm = state.adminCreateProspectSm;

    if (!isOpen)
      return;

    var title = "Create Prospect";

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >{title}</DialogTitle>
          <DialogContent style={{ height: "60vh", minHeight: 300, minWidth: 800 }} >

            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <Button
              variant="contained"
              //color="default"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</Button>

            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={this.onCheckClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Check</Button>


            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={this.onRegisterClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Add</Button>



          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 