import * as App from 'AppReferences';

export class AppModulePermission  {

    public appModulePermissionId:number;
    public appModuleTypeId:number;
    //public assignedToRoleId?:number;
    //public assignedToUserId?:number;
    public canCreate:boolean = false;
    public canRead:boolean = false;
    public canUpdate:boolean = false;
    public canDelete:boolean = false;
 
    public assignedToUser:App.AppUser | undefined;

    public appModuleType:App.AppModuleType | undefined;
     
    constructor() {  
 
      
    }
      

}