import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class AdminUserState extends LIB.BaseStateModel {
 
    public userRoleTypes:App.UserRoleType[];
    public appUsers:App.AppUser[];
 
    public vm:App.AdminUserVm;

       
    constructor() { 

        super();
 
        this.userRoleTypes=[];
        this.appUsers =[];
        this.vm = new App.AdminUserVm();
       
       
 
    }
     


}