import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


class AdministrationRoles extends React.Component<App.AdministrationState, {}> {


  constructor(props: any) {
    super(props);

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationRoles componentDidMount');
 

     
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationRoles componentDidUpdate');
 
 
  }
 

  public onInputChange(newVal: any) {
    console.log('onInputChange:' + newVal);


  } 
   

  public getTopRow(){

    var fullWidth: CSSProperties = {
     // paddingRight: 20,
      paddingLeft: 0,
     //  marginBottom: 10,
   //  padding: 10,
    
      width: '30vw'
    };
    var formInput: CSSProperties = {
      height: 50,
    //  marginTop: 10,
      //paddingTop: 20,
      padding: 10,
    };

    return <Grid container spacing={2} style={{marginBottom:10}}>
    <Grid item xs={12} sm={6} >
      
      <TextField
        name="keyword" 
        //label="Search"
        //value={item.location || ''}
        placeholder='Search'
        {...App.AppBase.getInputErrorProps('keyword')}
        onChange={this.onInputChange.bind(this)}
        style={fullWidth}
        inputProps={{ style: formInput }}
        //variant="outlined"
        
        InputProps={{
         // className: 'standardFormInput30',
          startAdornment: (
            <InputAdornment position="start">
              <Icons.Search />
            </InputAdornment>
          ),
            endAdornment: (
            <InputAdornment position="end">
            <Button
              id="btnCreate"
               variant="contained"
              //style={{padding:10}}
              color="primary"
              
            //  onClick={this.onCreateClick.bind(this)}
            >Search</Button>
            </InputAdornment>
          ), 
        }}

      />

       {/*   <Button
              id="btnCreate"
              variant="contained"
              style={{marginTop:13, marginLeft:10}}
              color="primary"
              
            //  onClick={this.onCreateClick.bind(this)}
            >Search</Button>  */}
 
    </Grid>
 
    <Grid item xs={12} sm={4} >

 


    </Grid>

  </Grid>

  }

  /* public onSearchClick(){
    var state = this.props;
    var vm = state.vm;
    //var searchFilter = state.searchFilter;
 
   // this.candidateService.getAdministrationRolesData(searchFilter);


  } */

  /* public onTest(){

    

    var location = App.AppBase.location;

    console.log('onTest location',location);


    var params = App.AppBase.params;

    console.log('onTest params',params);

    App.RouteManager.routeToPath('?requestResumeId=67519');

  

    console.log('onTest location2',location);
    console.log('onTest params2',params);

    this.forceUpdate();
  } */


  public render() {

    console.log('AdministrationRoles render');

    var state = this.props;
    var vm = state.vm;
    //var searchFilter = state.searchFilter;
  
    var keyword = '';

    return <App.MainPage
      pageTitle="Administration > Roles"
      state={vm} 
      hasDataLoaded={state.hasDataLoaded}
      loading={(state.isServiceCallPending && !vm.isActionInProgress)}
      alignError='left'
      isChildPage={true}
    >
      <div> 

      </div>

      

    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.administrationState })
)(AdministrationRoles as any);

