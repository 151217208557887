import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Menu, Button, Grid, Divider } from '@mui/material';
 

import { createStyles,  Theme } from '@mui/material/styles';
  
interface AboutMenuItemProps {
  apiEnvName: string;
}

export class AboutMenuItemState {
  open: boolean;
  anchorEl: null | HTMLElement;
  constructor() {
    this.open = false;
    this.anchorEl = null;
  }
}  

export class AboutMenuItem extends React.Component<AboutMenuItemProps, AboutMenuItemState> {

  constructor(props: any) {
    super(props);

     this.state = new AboutMenuItemState();

  }
  
  public getChildMenuBox(){
 
    var itemStyle:any ={float:'left',width:200, lineHeight:2};
    var liStyle:any ={lineHeight:2};
 //<div className="TopMenuChildMenu" style={{height:150}}> <React.Fragment>
    return  <div className="TopMenuChildMenu" style={{height:120}}> 

      <li style={liStyle}><span style={itemStyle}>UI Environment</span> {process.env.REACT_APP_ENV_NAME}    </li>
      <li style={liStyle}><span style={itemStyle}>API Environment</span> {this.props.apiEnvName}    </li>

      <li style={liStyle}><span style={itemStyle}>Version</span> {process.env.REACT_APP_VERSION}   </li>
      <li style={liStyle}><span style={itemStyle}>Last Release Date</span> {process.env.REACT_APP_DATE}    </li>
    
      </div>
  }


  
  public onMenuClick(event: React.MouseEvent<HTMLElement> | null) {

    var vm = { ...this.state };
    if (event == null)
      vm.anchorEl = null;
    else
      vm.anchorEl = event.currentTarget;
    vm.open = Boolean(vm.anchorEl);

    this.setState(vm);

  }
 


  public render() {
 
  
    const open = Boolean(this.state.anchorEl);

    return <div>
      <UI.IconButton
        onClick={this.onMenuClick.bind(this)}
        color="inherit"
        className="TopMenuBarUserButton" 
      >
        
        <Icons.Help />
 
      </UI.IconButton>
      <UI.Menu
        anchorEl={this.state.anchorEl}
        open={this.state.open}
        onClose={() => this.onMenuClick(null)}
        style={{ zIndex: 99999 }}
        PopoverClasses={{
          paper: "TopMenuBarMenuPopover"
        }}
      >
        {this.getChildMenuBox()}
       
      </UI.Menu>
    </div>
  }
 
 
}

