import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import { matchPath, Route, Navigate, Outlet, Router, Location } from 'react-router';
import { useNavigate, useLocation, useParams,BrowserRouter, Routes } from "react-router-dom";
import { Component } from 'react';
 

//Pages
import Login from '../Pages/User/Login/Login';
import ForgotPassword from '../Pages/User/ForgotPassword/ForgotPassword';
import ForgotPasswordSuccess from '../Pages/User/ForgotPassword/ForgotPasswordSuccess';
import Register from '../Pages/User/Register/Register';
import RegisterInviteRequest from '../Pages/User/Register/RegisterInviteRequest';
 
import Test from '../Pages/Test/Test';
import PasswordReset from '../Pages/User/PasswordReset/PasswordReset';
import PasswordResetSuccess from '../Pages/User/PasswordReset/PasswordResetSuccess';
import ConfirmEmail from '../Pages/Account/ConfirmEmail/ConfirmEmail';
import ConfirmEmailToken from '../Pages/Account/ConfirmEmail/ConfirmEmailToken';
import NotFound from '../Pages/Static/NotFound';
import AccessDenied from '../Pages/Static/AccessDenied';
import RedirectPage from '../Pages/Static/RedirectPage';


export class RouteManager {


    public static isLoginWarningRoute():boolean{
 
        var currentPage = RouteManager.getCurrentUrlPage();

        if(LIB.Common.isNullOrEmpty(currentPage))
            return false;

            currentPage = currentPage.toLowerCase();

           // console.log('isLoginWarningRoute  currentPage', currentPage);

        var list:string[]=[];
        list.push('/candidates');
        list.push('/jobs');

        var result = (list.indexOf(currentPage)>-1);

       // console.log('isLoginWarningRoute  result', result);
  
         return result;
 

        
    }
 
    public static getCurrentUrlPage(): string {
        let location = RouteManager.getLocation();
         
        //var pathname = App.AppBase.history.location.pathname;
        var pathname =location.pathname;

        return pathname;
 
    }
    public static getLocation():Location {
        let location = App.AppBase.location;
          
        return location; 
    }

    public static getQueryString(key: string, location: any = window.location.search): any {

        const urlParams = new URLSearchParams(location);
        const myParam = urlParams.get(key);

        return myParam;
    }

    public static getRouterQueryParam(key: string): any {

        var search = RouteManager.getLocation().search; 

        if (LIB.Common.isNullOrEmpty(search))
            return null;
        return RouteManager.getQueryString(key, search);

    }

   /*  
   //can't use hooks
    public static routeTo(to:string) {
         let navigate = useNavigate();
         navigate(to);
       
     }
     public static getLocation() {
        let location = useLocation();
       return location;
      
    } */
/* 
     public static navigated(to:string):any {
 
        return <Navigate to="/Login" />;
        
    } */

      
    public static routeToPath(urlPath: string) {
        console.log('routeToPath ...', urlPath);

        //let navigate = useNavigate();
        let navigator = App.AppBase.navigator;
        navigator(urlPath);
       
        //var history = App.AppBase.history;
       // history.push(urlPath);
    }

    public static redirect(urlPath: string) {
        console.log('redirect ...', urlPath);

        var newUrl = App.AppBase.appBaseUrl + urlPath;
        window.location.href = newUrl.replace('//','/');

    }



}