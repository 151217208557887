import * as LIB from '_LIB';
import moment from "moment";
 

export class StringHelper {

 
    public static addHtmlLineBreaksToString(val: string){
          //  console.log('addHtmlLineBreaksToString val',val);
            
            
            
            //val = val?.replace(/\r?\n|\r/g,'<br/>');

             
            val = StringHelper.replaceAll(val,'\n','<br/>');

          //  console.log('addHtmlLineBreaksToString val',val);

            return val;
          
    }

    public static getQueryStringParam(key: string):any {

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get(key);
        return myParam;
    }

    public static removeMultipleSpacesAndNewLines(str:string):string{
      
        if(LIB.Common.isNullOrEmpty(str))
        return str;

        str = str.replace(/\r?\n|\r/g,' ');
        str = str.replace(/ +(?= )/g,'');

        return str.trim();
  
    }
  
    public static maxLength(str:string, maxLen:number, endText:string='...'):string{
      
        if(LIB.Common.isNullOrEmpty(str))
            return str;

        str = str.trim();
        if(str.length>maxLen)
            str = str.substring(0,maxLen)+endText;
 
        return str;
  
    }

    public static getBetween(str:string, start:string, end:string):string
    {

        try
        {

            var startIndex = str.indexOf(start);
           // console.log('onDetailResult getBetween start',start );
            //console.log('onDetailResult getBetween startIndex',startIndex );

            var endIndex = str.indexOf(end, startIndex + 1+start.length);
            var skip = startIndex + start.length;

           // console.log('onDetailResult getBetween end',end );
           // console.log('onDetailResult getBetween skip',skip );

            //console.log('onDetailResult getBetween endIndex',endIndex );


            if (startIndex > -1 && endIndex > -1 && skip <= endIndex)
            {
                var result =  str.substring(skip, endIndex );
               // console.log('onDetailResult getBetween result',result );

                return result;
            }

        }
        catch { 

            return "";
        }

        return "";
    }

    public static replaceIgnoreCase(str:string, strReplace:string, strWith:string) {
     
        var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        var reg = new RegExp(esc, 'ig');
        return str.replace(reg, strWith);
    };

    
    public static replaceAll(str:string, keyword:string, replaceWith:string):string{
        // console.log('replaceAll', str,keyword,replaceWith);
           if(LIB.Common.isNullOrEmpty(str))
           return str;
   
          // str = str.replace(`${keyword}/g`,replaceWith); 
          // str = str.toString().rep(`${keyword}/g`,replaceWith); 
           str =  str.split(keyword).join(replaceWith);
           
        //   console.log('replaceAll after:', str);
 
           return str;
       }
   
}