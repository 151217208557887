import * as LIB from '_LIB';
import moment from "moment";


export class LocationStateHelper {


  public static getUSAStates(): LIB.KeyValuePair[] {

    var list: LIB.KeyValuePair[] = [];

    list.push({ key: 'Alabama', value: 'AL' });
    list.push({ key: 'Alaska', value: 'AK' });
    list.push({ key: 'Arizona', value: 'AZ' });
    list.push({ key: 'Arkansas', value: 'AR' });
    list.push({ key: 'California', value: 'CA' });
    list.push({ key: 'Colorado', value: 'CO' });
    list.push({ key: 'Connecticut', value: 'CT' });
    list.push({ key: 'Delaware', value: 'DE' });
    list.push({ key: 'Florida', value: 'FL' });
    list.push({ key: 'Georgia', value: 'GA' });
    list.push({ key: 'Hawaii', value: 'HI' });
    list.push({ key: 'Idaho', value: 'ID' });
    list.push({ key: 'Illinois', value: 'IL' });
    list.push({ key: 'Indiana', value: 'IN' });
    list.push({ key: 'Iowa', value: 'IA' });
    list.push({ key: 'Kansas', value: 'KS' });
    list.push({ key: 'Kentucky', value: 'KY' });
    list.push({ key: 'Louisiana', value: 'LA' });
    list.push({ key: 'Maine', value: 'ME' });
    list.push({ key: 'Maryland', value: 'MD' });
    list.push({ key: 'Massachusetts', value: 'MA' });
    list.push({ key: 'Michigan', value: 'MI' });
    list.push({ key: 'Minnesota', value: 'MN' });
    list.push({ key: 'Mississippi', value: 'MS' });
    list.push({ key: 'Missouri', value: 'MO' });
    list.push({ key: 'Montana', value: 'MT' });
    list.push({ key: 'Nebraska', value: 'NE' });
    list.push({ key: 'Nevada', value: 'NV' });
    list.push({ key: 'New Hampshire', value: 'NH' });
    list.push({ key: 'New Jersey', value: 'NJ' });
    list.push({ key: 'New Mexico', value: 'NM' });
    list.push({ key: 'New York', value: 'NY' });
    list.push({ key: 'North Carolina', value: 'NC' });
    list.push({ key: 'North Dakota', value: 'ND' });
    list.push({ key: 'Ohio', value: 'OH' });
    list.push({ key: 'Oklahoma', value: 'OK' });
    list.push({ key: 'Oregon', value: 'OR' });
    list.push({ key: 'Pennsylvania', value: 'PA' });
    list.push({ key: 'Rhode Island', value: 'RI' });
    list.push({ key: 'South Carolina', value: 'SC' });
    list.push({ key: 'South Dakota', value: 'SD' });
    list.push({ key: 'Tennessee', value: 'TN' });
    list.push({ key: 'Texas', value: 'TX' });
    list.push({ key: 'Utah', value: 'UT' });
    list.push({ key: 'Vermont', value: 'VT' });
    list.push({ key: 'Virginia', value: 'VA' });
    list.push({ key: 'Washington', value: 'WA' });
    list.push({ key: 'West Virginia', value: 'WV' });
    list.push({ key: 'Wisconsin', value: 'WI' });
    list.push({ key: 'Wyoming', value: 'WY' });
 
    return list;
  }




}