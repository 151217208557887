import * as LIB from '_LIB';
import moment from "moment";


export class DownloadHelper {


    public static downloadString(text: string, filename: string = 'data.txt', type = 'text/plain;charset=utf-8;') {

        let csvContent = "data:text/plain;charset=utf-8," + text;
        //new Blob([json], {type: "application/json"});
        var blob = new Blob([ text ], { type: type });
        var url = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
        a.remove();
        //var encodedUri = encodeURI(csvContent);
        //window.open(url);
    }

    public static downloadCSVList(rows: string[]) {

        let type = "data:text/csv;charset=utf-8;"
        var data = rows.join("\n");
        DownloadHelper.downloadString(data, 'data.txt', type)

    }

    public static downloadCSVTable(rows: any[], fileName='data', includeColumnsCsv:string='') {
        // console.log('download downloadCSVTable', rows);
 
         if(LIB.Common.isNullOrEmpty(rows) || !Array.isArray(rows))
             return;
 
         let type = "data:text/csv;charset=utf-8;" 

         var result = '';

         if(LIB.Common.isNullOrEmpty(includeColumnsCsv)){
            var header = Object.keys(rows[0]).join(",")+"\n";
            var data = rows.map(e => Object.values(e).join(",")).join("\n");
            result = header+data;
         }
         else{
            includeColumnsCsv = LIB.StringHelper.replaceAll(includeColumnsCsv,' ', '').trim();
            console.log('download downloadCSVTable includeColumnsCsv', includeColumnsCsv);
            result=includeColumnsCsv+"\n";
            var columns = includeColumnsCsv.split(',');
            for (var row of rows) {
                var rowData = Object.values(row);
                var line =[];
                for (var col of columns) {
                    line.push(row[col]);
                }
                result+=line.join(",")+"\n";
            }

         }
         
         console.log('download downloadCSVTable result', result);

         fileName+='_'+LIB.DateHelper.getFormattedDateWithAMPM()+'.csv';
         DownloadHelper.downloadString(result, fileName, type)
     }
 



}