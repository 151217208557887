import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdministrationManager extends LIB.BaseManager{


  constructor() {
    super('userId');
  }
  
   public static deleteById(userId: number, data: Api.AppUser[] ): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.userId === userId);
    data.splice(index, 1);
    return true;
  } 

  public static deleteProspectById(prospectId: number, data: Api.Prospect[] ): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.prospectId === prospectId);
    data.splice(index, 1);
    return true;
  } 

  public static deleteKeywordById(keywordId: number, data: Api.Keyword[] ): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.keywordId === keywordId);
    data.splice(index, 1);
    return true;
  } 

  

  public static deleteEmailTemplateById(emailTemplateId: number, data: Api.EmailTemplate[] ): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.emailTemplateId === emailTemplateId);
    data.splice(index, 1);
    return true;
  } 
 
  public static deleteJobById(jobId: number, data: Api.Job[] ): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.jobId === jobId);
    data.splice(index, 1);
    return true;
  } 

 
 
  public static update(item: Api.AppUser, data: Api.AppUser[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.userId == undefined || item?.userId < 1)
      return false;

    let index = data.findIndex((c) => c.userId === item.userId);
    if (index < 0)
      return false;
      
    data[index] = item;

    return true;
  }

  public static updateEmailTemplate(item: Api.EmailTemplate, data: Api.EmailTemplate[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.emailTemplateId == undefined || item?.emailTemplateId < 1)
      return false;

    let index = data.findIndex((c) => c.emailTemplateId === item.emailTemplateId);
    if (index < 0)
      return false;
      
    data[index] = item;

    return true;
  }

  public static updateProspect(item: Api.Prospect, data: Api.Prospect[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.prospectId == undefined || item?.prospectId < 1)
      return false;

    let index = data.findIndex((c) => c.prospectId === item.prospectId);
    if (index < 0)
      return false;
      
    data[index] = item;

    return true;
  }



  public static updateKeyword(item: Api.Keyword, data: Api.Keyword[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.keywordId == undefined || item?.keywordId < 1)
      return false;

    let index = data.findIndex((c) => c.keywordId === item.keywordId);
    if (index < 0)
      return false;
      
    data[index] = item;

    return true;
  }
  
  public static updateJob(item: Api.Job, data: Api.Job[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.jobId == undefined || item?.jobId < 1)
      return false;

    let index = data.findIndex((c) => c.jobId === item.jobId);
    if (index < 0)
      return false;
      
    data[index] = item;

    return true;
  }
  



  
}