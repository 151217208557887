import * as LIB from '_LIB';
import moment from "moment";
 

export class UrlHelper {


    public static redirect(url:string){
        console.log('redirect ..., url: ',url);

        window.location.href = url;
        
    }

    public static getQueryStringParam(key: string):any {

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get(key);
        return myParam;
    }
 
    public static getCurrentUrlPathAndQuery(excludeStr:string):string{
      
        var loc = window.location;
        var result = `${loc.pathname}${loc.search}`
 
        result = result.replace(excludeStr,'');
        return result;
  
    }

    public static getQueryString(key: string, location:any = window.location.search ):any {

        const urlParams = new URLSearchParams(location);
        const myParam = urlParams.get(key);
 
        return myParam;
    }

    public static getRouterQueryParam(key: string):any {

        var location = window.location.search; 

        if(LIB.Common.isNullOrEmpty(location))
            return null;
        return UrlHelper.getQueryString(key,location);
        
    }

    public static convertToQueryString(obj:any):string {

        obj = LIB.ObjectHelper.getCleanObjectWithValues(obj);
        if(LIB.Common.isNullOrEmpty(obj))
            return '';
            
        var result = new URLSearchParams(obj).toString();
        
        return result;
    }
 

}