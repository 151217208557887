
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AccountVm  extends LIB.BaseVmModel {

    public selectedTabIndex:number;
    public selectedTab:string;
    public isActionModalVisible:boolean;

    public hasResentConfirmEmail:boolean=false;
    public accountPasswordChangeSm:Api.AccountPasswordChangeSm= new Api.AccountPasswordChangeSm();

   

    constructor() { 

       super();
    }
     


}