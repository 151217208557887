import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';



export class AdminDataService extends App.BaseAppService  {
 

    constructor() { 

        super();

    }

    public getAdminUserData() {

        console.log("AdminDataService.getAdminUserData");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminUser/getAdminUserData`;
        this.get(apiUrl, 
                 App.ActionTypes.ADMIN_USER_PAGE_DATA_RECEIVED,
                 App.ActionTypes.ADMIN_USER_API_ERROR
                 );

       
    } 
     
    public sessionReset() {
        console.log("AdminDataService.sessionReset");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Session/sessionReset`;
        this.get(apiUrl, 
            App.ActionTypes.ADMIN_USER_RESET_SESSION_SUCCESS,
            App.ActionTypes.ADMIN_USER_API_ERROR, 
            "",
            //onAfterSuccess
            (result:boolean)=>{ 
                console.log("AdminDataService.sessionReset onAfterSuccess");

                    App.RouteManager.routeToPath("/");
                    window.location.reload();
                  
                }
            
            );
 
    }

    public impersonateUser(userEmail:string) {
        console.log("AdminDataService.impersonateUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminUser/impersonateUser?userEmail=${userEmail}`;
        this.get(apiUrl, 
            App.ActionTypes.ADMIN_USER_IMPERSONATE_USER_SUCCESS,
            App.ActionTypes.ADMIN_USER_API_ERROR 
            );
 
    }

    public impersonationReset() {
        console.log("AdminDataService.impersonationReset");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminUser/impersonationReset`;
        this.get(apiUrl, 
            App.ActionTypes.ADMIN_USER_IMPERSONATE_USER_SUCCESS,
            App.ActionTypes.ADMIN_USER_API_ERROR 
            );
 
    }

    
 
}