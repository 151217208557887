import * as LIB from '_LIB';
import moment from "moment";


export class ConvertHelper {


    public static getNumberOrEmptyString(value:number, toFixed:number=-1){
        if (LIB.Common.isNullOrEmpty(value) || isNaN(value))
            return '';
         var ret = value.toString();
         if(toFixed>-1)
            ret = value.toFixed(toFixed);
        return ret;
    }
  



}