import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import { Method } from 'axios';



export class BaseAppService extends LIB.BaseService  {
 
    public appState:App.AppState|null=null;
    
    constructor() { 

        super(App.AppBase.authTokenKey);
        this.onError = this.onErrorDefault;
    }

    public onErrorDefault(error: any) {
        console.log('BaseAppService API onError:');

      
        console.log(error);

        App.AppBase.addError('error',error);

       // var msg = LIB.ErrorHelper.getStringErrorMessage('error',error);
         
      //  console.log('BaseAppService API msg:' + msg);

    }

    public get(apiUrl: string, successActionType: string="", errorActionType: string="", successUrl:string="", 
    onAfterSuccess:Function|null=null, onAfterStateSet:Function|null=null, torkenRequired:boolean=false): void {
        
        this.callApiAndDispatch(apiUrl, null, successActionType, errorActionType, successUrl, 'GET',onAfterSuccess,onAfterStateSet,torkenRequired);
    }

    public post(apiUrl: string, data: any, successActionType: string,  errorActionType: string="", 
    successUrl:string="", onAfterSuccess:Function|null=null, 
    onAfterStateSet:Function|null=null,
    torkenRequired:boolean=false,
    isFileUpload: boolean = false
    ): void {

        this.callApiAndDispatch(apiUrl, data, 
            successActionType, errorActionType, successUrl, 
             'POST',onAfterSuccess,onAfterStateSet,torkenRequired,isFileUpload);
    }

       

    public callApiAndDispatch(
        apiUrl: string,
        data: any,
        successActionType: string,
        errorActionType: string,
        successUrl:string="",
        method: Method = 'POST', 
        onAfterSuccess:Function|null=null,
        onAfterStateSet:Function|null=null,
        torkenRequired:boolean=false,
        isFileUpload: boolean = false
    ): void {
 
        if(LIB.Common.isNullOrEmpty(errorActionType))
            errorActionType = App.ActionTypes.ERROR_API_ERROR;
      
          //  console.log('callApiAndDispatch data:', data);


        this.callApi(apiUrl, data,
            (result: any) => {

                if(!LIB.Common.isNullOrEmpty(successActionType))
                    App.AppStore.dispatch(successActionType,result, onAfterStateSet,this.appState);
                         
                if(!LIB.Common.isNullOrEmpty(successUrl))
                        App.RouteManager.routeToPath(successUrl)

                if(onAfterSuccess!=null)
                    onAfterSuccess(result);

            }
            ,
            (error: any) => {
                console.error('callApiAndDispatch error:', error);

                App.AppStore.dispatch(errorActionType,error,null,this.appState);
                this.onErrorDefault(error);
                App.AppBase.addError('error', error);
            }
            ,method
            ,torkenRequired
            ,isFileUpload
            );
    }


}