import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';

// for AdminUsersController

export class AdminEmailService extends App.BaseAppService {


    constructor() {

        super();
        //this.isAccessTokenRequired = true;
    }

    public sendDailyEmailHotCandidates(sm: Api.DailyEmailSm | null = null, onAfterStateSet: Function | null = null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(sm);
        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminEmail/sendDailyEmailHotCandidates?${urlPrams}`;

        this.get(apiUrl,
            App.ActionTypes.ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR

        );

    }

    
    public sendDailyEmailHotJobs(sm: Api.DailyEmailSm | null = null, onAfterStateSet: Function | null = null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(sm);
        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminEmail/sendDailyEmailHotJobs?${urlPrams}`;

        this.get(apiUrl,
            App.ActionTypes.ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR

        );

    }



}
