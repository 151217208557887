import * as React from 'react';
import { CircularProgress, Backdrop, Grid,Paper } from '@mui/material';
import * as LIB from '_LIB';
import * as App from 'AppReferences';
import Alert from '@mui/material/Alert';
import * as Api from 'app-api';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
 


interface QuickMessageContainerProps {
    message?: string;
    iconType?: string;
    icon?:any;
    children?: React.ReactNode;

}


export class QuickMessageContainer extends React.Component<QuickMessageContainerProps, {}> {

    //public title:string;

    constructor(props: any) {
        super(props);
    }

    public getIcon(iconType?:string){

        if (LIB.Common.isNullOrEmpty(iconType))
            iconType = 'success';

            //return  <Icons.CheckCircle style={{ color: '#52c41a', fontSize:'7rem' }} fontSize='large' />;
 
        if(iconType=='success')
            return  <Icons.CheckCircle style={{ color: '#52c41a', fontSize:'7rem' }} fontSize='large' />;
        else if(iconType=='error')
           return  <Icons.Error style={{ color: 'red', fontSize:'7rem' }} fontSize='large' />;
       else if(iconType=='loading')
           return  <CircularProgress size="6rem"  />
        else 
          return null;
    }


    public render() {

        console.log("QuickMessageContainer.render");

        var icon = this.props.icon;

        if(LIB.Common.isNullOrEmpty(icon))
           icon = this.getIcon(this.props.iconType);
 
        return <Grid container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="center"

        >

            <Paper square style={{ margin: 50, padding: 50, width: '80vw', textAlign: 'center', minHeight: '50vh' }} >

                <div style={{ textAlign: 'center' }} >
                    {icon}
                </div>

                 <h1 style={{ fontWeight:400 }} >{this.props.message}</h1>
                
                  {this.props.children}

            </Paper>
        </Grid>;



    }
}
