import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';



interface AlertTypeIconProps {
    name: string;
}


export class AlertTypeIcon extends React.Component<AlertTypeIconProps, {}> {


    public render() {

        //console.log('alert:');
       // console.log(this.props.alertTypeId);
      
        var color = "red";
        var name = this.props.name;

        if(!LIB.Common.isNullOrEmpty(name)){

            name = name.toString().toLowerCase();
       
        if (name.startsWith('c') || name.startsWith('u'))
            color = 'green';
       // else if (name == App.AlertTypeEnum.Delay)
       //     color = 'yellow';
        else // if (name == App.AlertTypeEnum.Stopped)
            color = 'red';
        }

        return <i className="fas fa-circle fa-2x" style={{ color: color }}></i>;

    }
}
