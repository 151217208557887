import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class JobListState  extends LIB.BaseStateModel {
 
  
    public vm:App.JobListVm;
 
    public jobs:Api.Job[];
    public jobsCount:number=0;

    public searchFilter:Api.JobFilterVm = new Api.JobFilterVm();
    public jobsFullList:Api.Job[];
 
    public hasCandidateDataLoaded:boolean = false;
   
    constructor() { 
        
        super();
       
        this.vm = new App.JobListVm();

      
    }
     


}