import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment, Checkbox, FormControlLabel, FormControl, FormHelperText } from '@mui/material';
import axios from 'axios';
import { Alert, AlertTitle } from '@mui/material';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
//import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import { CSSProperties } from "react";

/* 
interface RegisterProps {
  sm: Api.RegisterFormSm;
} */

//export class Register extends React.PureComponent<RegisterProps> {
class Register extends React.Component<App.GuestUserState, {}> {

  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('Register componentDidMount');

    if(App.AppBase.isUserAuthenticated){
      App.AuthManager.logout();
      this.forceUpdate();
    }
    const vm = this.props.vm;
    var sm = this.props.registerSm;

    
    sm.offerId = LIB.UrlHelper.getQueryString("offerId");
 
    App.AppStore.dispatch(App.ActionTypes.GUESTUSER_GET_PAGE_DATA,sm.offerId);

   
   

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('Register componentDidUpdate');


  }



  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    var state = this.props;
    var vm = state.vm;
    var item = state.registerSm;
     
    var target: any = e.target;

    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    console.log('onInputChange key', key);
    //console.log('onInputChange item', item);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateRegister(key, item);

    this.forceUpdate();
  }


  public onRegisterClick(e: React.ChangeEvent<{}>) {
    console.log('onRegisterClick');

    var state = this.props;
    var vm = state.vm;
    var sm = state.registerSm;

    var isValid = this.validationManager.validateRegister(null, sm);

    if (!isValid) {
      console.log('validateRegister NOT valid, ', App.AppBase.lastErrorMessage);
      this.forceUpdate();
      return;
    }

    console.log('onRegisterClick sm',sm);

    //call api
    App.AppStore.dispatch(App.ActionTypes.GUESTUSER_REGISTER_REQUEST,sm ); 

    this.forceUpdate();

  }

  public getOfferText() {
    console.log("getOfferText");
    var state = this.props;
    var vm = state.vm;
    var offer = vm?.offer;

    //console.log("getOfferText offer",offer);

    if (LIB.Common.isNullOrEmpty(offer) || !offer?.isVisibleOnSignup)
      return null;

    return <Alert severity="info"  style={{ marginBottom:20, marginTop:20 }}>
      <AlertTitle>{'Offer: ' + offer.offerId}</AlertTitle>
      {offer.description}
    </Alert>

  
  }


  public render() {

    console.log("Register Render");

    var state = this.props;
    var vm = state.vm;
    var sm = state.registerSm;

    var useTestData = LIB.UrlHelper.getQueryString("useTestData");

    if (useTestData == "1" && LIB.Common.isNullOrEmpty(sm.firstName)) {
        console.log('Register useTestData');
        sm = App.TestDataManager.setTestRegisterData(sm);
       // console.log('Register useTestData sm', sm);
        this.forceUpdate(); 
    }

    //console.log("Register Render sm",sm);

    const classes: any = createStyles({
      backdrop: {
        zIndex: App.AppBase.theme.zIndex.drawer + 1,
        color: '#fff',
      }
    });

    var formInput: CSSProperties = {
      height: 50
    };

    var formControl: CSSProperties = {
      marginTop: 10,
      width: '100%'
    };

    /*   if (App.AppBase.isUserAuthenticationInProgress  ) {
       
       return <Backdrop style={classes.backdrop} open={true}  >
         <CircularProgress color="inherit" />
       </Backdrop>
     }   */


    return <App.MainPage
      state={vm}
      hasDataLoaded={true}
      loading={state.isServiceCallPending}
      //loading={true}
      showContentWhenInProgress={true}
      showModalForLoading={true}

    >
 
      <Grid container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ width: '97vw', marginTop: 20 }}

      >

        <Card style={{ padding: 20, width: 400 }}>
          <CardContent  >

            <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>
              Sign Up
            </Typography>

            <div>

              <Grid container spacing={2}>
                <Grid item xs={6}>

                  <TextField
                    name="firstName"
                    label="First Name"
                    value={sm.firstName || ''}
                    {...App.AppBase.getInputErrorProps('firstName')}
                    onChange={this.onInputChange.bind(this)}
                    style={formControl}
                    inputProps={{ style: formInput }}

                  />

                </Grid>

                <Grid item xs={6} >
                  <TextField
                    name="lastName"
                    label="Last Name"
                    value={sm.lastName || ''}
                    {...App.AppBase.getInputErrorProps('lastName')}
                    onChange={this.onInputChange.bind(this)}
                    style={formControl}
                    inputProps={{ style: formInput }}

                  />
                </Grid>

              </Grid>
 
            </div>

            <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>

                  <TextField
                    name="companyName"
                    label="Company Name"
                    value={sm.companyName || ''}
                    {...App.AppBase.getInputErrorProps('companyName')}
                    onChange={this.onInputChange.bind(this)}
                    style={formControl}
                    inputProps={{ style: formInput }}

                  />

                </Grid>

                <Grid item xs={6} >
                  <TextField
                    name="phone"
                    label="Phone"
                    value={sm.phone || ''}
                    {...App.AppBase.getInputErrorProps('phone')}
                    onChange={this.onInputChange.bind(this)}
                    style={formControl}
                    inputProps={{ style: formInput }}

                  />
                </Grid>

              </Grid>

              
            </div>

            <div>
            <TextField
                name="email"
                label="Email"
                value={sm.email || ''}
                {...App.AppBase.getInputErrorProps("email")}
                onChange={(e: any) => this.onInputChange(e)}
                ///style={fullWidth}
                fullWidth
                style={formControl}
                //variant="outlined"
                inputProps={{ style: formInput }}
              />
            </div>
            
            <div>
              <TextField
                name="password"
                label="Password"
                type='password'
                value={sm.password || ''}
                {...App.AppBase.getInputErrorProps("password")}
                onChange={(e: any) => this.onInputChange(e)}
                ///style={fullWidth}
                fullWidth
                style={formControl}
                inputProps={{ style: formInput }}
              />
            </div>

            <LIB.MCheckbox {...App.AppBase.getInputErrorProps('acceptTerms')}
            formControlLabel={ <FormControlLabel
              control={
                <Checkbox
                  name="acceptTerms"
                  color="primary"
                  
                  checked={sm.acceptTerms}
                  onChange={(e: any) => this.onInputChange(e)}
                  //style={fullWidth}
                  inputProps={{ style: formInput }}
  
                />
              }
              style={{ marginLeft: 0 }}
              labelPlacement="end"
              label={ <span style={{ fontSize: '11px' }}>
              I hereby agree to 
              <a className="registerFormTermsOfService" href={`${App.AppBase.appInfo.appWebsiteUrl}/page/terms-of-service`} 
              target="_blank"> Terms of Service </a>
          and <a className="registerFormPrivacyPolicy" href={`${App.AppBase.appInfo.appWebsiteUrl}/page/privacy-policy`} 
          target="_blank">Privacy Policy</a>
          </span>}
            />}
            >
 
            </LIB.MCheckbox>

          

            {this.getOfferText()}

            <div className='standardFormInput30'>
              <Button variant="contained" color="primary" style={{ width: '100%' }}
                onClick={(e) => this.onRegisterClick(e)}
                disabled={state.isServiceCallPending}
              >Sign Up</Button>
            </div>


            <div style={{ width: '100%', marginBottom: 0, marginTop: 50, fontWeight: 500 }} >
              <span style={{ marginRight: 5 }}> Already have an account?</span>
              <RouterLink to="/Login" color="primary">Login</RouterLink>
            </div>



          </CardContent>
        </Card>

      </Grid >

      </App.MainPage>  ;

  }
};
      export default connect(
  (state: App.AppState) => ({...state.guestUserData})
      )(Register as any);
