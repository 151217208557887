import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, FormControl, FormHelperText, InputLabel
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';
import { UnprivilegedEditor } from 'react-quill';
//import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';

interface MyAccountJobsAddEditModalProps {
  state: App.MyAccountState;
  onChange?: Function;
}


export class MyAccountJobsAddEditModal extends React.Component<MyAccountJobsAddEditModalProps> {

  // todo public designSetDataService: App.JobDataService;
  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    // todo this.designSetDataService = new App.JobDataService();
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    // console.log('MyAccountJobsAddEditModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('MyAccountJobsAddEditModal componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onAddOrUpdateClick(isPublished: boolean) {
    console.log('onAddOrUpdateClick');


    var state = this.props.state;
    var vm = state.vm;
    var sm = state.jobSm;
    var item = vm.selectedJob;
    var items = state.jobs;

    item.isPublished = isPublished;

    //console.log('onAddOrUpdateClick selectedJob',item);
   // console.log('onAddOrUpdateClick jobs',items);

    var isValid = this.validationManager.validateJobCreateUpdate(null, item);

    if (!isValid) {
      console.log('validateAddJobModal NOT valid');
      this.forceUpdate();
      return;
    }
 
    if (vm.isJobsEditOpen)
      App.AppStore.dispatch(App.ActionTypes.MY_ACCOUNT_JOB_UPDATE_REQUEST, item);
    else if (vm.isJobsAddOpen)
      App.AppStore.dispatch(App.ActionTypes.MY_ACCOUNT_JOB_ADD_REQUEST, item);

    vm.isActionInProgress = true; 

    this.onCloseClick();

  }

  public onCloseClick() {

    var state = this.props.state;
    var vm = state.vm;
    var sm = state.jobSm;

    //reset edits
    console.log('Jobs onCloseClick selectedJobOriginal',vm.selectedJobOriginal);

 
    App.JobManager.update(vm.selectedJobOriginal, state.jobs);

    console.log('Jobs onCloseClick state.jobs',state.jobs);


    vm.isJobsAddOpen = false;
    vm.isJobsEditOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    if (this.props.onChange !== undefined)
      this.props.onChange();

  console.log('onCloseClick end');

    this.forceUpdate();

  }

  public onAutocompleteChange(name: string, value: any, selectedTypes: any, valueProperty: any = '') {
    console.log('onAutocompleteChange name', name);
    //console.log('onAutocompleteChange value', value);
    // console.log('onAutocompleteChange selectedTypes', selectedTypes);

    var state = this.props.state;
    var vm = state.vm;

    var sm = state.jobSm;

    var item = vm.selectedJob;

    var key = LIB.FormHelper.setAutocompleteChangeForIdAndType(name, item, selectedTypes, valueProperty);

    // console.log('onAutocompleteChange setAutocompleteChangeForIdAndType key', key);
    //console.log('onAutocompleteChange selectedItem', item);



    /* if (name == 'name')
      item.name = value;

    else {

      name = LIB.FormHelper.setInputChangeData(selected, item);

      if (LIB.Common.isNullOrEmpty(name))
        return;
    }
 */

    console.log('onAutocompleteChange key, selectedItem', key, item);

    this.validationManager.validateJobCreateUpdate(key, item);

    this.forceUpdate();
  }


  //public onDataChange(key: string, val:string, editor: UnprivilegedEditor) {
    public onDataChange(key: string, value:string) {
    console.log('onRichTextBlue key', key);
    console.log('onRichTextBlue value',value);
  
    var state = this.props.state;
    var vm = state.vm;
    var item: any = vm.selectedJob;

    //var value = editor.getText();
 
    item[key] = value;

    var isValid = this.validationManager.validateJobCreateUpdate(key, item);

    console.log('onDataChange item', item);
    console.log('onDataChange isValid', isValid);

    if (!isValid) {
      this.forceUpdate();
    }

  }


  public onRichTextBlue(key: string, editor: UnprivilegedEditor) {

    console.log('onRichTextBlue key', key);
  //  console.log('onRichTextBlue editor',editor);

    //var value = editor.getHTML();
  //  var valueText = editor.getText();
    var value = editor.getText();
    value = LIB.StringHelper.addHtmlLineBreaksToString(value);
 
    //console.log('onRichTextBlue value html',value);
   // console.log('onRichTextBlue valueText',valueText);

    
    
    var state = this.props.state;
    var vm = state.vm;
    var item: any = vm.selectedJob;

    item[key] = value;
    this.validationManager.validateJobCreateUpdate(key, item);
 
    this.forceUpdate();


  }

  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedJob;

    var target: any = e.target;

    var name = LIB.FormHelper.setInputChangeData(e.target, item);

    if (LIB.Common.isNullOrEmpty(name))
      return;


    this.validationManager.validateJobCreateUpdate(name, item);


    this.forceUpdate();
  }

  public getForm() {
    //console.log('MyAccountJobsAddEditModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedJob;


    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };

    var item = vm.selectedJob;



    if (item === undefined)
      return null;

/* 
    const Tooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[ 1 ],
        fontSize: 11,
      },
    }))(Tooltip); */


    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasJobsDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      <form     >


        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} >
            <TextField
              name="title" label="Title"
              placeholder="i.e Full Stack .NET/React Developer"
              value={item.title || ''}
              {...App.AppBase.getInputErrorProps('title')}
              onChange={this.onInputChange.bind(this)}
              style={fullWidth}
              inputProps={{ style: formInput }}

            />

          </Grid>
          <Grid item xs={12} sm={4}
          >


            <App.FormAutocomplete
              label="Type"
              name="jobType"
              data={state.jobTypes}
              onChange={this.onAutocompleteChange.bind(this)}
              labelProperty="name"
              valueProperty="jobTypeId"
              value={item.jobType}
              textFieldStyle={autocompleteInput}

            />



          </Grid>

        </Grid>

        <div style={{ paddingTop: 20 }} >
          <LIB.MRichTextEditor
            {...App.AppBase.getInputErrorProps('description')}
            name='description'
            label='Description'
            placeholder='Description'
            value={item.description || ''}
            onChangeText={this.onDataChange.bind(this)}
            formControlStyle={fullWidth}
            onBlur={(val:any) => this.onRichTextBlue('description',val)}
            disableToolbar={true}
            addTextLineBreaks={true}
          />
        </div>

        {/*   <div style={{ paddingTop:20 }} >
          <FormControl style={fullWidth}   {...App.AppBase.getInputErrorProps('description')} >
          
            <InputLabel htmlFor="my-input" style={{marginTop:-50}}>Description</InputLabel>
           
            <ReactQuill theme="snow"
              id='my-input'
              value={item.description || ''}
              onChange={(val) => this.onDataChange('description', val)}
              style={{ height: '200px' }}
              placeholder="Description"

            />

            <FormHelperText
            style={{marginTop:45}}
             >We'll never share your email.</FormHelperText>

          </FormControl>
        </div> */}





        <div >


          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} >
              <TextField
                name="location" label="Location"
                value={item.location || ''}
                {...App.AppBase.getInputErrorProps('location')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

            </Grid>
             
            
          </Grid>
 
          <div>

            <Tooltip arrow placement="right" className='formHelperTooltip'
              title="Hot jobs will be included in daily email to all vendors."

            >
              <FormControlLabel
                control={

                  <Switch name="isHot"
                    checked={item.isHot}
                    onChange={this.onInputChange.bind(this)}
                  />

                }
                style={{ marginLeft: 0 }}
                labelPlacement="start"
                label="Hot"
              />
            </Tooltip>

            <Tooltip arrow placement="right"
              title="Is Remote working allowed?"

            >
              <FormControlLabel
                control={

                  <Switch name="isRemote"
                    checked={item.isRemote}
                    onChange={this.onInputChange.bind(this)}

                    color="primary"
                  />

                }
                style={{ marginLeft: 20 }}
                labelPlacement="start"
                label="Remote"
              />
 
          </Tooltip>

            <Tooltip arrow placement="right"
              title="When turned On, System will Automatically Renew this Candidate every Week so it never expires."

            >
              <FormControlLabel
                control={

                  <Switch name="isAutoRenew"
                    checked={item.isAutoRenew}
                    onChange={this.onInputChange.bind(this)}
                  />

                }
                style={{ marginLeft: 10 }}
                labelPlacement="start"
                label="Auto Renew"
              />
            </Tooltip>


            {/*    <Tooltip arrow placement="right"
              title="Hot jobs will be included in daily email to all vendors."

            >
              <Icons.Info style={{ marginLeft: 5, marginBottom: -8, color: 'gray' }} />
            </Tooltip>
 */}



          </div>



        </div>


      </form>

    </App.MainPage>;


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    //console.log('MyAccountJobsAddEditModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = (vm.isJobsAddOpen || vm.isJobsEditOpen);
    var item = vm.selectedJob;

    if(!isOpen)
      return;

    var title = "Create a New Job Listing";
    var saveBtnTitle = "Save Draft"; //"Add";
    if (vm.isJobsEditOpen) {
      title = `Edit Job Listing (${item?.jobId})`;
      saveBtnTitle = "Save";
    }

    var statusTag = App.FeaturedManager.getStatusNameTag(item);


    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >{title} {statusTag}</DialogTitle>
          <DialogContent style={{ width: '50vw' }}>

            {this.getForm()}

          </DialogContent>
          <DialogActions>

          <div style={{ marginRight: 10 }}>Status: {statusTag}</div>
 
            <Button
              variant="contained"
              color="inherit"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</Button>

            <Button
               color="warning"
              variant="contained"
              style={{ margin: 10 }}
                onClick={() => this.onAddOrUpdateClick(false)}
              disabled={vm.isActionInProgress}
            >{saveBtnTitle}</Button>

<Tooltip arrow placement="top"
              title="Only Published Jobs will be available to vendors or emails."
            >
 
              <Button
                color="primary"
                variant="contained"
                style={{ margin: 10 }}
                onClick={() => this.onAddOrUpdateClick(true)}
                disabled={vm.isActionInProgress}
              // title='Only Published Jobs will be available to vendors or emails.'
              >Publish</Button>

            </Tooltip>



          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 