import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
import { Tabs, Tab } from "@mui/material";
import { CSSProperties } from "react";
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Link, Stack
} from '@mui/material';

import { createStyles, Theme } from '@mui/material/styles';
import { useParams, useLocation } from 'react-router';



interface MSingleFileUploadProps {
  name:string;
  vm: LIB.MSingleFileUploadVm;
  onChange:Function;
  disabled?: boolean;
  pending?:boolean;
}


//export const MSingleFileUpload: React.FC<MSingleFileUploadProps> = (props) => {
export class MSingleFileUpload extends React.Component<MSingleFileUploadProps, {}> {


  constructor(props: any) {
    super(props);
    //  this.state = { visible: false, isCancel:false };

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('componentDidUpdate:'+this.state.visible);


  }


  /*  React.useEffect(() => {
     console.log("MSingleFileUpload useEffect update");
 
     //checkSetSelected();
   }); */



  public onClearClick() {
    console.log('onClearClick props', this.props);

    var vm = this.props?.vm;

    vm.existingFileName = '';
    vm.newFileName = '';

    if(this.props.onChange!==undefined)
      this.props.onChange(undefined);


    this.forceUpdate();
  }

  public getViewComponent() {

    var vm = this.props.vm;

    return <Stack direction="row" alignItems="left" spacing={1}>

      <Link style={{ textDecoration: 'none' }} href={vm.existingFilePath} target="_blank" >
        <Icons.FileOpen style={{ verticalAlign: 'top', marginTop: -1 }} />
        <span style={{ marginLeft: 5 }}>
          {vm.existingFileName}
        </span>
      </Link>
      <Icons.Clear
        onClick={() => this.onClearClick()}
        titleAccess="Delete"
        style={{ verticalAlign: 'top', marginTop: -1, fontWeight: 'bold', fontSize: 25, cursor: 'pointer' }} color='error' />


    </Stack>

  }

  private onFileChange(e: any) {
    const vm = this.props.vm;

    var item = vm;
    var files = Array.from(e.target.files);

    console.log("onFileChange files:", files);

    //validate
    if (!Array.isArray(files) || files.length < 1) return;

    vm.newFile = files[ 0 ];

    console.log("onFileChange file 2:", item.newFile);

    item.newFileName = item.newFile.name.toString();

    var fileNameParts = item.newFileName.split('.');

    if (fileNameParts.length > 1)
      fileNameParts.splice(fileNameParts.length - 1, 1);

    item.newFileName = fileNameParts.join('.');

    this.forceUpdate();

    if(this.props.onChange!==undefined)
        this.props.onChange(item);

  }


  public getUploadComponent() {

    var vm = this.props.vm;

    return <Stack direction="row" alignItems="left" spacing={2}>

      <div
      >
        <TextField
          name={this.props.name}
          label="Resume File"
          {...App.AppBase.getInputErrorProps(this.props.name)}
          value={vm.newFileName || ""}
          style={{ minWidth: 300, verticalAlign: 'bottom', color: 'green', marginRight: 5 }}
          //todo inputProps={{ style: formInput }}
         // disabled={true}
        />
        <label htmlFor="fileUpload">
          <input
            style={{ display: "none" }}
            id="fileUpload"
            name="fileUpload"
            type="file"
            accept=".pdf,.doc,.docx"
            multiple={false}
            value={""}
            onChange={(e: any) => this.onFileChange(e)}
          />

          <Button color="primary"
            // style={{width:120 }}
            variant="contained" component="span">
            Browse
          </Button>
        </label>

        {/* <span style={{color:'green', marginLeft:10}}>{item.file.name || ""}</span> */}
      </div>
      <div hidden={vm.newFileName == ''}>
        <Icons.Clear
          onClick={() => this.onClearClick()}
          titleAccess="Clear"

          style={{ verticalAlign: 'top', marginTop: 20, fontWeight: 'bold', fontSize: 25, cursor: 'pointer' }} color='error' />
      </div>
    </Stack>

  }



  public render() {
 
    if(this.props.pending)
      return <LIB.Loading />
      
    //var theme = App.AppBase.theme;
    const classes: any = {
      root: {


      },

    };


    var fullWidth: CSSProperties = {
      // paddingRight: 20,
      paddingLeft: 0,
      //  marginBottom: 10,
      //  padding: 10,

      width: '30vw',
      minWidth: '350px'
    };
    var formInput: CSSProperties = {
      height: 50,
      //  marginTop: 10,
      //paddingTop: 20,
      padding: 10,
    };


    var vm = this.props.vm;

    var viewMode = vm.viewMode;
 
    var view = this.getViewComponent();

    if (LIB.Common.isNullOrEmpty(vm.existingFileName)) {
     // viewMode = 'upload'
     // //vm.viewMode =viewMode;
      view = this.getUploadComponent();
    }
    
   /*  if (viewMode=='upload') {
      view = this.getUploadComponent();
    } */

    console.log("MSingleFileUpload viewMode:",viewMode);

    

    return (
      <div style={{ marginBottom: 30  }}>

        {view}

      </div>
    );


  }
}

