import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Snackbar
} from '@mui/material';
import { Table, Card, CardContent, Paper, Container, Box, Backdrop, CircularProgress, InputAdornment, FormControl, FormHelperText } from '@mui/material';

import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface AdministrationUsersEditRolesModalProps {
  state: App.AdministrationState;
  onChange?: Function;
}


export class AdministrationUsersEditRolesModal extends React.Component<AdministrationUsersEditRolesModalProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationUsersEditRolesModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationUsersEditRolesModal componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onCloseClick() {

    var state = this.props.state;
    var vm = state.vm;
    var sm = vm.adminChangeUserRolesSm;
 
    //sm.userRoleIds = [];

    //vm.selectedUser.userRoleTypes

    vm.isUsersEditRolesOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

  }
 

  public onSaveClick(e: React.ChangeEvent<{}>) {
    console.log('onSaveClick');


    var state = this.props.state;
    var vm = state.vm;
    var sm = vm.adminChangeUserRolesSm;

    var item = vm.selectedUser;

    sm.userId = item.userId;
    console.log("onSaveClick sm:", sm);

    var isValid = this.validationManager.validateAdminUserRolesChange(null, sm);

    if (!isValid) {
      console.log('validateAddConstructionPermitModal NOT valid');
      this.forceUpdate();
      return;
    }

    //var sm = vm.accountPasswordChangeSm;
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_USERS_ROLE_CHANGE_REQUEST, sm
      //,()=>{this.resetData();}
    );

   // vm.isActionInProgress = true;

  //  this.forceUpdate();

  }


  public onRolesChange(name: string, value: any, selectedTypes: any) {

    console.log('EditProjectAddRequestModal onRolesChange name', name);
    console.log('EditProjectAddRequestModal onRolesChange value', value);
    console.log('EditProjectAddRequestModal onRolesChange selectedTypes', selectedTypes);

    var state = this.props.state;
    var vm = state.vm;
    var sm = vm.adminChangeUserRolesSm;
 
    LIB.FormHelper.setAutocompleteChangeForMultipleTypes(name,value,selectedTypes,sm);

   /*  var selected = {
      name: name,
      value: value
    }
    if (name == "roles") {
      // sm.selectedAddRequestIds = value;
    }
 */
    this.validationManager.validateAdminUserRolesChange(name, sm);

    this.forceUpdate();
  }


  public getForm() {
    console.log('EditProjectAddRequestModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var sm = vm.adminChangeUserRolesSm;

    //var users = vm.filteredImpersonateUserList;
    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };
    var formInput: CSSProperties = {
      height: 50
    };

    var formControl: CSSProperties = {
      margin: theme.spacing(2),
      marginLeft: 20,
      // padding:'20px, 16px'
    };
    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10
    };
    var TextFieldLabelStyle: CSSProperties = {
      paddingLeft: 0

    };

    var item = vm.selectedUser;
    var sm = vm.adminChangeUserRolesSm;

    console.log('EditProjectAddRequestModal getForm selectedUser', vm.selectedUser);
    console.log('EditProjectAddRequestModal getForm sm.userRoleTypes', sm.userRoleTypes);
 
    //var filtered = state.userRoleTypes?.filter((item) => sm.userRoleTypes?.findIndex((c)=> c.userRoleTypeId==item.userRoleTypeId)===-1 );
    
   // console.log('EditProjectAddRequestModal filtered',filtered);
 

    return <div style={{ margin: 10 }}>
      <form     >

        <div >

          <App.FormAutocomplete label="Select Roles"
            name="userRole"
            //data={filtered}
            data={state.userRoleTypes}
            value={sm.userRoleTypes}
            onChange={this.onRolesChange.bind(this)}
            labelProperty="name"
            // labelRenderer={(item: any) => { return `${item[ 'requestId' ]} - ${item[ 'buildingNames' ]} - ${item[ 'requestorName' ]}`; }}
            valueProperty="userRoleTypeId"
            textFieldStyle={textFieldStyle}
            multiple={true}
          />



        </div>


      </form>



    </div>;


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    console.log('AdministrationUsersEditRolesModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = vm.isUsersEditRolesOpen;
    var item = vm.selectedUser;

    if (!isOpen)
      return;

    var title = "Edit Roles for " + item.firstName + " " + item.lastName;

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >{title}</DialogTitle>
          <DialogContent style={{ width: '35vw' }}>

            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <Button
              variant="contained"
              //color="default"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Close</Button>

            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={this.onSaveClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Save</Button>



          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 