import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class GlobalReducers {


    constructor() {

    }


    public static getActionHandlersReducers(state: App.GlobalState | undefined, action: App.AppAction): App.GlobalState {
        var str = "";

        var result = new App.GlobalState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("GLOBAL"))
            return state;

        var appState = action.appState;

        console.log('GlobalReducers.getActionHandlersReducers');

        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.GLOBAL_API_ERROR:
                console.log('GLOBAL_API_ERROR:');
                console.log('action.payload:', action);
                result = { ...state };
                result.initialData = { ...state.initialData };
                result.initialData.isActionInProgress = false;
                //result.initialData.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action.payload);
                result.isInitialDataLoaded = false;
                console.log('apiErrorMessage:' + result.initialData.apiErrorMessage);

                App.AppBase.setApiError(action,appState);

                //return result;
                break;
             case App.ActionTypes.GLOBAL_WINDOW_SIZE_CHANGED:{
                     console.log('GLOBAL_WINDOW_SIZE_CHANGED:',action.payload);

                    var isMobile = action.payload.isMobile;

                    console.log('GLOBAL_WINDOW_SIZE_CHANGED state:',state.globalUIState.isMobile);

                    if(isMobile==state.globalUIState.isMobile){
                        return state;
                    }
                     

                    result = { ...state };

                    LIB.ObjectHelper.setData(action.payload,result.globalUIState);
                   // result.globalUIState.isMobile = action.payload.isMobile;


                    App.AppBase.isMobile = result.globalUIState.isMobile;
                  
                    console.log('GLOBAL_WINDOW_SIZE_CHANGED:',action.payload);
                    result.forceUpdateCount++;
                   // return result;
                    break;
              }       
            case App.ActionTypes.GLOBAL_LOG_API_RESULT:
                console.log('GLOBAL_LOG_API_RESULT:');
                console.log(action.payload);
                //return result;
                break;

            case App.ActionTypes.GLOBAL_UI_STATE_CHANGED:{
                console.log('GLOBAL_UI_STATE_CHANGED:', action.payload);
                result = { ...action.appState.globalData };
               
               // result.globalUIState = action.payload;

                LIB.ObjectHelper.setData(action.payload,result.globalUIState);

                console.log('GLOBAL_UI_STATE_CHANGED:done:', result.globalUIState);

                result.forceUpdateCount++;
                //return result;
                break;
            }
            case App.ActionTypes.GLOBAL_UI_SELECT_LEFT_MENU:{
                console.log('GLOBAL_UI_SELECT_LEFT_MENU:', action.payload);
                result = { ...action.appState.globalData };

                let data = action.payload;
                result.globalUIState.selectLeftMenuItem = data.selectLeftMenuItem;
                 
                console.log('GLOBAL_UI_SELECT_LEFT_MENU:done:', result.globalUIState);

                result.forceUpdateCount++;
                //return result;
                break;
            }
            case App.ActionTypes.GLOBAL_INITIAL_DATA_RECEIVED:
                console.log('GLOBAL_INITIAL_DATA_RECEIVED:', action.payload);
                //console.log(action.payload);
                //result.initialData.currentUser = action.payload.currentUser;
                result = { ...state };
                result.initialData.currentUser.setData(action.payload.currentUser);
                result.initialData.userRoleTypes = action.payload.userRoleTypes;
                result.initialData.testSessionVal = action.payload.testSessionVal;
                result.initialData.apiEnvName = action.payload.apiEnvName;
                result.initialData.appSettings = action.payload.appSettings;
                result.initialData.sessionHasCurrentUser = action.payload.sessionHasCurrentUser;

                console.log('testSessionVal:' + result.initialData.testSessionVal);
                console.log('sessionHasCurrentUser:' + result.initialData.sessionHasCurrentUser);
                console.log('GLOBAL_INITIAL_DATA_RECEIVED currentUser:',result.initialData.currentUser);
                
                App.AppBase.setAppInfo(action.payload.appSettings);


                var globalUi = result.globalUIState;
                globalUi.countCandidateSubmits = action.payload.countCandidateSubmits;
                globalUi.countResumeRequests = action.payload.countResumeRequests;

 
                result.isInitialDataLoaded = true;
                result.isAppInfoDataLoaded = true;
             
              
                break;


            case App.ActionTypes.GLOBAL_APPINFO_DATA_RECEIVED:
                console.log('GLOBAL_APPINFO_DATA_RECEIVED:');
                console.log(action.payload);
                  
                App.AppBase.setAppInfo(action.payload);
 
                result.isAppInfoDataLoaded = true;
               
                break;
    
                
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;
    }



}