import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdministrationDailyEmailsReducers {


    constructor() {

    }

    public static getReducers(state: App.AdministrationState | undefined, action: App.AppAction): App.AdministrationState {
        var str = "";

        var result = new App.AdministrationState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ADMINISTRATION_DAILY_EMAILS"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        var adminUsersService = new App.AdminUsersService();


        // const action = incomingAction as KnownAction;
        switch (action.type) {
            case App.ActionTypes.ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_SUCCESS: {
                console.log('ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_SUCCESS:');
                result = { ...state };

                var data = action.payload;

                var msg = `Successfully Sent: userCount: ${data.userCount}, prospectCount: ${data.prospectCount}`
                App.AppBase.showMessage(msg);

                console.log('ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_SUCCESS: msg', msg);

                result.isServiceCallPending = false;
                result.hasDataLoaded = true;
                result.forceUpdateCount++;
                console.log("result", result);
                break;
            }

            default:
                result = state;
                //return result;
                break;
        }
        //  console.log('administrationuser_DATA_RECEIVED action:',action);


        if (action.postActionCallBack) {
            console.log("postactioncallback");
            action.postActionCallBack(result);
        }
        return result;
    }



}