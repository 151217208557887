import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Snackbar
} from '@mui/material';
import { Table, Card, CardContent, Paper, Container, Box, Backdrop, CircularProgress, InputAdornment, FormControl, FormHelperText } from '@mui/material';

import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface AdministrationUsersChangePasswordModalProps {
  state: App.AdministrationState;
  onChange?: Function;
}


export class AdministrationUsersChangePasswordModal extends React.Component<AdministrationUsersChangePasswordModalProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
     console.log('AdministrationUsersChangePasswordModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
     console.log('AdministrationUsersChangePasswordModal componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onCloseClick() {

    var state = this.props.state;
    var vm = state.vm;
  var sm = vm.adminPasswordChangeSm;
  
    //reset edits
    App.AdministrationManager.update(vm.selectedUserOriginal, state.appUsers);
    sm.password = '';
    sm.confirmPassword = '';

    vm.isUsersChangePasswordOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

  }

  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    const item = this.props.state.vm.adminPasswordChangeSm;
    var target: any = e.target;
 
    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    //console.log('onInputChange key', key);
    //console.log('onInputChange value2', target.value);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateAdminUserPasswordChange(key, item);

    //console.log('onInputChange item', item);

    this.forceUpdate();
  }

  public onSaveClick(e: React.ChangeEvent<{}>) {
    console.log('onSaveClick');


    var state = this.props.state;
    var vm = state.vm;
    var sm = vm.adminPasswordChangeSm;

    var item = vm.selectedUser;
    
    sm.userId = item.userId;
    console.log("userId:",sm.userId);

    var isValid = this.validationManager.validateAdminUserPasswordChange(null, sm);

    if (!isValid) {
      console.log('onSaveClick validateAdminUserPasswordChange: NOT valid');
      this.forceUpdate();
      return;
    }

    //var sm = vm.accountPasswordChangeSm;
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_USERS_PASSWORD_CHANGE_REQUEST, sm
      //,()=>{this.resetData();}
       );

    vm.isActionInProgress = true;

    this.forceUpdate();

  }

  public getForm() {
    console.log('AdministrationUsersChangePasswordModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var sm = vm.adminPasswordChangeSm;
    console.log(sm);

    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };

    var formControl: CSSProperties = {
      margin: theme.spacing(2),
      //minWidth:'25rem',
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };

    

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };

    var item = vm.selectedUser;

    if (item === undefined)
      return null;
 

    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasUserDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
    //  errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
{     

<form >
Manage your account security from this page.
<h5>CHANGE PASSWORD</h5>
 <div style={{ padding:8 }}>
 <Grid container spacing={2}>

</Grid>

<Grid container spacing={2}>

<Grid item xs={12} sm={12}   >


  <TextField
    name="password"
    label="New Password"
    type='password'
    value={sm.password || ''}
    {...App.AppBase.getInputErrorProps("password")}
    onChange={(e: any) => this.onInputChange(e)}
    ///style={fullWidth}
    fullWidth
    style={formControl}
    //variant="outlined"
    InputProps={{
      className: 'standardFormInput30',
      startAdornment: (
        <InputAdornment position="start">
          <Icons.LockOutlined />
        </InputAdornment>
      ),
    }}
  />


</Grid>

</Grid>

<Grid container spacing={2}>

<Grid item xs={12} sm={12}   >

  <TextField
    name="confirmPassword"
    label="Confirm New Password"
    type='password'
    value={sm.confirmPassword || ''}
    {...App.AppBase.getInputErrorProps("confirmPassword")}
    onChange={(e: any) => this.onInputChange(e)}
    ///style={fullWidth}
    fullWidth
    style={formControl}
    //variant="outlined"
    InputProps={{
      className: 'standardFormInput30',
      startAdornment: (
        <InputAdornment position="start">
          <Icons.LockOutlined />
        </InputAdornment>
      ),
    }}
  />

</Grid>

</Grid>
          </div>
</form>

}

    </App.MainPage>;


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    console.log('AdministrationUsersChangePasswordModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = vm.isUsersChangePasswordOpen;
    var item = vm.selectedUser;

    if (!isOpen)
      return;

     var title = "Change Password for " + item.firstName + " " + item.lastName;
    
    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >{title}</DialogTitle>
          <DialogContent style={{ minWidth: '35vw' }}>

            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <Button
              variant="contained"
              //color="default"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Close</Button>

            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={this.onSaveClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Change Password</Button>



          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 