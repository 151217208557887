import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdminUserReducers {


    constructor() {

    }


    public static getActionHandlersReducers(state: App.AdminUserState | undefined, action: App.AppAction): App.AdminUserState {
        var str = "";

        var result = new App.AdminUserState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ADMIN_USER"))
            return state;

        console.log('AdminUserReducers.getActionHandlersReducers');
        var appState = action.appState;

        switch (action.type) {
            case App.ActionTypes.ADMIN_USER_API_ERROR:
                console.log('ADMIN_USER_API_ERROR:');
                result = { ...state };
                result.vm = { ...state.vm };
                result.vm.isActionInProgress = false;
                //result.vm.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action.payload);

                App.AppBase.setApiError(action,appState);

                //return result;
                break;
            case App.ActionTypes.ADMIN_USER_PAGE_DATA_RECEIVED:
                console.log('ADMIN_USER_PAGE_DATA_RECEIVED:');
                console.log('AdminUserData:', action.payload);
                result.appUsers = action.payload.appUsers;
                result.userRoleTypes = action.payload.userRoleTypes;
                result.vm.filteredImpersonateUserList = result.appUsers;
                result.vm.isActionInProgress = false;
                result.hasDataLoaded = true;
                //return result;
                break;

            case App.ActionTypes.ADMIN_USER_IMPERSONATE_USER_SUCCESS:
                console.log('ADMIN_USER_IMPERSONATE_USER_SUCCESS:');
                console.log('ADMIN_USER_IMPERSONATE_USER_SUCCESS:',action.payload);
               // appState.globalData.initialData.currentUser = action.payload;
                appState.globalData.initialData.currentUser.setData(action.payload);

                

                 console.log('ADMIN_USER_IMPERSONATE_USER_SUCCESS: currentUser',appState.globalData.initialData.currentUser);
                 console.log('ADMIN_USER_IMPERSONATE_USER_SUCCESS: isImpersonating',appState.globalData.initialData.currentUser.isImpersonating);

                result.vm.isActionInProgress = false;
                App.RouteManager.redirect("/");
                //return result;
                break;

            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;
    }



}