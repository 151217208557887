import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class JobManager extends LIB.BaseManager{


  constructor() {
    super('jobId');
  }
  
  public static deleteById(jobId: number, data: Api.Job[]): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.jobId === jobId);
    data.splice(index, 1);
 
    return true;
  } 

  public static getById(jobId: number, data: Api.Job[]) {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(jobId) )
      return null;

    var found = data?.find((item) => item.jobId === jobId);
    
    
    if(LIB.Common.isNullOrEmpty(found))
      return null;
 
    return found;
  }
 
  public static update(item: Api.Job, data: Api.Job[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.jobId == undefined || item?.jobId < 1)
      return false;

    let index = data.findIndex((c) => c.jobId === item.jobId);
    if (index < 0)
      return false;

       
/*     console.log('JobManager update data',data);
    console.log('JobManager update index',index);
    console.log('JobManager update item',item);
    console.log('JobManager update data[index]',data[index]);   */
    
    data[index] = item;
 

    return true;
  }



}