import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Link,Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


import { JobListTable } from './JobListTable';



class JobList extends React.Component<App.JobListState, {}> {

  public jobService: App.JobService;

  constructor(props: any) {
    super(props);
    this.jobService = new App.JobService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('JobList componentDidMount');


    
   

    if (!this.props.hasDataLoaded) {
      console.log('JobList getJobListData');

      var submitCandidateJobId = App.RouteManager.getRouterQueryParam('submitCandidateJobId');

      console.log('JobList submitCandidateJobId',submitCandidateJobId);

      
      var filter = new Api.JobFilterVm();
      
      if(!LIB.Common.isNullOrEmpty(submitCandidateJobId)){
        filter.submitCandidateJobId = submitCandidateJobId;
      }
 
      this.jobService.getJobListData(filter,
        (result:any) => {
          console.log('getJobListData success...');
          this.checkShowSubmiteCandidate(result,submitCandidateJobId);
      });
 

      this.jobService.getJobListData();

      this.forceUpdate();
    }


  }

  
  public checkShowSubmiteCandidate(state:App.JobListState, submitCandidateJobId:any){
 
    // var state = this.props;
     console.log('checkShowSubmiteCandidate state', state);
  
     if(LIB.Common.isNullOrEmpty(submitCandidateJobId))
       return;
  
       var vm = state.vm;
       var jobs = state.jobs;
 
      // console.log('checkShowSubmiteCandidate candidates',candidates);
 
       var item = jobs?.find(x => x.jobId == submitCandidateJobId);
  
       console.log('checkShowSubmiteCandidate item',item);
 
       if(item==undefined)
           return;
  
         vm.setSelectedJob(item);
         
 
         this.forceUpdate();
   }

  public componentWillUnmount() {
    console.log("componentWillUnmount");
   // App.AppStore.dispatch(App.ActionTypes.JOB_LIST_PAGE_RESET, null);

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('JobList componentDidUpdate');

  }

  public onInputChange(newVal: any) {
    console.log('onInputChange:' + newVal);


  }


  public onSearchClick() {
    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.searchFilter;

    this.jobService.getJobListData(searchFilter);


  }

  
  public getSubmitJobsLink(){

    if(!App.AppBase.isUserAuthenticatedAndEmailConfirmed)
      return null;

    return <div  className='submitLinkBtn'>
    
    <Link
    key='submitJobsLink'
    onClick={(e: any) => App.RouteManager.routeToPath('/MyAccount/Jobs')}
    color="primary">SUBMIT JOBS</Link>
    </div>
  }

  public render() {

    console.log('JobList render');

    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.searchFilter;

    console.log('JobList hasDataLoaded', state.hasDataLoaded);
    console.log('JobList isServiceCallPending', this.jobService.isServiceCallPending);
    console.log('JobList vm.isActionInProgress', vm.isActionInProgress);

   
    var loginWarning = App.AppBase.getLoginToForFullAccessWarning("You're only viewing first 50 record.");

    return <App.MainPage
      pageTitle="Jobs"
      state={vm}
      hasDataLoaded={state.hasDataLoaded}
      loading={(state.isServiceCallPending && !vm.isActionInProgress)}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      alignError='left'
      showLeftMenu={true}
    >
      <div>

        {<LIB.MSearchBar filter={searchFilter} onSearchClick={() => this.onSearchClick()}
          disabled={!App.AppBase.isUserAuthenticatedAndEmailConfirmed}
        />}

        {loginWarning}

        {/*  <div style={{ textAlign: 'right', width: '100%'  }} >

            <Button
              id="btnCreate"
              variant="contained"
              style={{marginBottom:10}}
              color="primary"
              
              onClick={this.onCreateClick.bind(this)}
            >New Request</Button>
 
        </div>
 */}

        {this.getSubmitJobsLink()}
        <JobListTable state={state} />


        {loginWarning}

      </div>



    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.jobListState })
)(JobList as any);

