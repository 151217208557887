import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


interface PasswordResetProps {
  //vm: App.ProjectListState;
}

//export class PasswordReset extends React.PureComponent<PasswordResetProps> {
class PasswordReset extends React.Component<App.GuestUserState, {}> {

  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('PasswordReset componentDidMount');

    if(App.AppBase.isUserAuthenticated){
      App.AuthManager.logout();
      this.forceUpdate();
    }
   

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('PasswordReset componentDidUpdate');
  }

  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    var state = this.props;
    var vm = state.vm;
    var sm = state.passwordResetSm;


    var target: any = e.target;

    var key = LIB.FormHelper.setInputChangeData(e.target, sm);

    console.log('onInputChange key', key);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validatePasswordReset(key, sm);

    this.forceUpdate();
  }


  public onResetClick(e: React.ChangeEvent<{}>) {
    console.log('onResetClick');

    var state = this.props;
    var vm = state.vm;
    var sm = state.passwordResetSm;

    var isValid = this.validationManager.validatePasswordReset(null, sm);

    if (!isValid) {
      console.log('validatePasswordReset NOT valid');
      this.forceUpdate();
      return;
    }

    //call api
    App.AppStore.dispatch(App.ActionTypes.GUESTUSER_PASSWORD_RESET_REQUEST, sm);

    //this.forceUpdate();

  }


  public render() {

    // console.log("PasswordReset Render", this.props);
    var state = this.props;
    var vm = state.vm;
    var sm = state.passwordResetSm;
  
    var formInput: CSSProperties = {
      height: 50
    };

    var formControl: CSSProperties = {
      marginTop: 10,
      width: '100%'
    };

    sm.userId = App.RouteManager.getQueryString("userId");
    var code = App.RouteManager.getQueryString("code") as string;

   
    if (LIB.Common.isNullOrEmpty(sm.userId) || LIB.Common.isNullOrEmpty(code) || sm.userId<1 || code.length<20) {

      return <App.QuickMessageContainer icon='error'
            message='Error: Invalid password reset link!' />
    }
    else 
      sm.code = code.replace(/\s/g, "+"); 

    return <App.MainPage
      state={vm}
      hasDataLoaded={true}
      loading={state.isServiceCallPending}
      //loading={true}
      showContentWhenInProgress={true}
      showModalForLoading={true}
    >

      <Grid container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ width: '97vw', marginTop: 120 }}

      >

        <Card style={{ padding: 20, width: 400, minHeight: 300 }}>
          <CardContent  >

            <Typography variant="h5" gutterBottom style={{ textAlign: 'center', marginBottom: 25 }}>
              Forgot Password
            </Typography>
            <div style={{ marginTop: 10, marginBottom: 10 }}>

              Enter your email to receive an email with password reset link

            </div>

            <div>
              <TextField
                name="password"
                label="New Password"
                type='password'
                {...App.AppBase.getInputErrorProps("password")}
                onChange={(e: any) => this.onInputChange(e)}
                ///style={fullWidth}
                fullWidth
                style={formControl}
                //variant="outlined"
                InputProps={{
                  className: 'standardFormInput30',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icons.LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div>
              <TextField
                name="confirmPassword"
                label="Confirm New Password"
                type='password'
                {...App.AppBase.getInputErrorProps("confirmPassword")}
                onChange={(e: any) => this.onInputChange(e)}
                ///style={fullWidth}
                fullWidth
                style={formControl}
                //variant="outlined"
                InputProps={{
                  className: 'standardFormInput30',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icons.LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </div>


            <div className='standardFormInput30'>
              <Button variant="contained" color="primary" style={{ width: '100%' }}
                onClick={(e) => this.onResetClick(e)}
              >Reset</Button>
            </div>



            <div style={{marginTop:30, textAlign:'center'}}>
              <RouterLink to="/Login"  color="primary">Return to Login</RouterLink>
              </div>

          </CardContent>
        </Card>

      </Grid >
    </App.MainPage>
      ;

  }
};
export default connect(
  (state: App.AppState) => ({ ...state.guestUserData })
)(PasswordReset as any);

