import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Snackbar } from '@mui/material';
//import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as LIB from '_LIB';
import Alert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';


import { createStyles,  Theme } from '@mui/material/styles';

//import './AccountMenuItem.css'
  
interface NotifyProps {
  message: string; 
  visible?: boolean;
  duration?: number; 
 
}

export class Notify extends React.Component<NotifyProps, {}> {

  constructor(props: any) {
    super(props);
    //  this.state = { visible: false, isCancel:false };

  }
  
  // This method is called when the component is first added to the document
  public componentDidMount() {

    
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('componentDidUpdate:'+this.state.visible);

    
  }
 /* 

  public onConfirmClick() {

    if(!LIB.Common.isNullOrEmpty(this.props.onConfirmClick))
       this.props.onConfirmClick();

  }

  public onCancelClick() {
    
    if(!LIB.Common.isNullOrEmpty(this.props.onCancelClick))
          this.props.onCancelClick();
  }

  
  public getAlert(){
    var error = this.props.errorMessage;
    if(LIB.Common.isNullOrEmpty(error))
      return null;
    return  <Alert severity="error">{error}</Alert>;
  }
 */

  public static showSuccess(message:string){

   // var props  ={open:true, autoHideDuration:3000} as UI.SnackbarProps;
    //var sn = new Snackbar(props);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    var options = {open:true, autoHideDuration:5000, variant:'success'};
    
    // @ts-ignore
    enqueueSnackbar(message,options);
    
  }

  public render() {

   // console.log('Notify render');

    var isOpen = true;
    if(this.props.visible===false && this.props.visible!==undefined)
        isOpen = false;
       
    var duration = this.props.duration;
    if(!duration)
        duration = 3000;

    return (
      <Snackbar 
      open={isOpen} 
      autoHideDuration={duration} 
     // onClose={handleClose}
      >
      <Alert 
        //onClose={handleClose} 
        severity="success"
      >
       {this.props.message}
      </Alert>
      </Snackbar>
    );



  }
} 
