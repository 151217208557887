import * as LIB from '_LIB';
import moment from "moment";


interface Country {
  name: string;
  code: string;
  code3: string;
  numericCode: number;
  tld: string;
}

export class LocationCountryHelper {


  public static getCountries(): Country[] {

    var list: Country[] = [];

    list.push({ name: 'United States (USA)', code: 'US', code3:'USA',numericCode:840,tld:'.us'});
    list.push({ name: 'Afghanistan', code: 'AF', code3:'AFG',numericCode:4,tld:'.af'});
    list.push({ name: 'Åland Islands', code: 'AX', code3:'ALA',numericCode:248,tld:'.ax'});
    list.push({ name: 'Albania', code: 'AL', code3:'ALB',numericCode:8,tld:'.al'});
    list.push({ name: 'Algeria', code: 'DZ', code3:'DZA',numericCode:12,tld:'.dz'});
    list.push({ name: 'American Samoa', code: 'AS', code3:'ASM',numericCode:16,tld:'.as'});
    list.push({ name: 'Andorra', code: 'AD', code3:'AND',numericCode:20,tld:'.ad'});
    list.push({ name: 'Angola', code: 'AO', code3:'AGO',numericCode:24,tld:'.ao'});
    list.push({ name: 'Anguilla', code: 'AI', code3:'AIA',numericCode:660,tld:'.ai'});
    list.push({ name: 'Antarctica', code: 'AQ', code3:'ATA',numericCode:10,tld:'.aq'});
    list.push({ name: 'Antigua and Barbuda', code: 'AG', code3:'ATG',numericCode:28,tld:'.ag'});
    list.push({ name: 'Argentina', code: 'AR', code3:'ARG',numericCode:32,tld:'.ar'});
    list.push({ name: 'Armenia', code: 'AM', code3:'ARM',numericCode:51,tld:'.am'});
    list.push({ name: 'Aruba', code: 'AW', code3:'ABW',numericCode:533,tld:'.aw'});
    list.push({ name: 'Australia', code: 'AU', code3:'AUS',numericCode:36,tld:'.au'});
    list.push({ name: 'Austria', code: 'AT', code3:'AUT',numericCode:40,tld:'.at'});
    list.push({ name: 'Azerbaijan', code: 'AZ', code3:'AZE',numericCode:31,tld:'.az'});
    list.push({ name: 'Bahamas', code: 'BS', code3:'BHS',numericCode:44,tld:'.bs'});
    list.push({ name: 'Bahrain', code: 'BH', code3:'BHR',numericCode:48,tld:'.bh'});
    list.push({ name: 'Bangladesh', code: 'BD', code3:'BGD',numericCode:50,tld:'.bd'});
    list.push({ name: 'Barbados', code: 'BB', code3:'BRB',numericCode:52,tld:'.bb'});
    list.push({ name: 'Belarus', code: 'BY', code3:'BLR',numericCode:112,tld:'.by'});
    list.push({ name: 'Belgium', code: 'BE', code3:'BEL',numericCode:56,tld:'.be'});
    list.push({ name: 'Belize', code: 'BZ', code3:'BLZ',numericCode:84,tld:'.bz'});
    list.push({ name: 'Benin', code: 'BJ', code3:'BEN',numericCode:204,tld:'.bj'});
    list.push({ name: 'Bermuda', code: 'BM', code3:'BMU',numericCode:60,tld:'.bm'});
    list.push({ name: 'Bhutan', code: 'BT', code3:'BTN',numericCode:64,tld:'.bt'});
    list.push({ name: 'Bolivia', code: 'BO', code3:'BOL',numericCode:68,tld:'.bo'});
    list.push({ name: 'Bonaire', code: 'BQ', code3:'BES',numericCode:535,tld:'.bq .nl'});
    list.push({ name: 'Sint Eustatius', code: 'BQ', code3:'BES',numericCode:535,tld:'.bq .nl'});
    list.push({ name: 'Saba', code: 'BQ', code3:'BES',numericCode:535,tld:'.bq .nl'});
    list.push({ name: 'Bosnia and Herzegovina', code: 'BA', code3:'BIH',numericCode:70,tld:'.ba'});
    list.push({ name: 'Botswana', code: 'BW', code3:'BWA',numericCode:72,tld:'.bw'});
    list.push({ name: 'Bouvet Island', code: 'BV', code3:'BVT',numericCode:74,tld:''});
    list.push({ name: 'Brazil', code: 'BR', code3:'BRA',numericCode:76,tld:'.br'});
    list.push({ name: 'British Indian Ocean Territory', code: 'IO', code3:'IOT',numericCode:86,tld:'.io'});
    list.push({ name: 'Brunei Darussalam', code: 'BN', code3:'BRN',numericCode:96,tld:'.bn'});
    list.push({ name: 'Bulgaria', code: 'BG', code3:'BGR',numericCode:100,tld:'.bg'});
    list.push({ name: 'Burkina Faso', code: 'BF', code3:'BFA',numericCode:854,tld:'.bf'});
    list.push({ name: 'Burundi', code: 'BI', code3:'BDI',numericCode:108,tld:'.bi'});
    list.push({ name: 'Cabo Verde', code: 'CV', code3:'CPV',numericCode:132,tld:'.cv'});
    list.push({ name: 'Cambodia', code: 'KH', code3:'KHM',numericCode:116,tld:'.kh'});
    list.push({ name: 'Cameroon', code: 'CM', code3:'CMR',numericCode:120,tld:'.cm'});
    list.push({ name: 'Canada', code: 'CA', code3:'CAN',numericCode:124,tld:'.ca'});
    list.push({ name: 'Cape Verde', code: 'CV', code3:'CPV',numericCode:132,tld:'.cv'});
    list.push({ name: 'Cayman Islands', code: 'KY', code3:'CYM',numericCode:136,tld:'.ky'});
    list.push({ name: 'Central African Republic', code: 'CF', code3:'CAF',numericCode:140,tld:'.cf'});
    list.push({ name: 'Chad', code: 'TD', code3:'TCD',numericCode:148,tld:'.td'});
    list.push({ name: 'Chile', code: 'CL', code3:'CHL',numericCode:152,tld:'.cl'});
    list.push({ name: 'China', code: 'CN', code3:'CHN',numericCode:156,tld:'.cn'});
    list.push({ name: 'Christmas Island', code: 'CX', code3:'CXR',numericCode:162,tld:'.cx'});
    list.push({ name: 'Clipperton Island', code: 'FR', code3:'FRA',numericCode:250,tld:'.fr'});
    list.push({ name: 'Cocos (Keeling) Islands', code: 'CC', code3:'CCK',numericCode:166,tld:'.cc'});
    list.push({ name: 'Colombia', code: 'CO', code3:'COL',numericCode:170,tld:'.co'});
    list.push({ name: 'Comoros', code: 'KM', code3:'COM',numericCode:174,tld:'.km'});
    list.push({ name: 'Congo (the Democratic Republic of the)', code: 'CD', code3:'COD',numericCode:180,tld:'.cd'});
    list.push({ name: 'Congo', code: 'CG', code3:'COG',numericCode:178,tld:'.cg'});
    list.push({ name: 'Cook Islands', code: 'CK', code3:'COK',numericCode:184,tld:'.ck'});
    list.push({ name: 'Costa Rica', code: 'CR', code3:'CRI',numericCode:188,tld:'.cr'});
    list.push({ name: 'Côte dIvoire', code: 'CI', code3:'CIV',numericCode:384,tld:'.ci'});
    list.push({ name: 'Croatia', code: 'HR', code3:'HRV',numericCode:191,tld:'.hr'});
    list.push({ name: 'Cuba', code: 'CU', code3:'CUB',numericCode:192,tld:'.cu'});
    list.push({ name: 'Curaçao', code: 'CW', code3:'CUW',numericCode:531,tld:'.cw'});
    list.push({ name: 'Cyprus', code: 'CY', code3:'CYP',numericCode:196,tld:'.cy'});
    list.push({ name: 'Czechia', code: 'CZ', code3:'CZE',numericCode:203,tld:'.cz'});
    list.push({ name: 'Denmark', code: 'DK', code3:'DNK',numericCode:208,tld:'.dk'});
    list.push({ name: 'Djibouti', code: 'DJ', code3:'DJI',numericCode:262,tld:'.dj'});
    list.push({ name: 'Dominica', code: 'DM', code3:'DMA',numericCode:212,tld:'.dm'});
    list.push({ name: 'Dominican Republic', code: 'DO', code3:'DOM',numericCode:214,tld:'.do'});
    list.push({ name: 'Ecuador', code: 'EC', code3:'ECU',numericCode:218,tld:'.ec'});
    list.push({ name: 'Egypt', code: 'EG', code3:'EGY',numericCode:818,tld:'.eg'});
    list.push({ name: 'El Salvador', code: 'SV', code3:'SLV',numericCode:222,tld:'.sv'});
    list.push({ name: 'Equatorial Guinea', code: 'GQ', code3:'GNQ',numericCode:226,tld:'.gq'});
    list.push({ name: 'Eritrea', code: 'ER', code3:'ERI',numericCode:232,tld:'.er'});
    list.push({ name: 'Estonia', code: 'EE', code3:'EST',numericCode:233,tld:'.ee'});
    list.push({ name: 'Eswatini', code: 'SZ', code3:'SWZ',numericCode:748,tld:'.sz'});
    list.push({ name: 'Ethiopia', code: 'ET', code3:'ETH',numericCode:231,tld:'.et'});
    list.push({ name: 'Falkland Islands', code: 'FK', code3:'FLK',numericCode:238,tld:'.fk'});
    list.push({ name: 'Faroe Islands', code: 'FO', code3:'FRO',numericCode:234,tld:'.fo'});
    list.push({ name: 'Fiji', code: 'FJ', code3:'FJI',numericCode:242,tld:'.fj'});
    list.push({ name: 'Finland', code: 'FI', code3:'FIN',numericCode:246,tld:'.fi'});
    list.push({ name: 'France', code: 'FR', code3:'FRA',numericCode:250,tld:'.fr'});
    list.push({ name: 'French Guiana', code: 'GF', code3:'GUF',numericCode:254,tld:'.gf'});
    list.push({ name: 'French Polynesia', code: 'PF', code3:'PYF',numericCode:258,tld:'.pf'});
    list.push({ name: 'French Southern Territories', code: 'TF', code3:'ATF',numericCode:260,tld:'.tf'});
    list.push({ name: 'Gabon', code: 'GA', code3:'GAB',numericCode:266,tld:'.ga'});
    list.push({ name: 'Gambia', code: 'GM', code3:'GMB',numericCode:270,tld:'.gm'});
    list.push({ name: 'Georgia', code: 'GE', code3:'GEO',numericCode:268,tld:'.ge'});
    list.push({ name: 'Germany', code: 'DE', code3:'DEU',numericCode:276,tld:'.de'});
    list.push({ name: 'Ghana', code: 'GH', code3:'GHA',numericCode:288,tld:'.gh'});
    list.push({ name: 'Gibraltar', code: 'GI', code3:'GIB',numericCode:292,tld:'.gi'});
    list.push({ name: 'Greece', code: 'GR', code3:'GRC',numericCode:300,tld:'.gr'});
    list.push({ name: 'Greenland', code: 'GL', code3:'GRL',numericCode:304,tld:'.gl'});
    list.push({ name: 'Grenada', code: 'GD', code3:'GRD',numericCode:308,tld:'.gd'});
    list.push({ name: 'Guadeloupe', code: 'GP', code3:'GLP',numericCode:312,tld:'.gp'});
    list.push({ name: 'Guam', code: 'GU', code3:'GUM',numericCode:316,tld:'.gu'});
    list.push({ name: 'Guatemala', code: 'GT', code3:'GTM',numericCode:320,tld:'.gt'});
    list.push({ name: 'Guernsey', code: 'GG', code3:'GGY',numericCode:831,tld:'.gg'});
    list.push({ name: 'Guinea', code: 'GN', code3:'GIN',numericCode:324,tld:'.gn'});
    list.push({ name: 'Guinea-Bissau', code: 'GW', code3:'GNB',numericCode:624,tld:'.gw'});
    list.push({ name: 'Guyana', code: 'GY', code3:'GUY',numericCode:328,tld:'.gy'});
    list.push({ name: 'Haiti', code: 'HT', code3:'HTI',numericCode:332,tld:'.ht'});
    list.push({ name: 'Heard Island and McDonald Islands', code: 'HM', code3:'HMD',numericCode:334,tld:'.hm'});
    list.push({ name: 'Holy', code: 'VA', code3:'VAT',numericCode:336,tld:'.va'});
    list.push({ name: 'Honduras', code: 'HN', code3:'HND',numericCode:340,tld:'.hn'});
    list.push({ name: 'Hong Kong', code: 'HK', code3:'HKG',numericCode:344,tld:'.hk'});
    list.push({ name: 'Hungary', code: 'HU', code3:'HUN',numericCode:348,tld:'.hu'});
    list.push({ name: 'Iceland', code: 'IS', code3:'ISL',numericCode:352,tld:'.is'});
    list.push({ name: 'India', code: 'IN', code3:'IND',numericCode:356,tld:'.in'});
    list.push({ name: 'Indonesia', code: 'ID', code3:'IDN',numericCode:360,tld:'.id'});
    list.push({ name: 'Iran (Islamic Republic of)', code: 'IR', code3:'IRN',numericCode:364,tld:'.ir'});
    list.push({ name: 'Iraq', code: 'IQ', code3:'IRQ',numericCode:368,tld:'.iq'});
    list.push({ name: 'Ireland', code: 'IE', code3:'IRL',numericCode:372,tld:'.ie'});
    list.push({ name: 'Isle of Man', code: 'IM', code3:'IMN',numericCode:833,tld:'.im'});
    list.push({ name: 'Israel', code: 'IL', code3:'ISR',numericCode:376,tld:'.il'});
    list.push({ name: 'Italy', code: 'IT', code3:'ITA',numericCode:380,tld:'.it'});
    list.push({ name: 'Jamaica', code: 'JM', code3:'JAM',numericCode:388,tld:'.jm'});
    list.push({ name: 'Japan', code: 'JP', code3:'JPN',numericCode:392,tld:'.jp'});
    list.push({ name: 'Jersey', code: 'JE', code3:'JEY',numericCode:832,tld:'.je'});
    list.push({ name: 'Jordan', code: 'JO', code3:'JOR',numericCode:400,tld:'.jo'});
    list.push({ name: 'Kazakhstan', code: 'KZ', code3:'KAZ',numericCode:398,tld:'.kz'});
    list.push({ name: 'Kenya', code: 'KE', code3:'KEN',numericCode:404,tld:'.ke'});
    list.push({ name: 'Kiribati', code: 'KI', code3:'KIR',numericCode:296,tld:'.ki'});
    list.push({ name: 'Korea (the Democratic People', code: 'KP', code3:'PRK',numericCode:408,tld:'.kp'});
    list.push({ name: 'Korea', code: 'KR', code3:'KOR',numericCode:410,tld:'.kr'});
    list.push({ name: 'Kuwait', code: 'KW', code3:'KWT',numericCode:414,tld:'.kw'});
    list.push({ name: 'Kyrgyzstan', code: 'KG', code3:'KGZ',numericCode:417,tld:'.kg'});
    list.push({ name: 'Lao - Democratic Republic', code: 'LA', code3:'LAO',numericCode:418,tld:'.la'});
    list.push({ name: 'Latvia', code: 'LV', code3:'LVA',numericCode:428,tld:'.lv'});
    list.push({ name: 'Lebanon', code: 'LB', code3:'LBN',numericCode:422,tld:'.lb'});
    list.push({ name: 'Lesotho', code: 'LS', code3:'LSO',numericCode:426,tld:'.ls'});
    list.push({ name: 'Liberia', code: 'LR', code3:'LBR',numericCode:430,tld:'.lr'});
    list.push({ name: 'Libya', code: 'LY', code3:'LBY',numericCode:434,tld:'.ly'});
    list.push({ name: 'Liechtenstein', code: 'LI', code3:'LIE',numericCode:438,tld:'.li'});
    list.push({ name: 'Lithuania', code: 'LT', code3:'LTU',numericCode:440,tld:'.lt'});
    list.push({ name: 'Luxembourg', code: 'LU', code3:'LUX',numericCode:442,tld:'.lu'});
    list.push({ name: 'Macao', code: 'MO', code3:'MAC',numericCode:446,tld:'.mo'});
    list.push({ name: 'North Macedonia', code: 'MK', code3:'MKD',numericCode:807,tld:'.mk'});
    list.push({ name: 'Madagascar', code: 'MG', code3:'MDG',numericCode:450,tld:'.mg'});
    list.push({ name: 'Malawi', code: 'MW', code3:'MWI',numericCode:454,tld:'.mw'});
    list.push({ name: 'Malaysia', code: 'MY', code3:'MYS',numericCode:458,tld:'.my'});
    list.push({ name: 'Maldives', code: 'MV', code3:'MDV',numericCode:462,tld:'.mv'});
    list.push({ name: 'Mali', code: 'ML', code3:'MLI',numericCode:466,tld:'.ml'});
    list.push({ name: 'Malta', code: 'MT', code3:'MLT',numericCode:470,tld:'.mt'});
    list.push({ name: 'Marshall Islands', code: 'MH', code3:'MHL',numericCode:584,tld:'.mh'});
    list.push({ name: 'Martinique', code: 'MQ', code3:'MTQ',numericCode:474,tld:'.mq'});
    list.push({ name: 'Mauritania', code: 'MR', code3:'MRT',numericCode:478,tld:'.mr'});
    list.push({ name: 'Mauritius', code: 'MU', code3:'MUS',numericCode:480,tld:'.mu'});
    list.push({ name: 'Mayotte', code: 'YT', code3:'MYT',numericCode:175,tld:'.yt'});
    list.push({ name: 'Mexico', code: 'MX', code3:'MEX',numericCode:484,tld:'.mx'});
    list.push({ name: 'Micronesia (Federated States of)', code: 'FM', code3:'FSM',numericCode:583,tld:'.fm'});
    list.push({ name: 'Moldova', code: 'MD', code3:'MDA',numericCode:498,tld:'.md'});
    list.push({ name: 'Monaco', code: 'MC', code3:'MCO',numericCode:492,tld:'.mc'});
    list.push({ name: 'Mongolia', code: 'MN', code3:'MNG',numericCode:496,tld:'.mn'});
    list.push({ name: 'Montenegro', code: 'ME', code3:'MNE',numericCode:499,tld:'.me'});
    list.push({ name: 'Montserrat', code: 'MS', code3:'MSR',numericCode:500,tld:'.ms'});
    list.push({ name: 'Morocco', code: 'MA', code3:'MAR',numericCode:504,tld:'.ma'});
    list.push({ name: 'Mozambique', code: 'MZ', code3:'MOZ',numericCode:508,tld:'.mz'});
    list.push({ name: 'Myanmar', code: 'MM', code3:'MMR',numericCode:104,tld:'.mm'});
    list.push({ name: 'Namibia', code: 'NA', code3:'NAM',numericCode:516,tld:'.na'});
    list.push({ name: 'Nauru', code: 'NR', code3:'NRU',numericCode:520,tld:'.nr'});
    list.push({ name: 'Nepal', code: 'NP', code3:'NPL',numericCode:524,tld:'.np'});
    list.push({ name: 'Netherlands', code: 'NL', code3:'NLD',numericCode:528,tld:'.nl'});
    list.push({ name: 'New Caledonia', code: 'NC', code3:'NCL',numericCode:540,tld:'.nc'});
    list.push({ name: 'New Zealand', code: 'NZ', code3:'NZL',numericCode:554,tld:'.nz'});
    list.push({ name: 'Nicaragua', code: 'NI', code3:'NIC',numericCode:558,tld:'.ni'});
    list.push({ name: 'Niger', code: 'NE', code3:'NER',numericCode:562,tld:'.ne'});
    list.push({ name: 'Nigeria', code: 'NG', code3:'NGA',numericCode:566,tld:'.ng'});
    list.push({ name: 'Niue', code: 'NU', code3:'NIU',numericCode:570,tld:'.nu'});
    list.push({ name: 'Norfolk Island', code: 'NF', code3:'NFK',numericCode:574,tld:'.nf'});
    list.push({ name: 'Northern Mariana Islands', code: 'MP', code3:'MNP',numericCode:580,tld:'.mp'});
    list.push({ name: 'Norway', code: 'NO', code3:'NOR',numericCode:578,tld:'.no'});
    list.push({ name: 'Oman', code: 'OM', code3:'OMN',numericCode:512,tld:'.om'});
    list.push({ name: 'Pakistan', code: 'PK', code3:'PAK',numericCode:586,tld:'.pk'});
    list.push({ name: 'Palau', code: 'PW', code3:'PLW',numericCode:585,tld:'.pw'});
    list.push({ name: 'Palestine, State of', code: 'PS', code3:'PSE',numericCode:275,tld:'.ps'});
    list.push({ name: 'Panama', code: 'PA', code3:'PAN',numericCode:591,tld:'.pa'});
    list.push({ name: 'Papua New Guinea', code: 'PG', code3:'PNG',numericCode:598,tld:'.pg'});
    list.push({ name: 'Paraguay', code: 'PY', code3:'PRY',numericCode:600,tld:'.py'});
    list.push({ name: 'Peru', code: 'PE', code3:'PER',numericCode:604,tld:'.pe'});
    list.push({ name: 'Philippines', code: 'PH', code3:'PHL',numericCode:608,tld:'.ph'});
    list.push({ name: 'Pitcairn', code: 'PN', code3:'PCN',numericCode:612,tld:'.pn'});
    list.push({ name: 'Poland', code: 'PL', code3:'POL',numericCode:616,tld:'.pl'});
    list.push({ name: 'Portugal', code: 'PT', code3:'PRT',numericCode:620,tld:'.pt'});
    list.push({ name: 'Puerto Rico', code: 'PR', code3:'PRI',numericCode:630,tld:'.pr'});
    list.push({ name: 'Qatar', code: 'QA', code3:'QAT',numericCode:634,tld:'.qa'});
    list.push({ name: 'Réunion', code: 'RE', code3:'REU',numericCode:638,tld:'.re'});
    list.push({ name: 'Romania', code: 'RO', code3:'ROU',numericCode:642,tld:'.ro'});
    list.push({ name: 'Russian Federation', code: 'RU', code3:'RUS',numericCode:643,tld:'.ru'});
    list.push({ name: 'Rwanda', code: 'RW', code3:'RWA',numericCode:646,tld:'.rw'});
    list.push({ name: 'Saint Barthélemy', code: 'BL', code3:'BLM',numericCode:652,tld:'.bl'});
    list.push({ name: 'Saint Helena', code: 'SH', code3:'SHN',numericCode:654,tld:'.sh'});
    list.push({ name: 'Saint Kitts and Nevis', code: 'KN', code3:'KNA',numericCode:659,tld:'.kn'});
    list.push({ name: 'Saint Lucia', code: 'LC', code3:'LCA',numericCode:662,tld:'.lc'});
    list.push({ name: 'Saint Martin (French part)', code: 'MF', code3:'MAF',numericCode:663,tld:'.mf'});
    list.push({ name: 'Saint Pierre and Miquelon', code: 'PM', code3:'SPM',numericCode:666,tld:'.pm'});
    list.push({ name: 'Saint Vincent and the Grenadines', code: 'VC', code3:'VCT',numericCode:670,tld:'.vc'});
    list.push({ name: 'Samoa', code: 'WS', code3:'WSM',numericCode:882,tld:'.ws'});
    list.push({ name: 'San Marino', code: 'SM', code3:'SMR',numericCode:674,tld:'.sm'});
    list.push({ name: 'Sao Tome and Principe', code: 'ST', code3:'STP',numericCode:678,tld:'.st'});
    list.push({ name: 'Saudi Arabia', code: 'SA', code3:'SAU',numericCode:682,tld:'.sa'});
    list.push({ name: 'Senegal', code: 'SN', code3:'SEN',numericCode:686,tld:'.sn'});
    list.push({ name: 'Serbia', code: 'RS', code3:'SRB',numericCode:688,tld:'.rs'});
    list.push({ name: 'Seychelles', code: 'SC', code3:'SYC',numericCode:690,tld:'.sc'});
    list.push({ name: 'Sierra Leone', code: 'SL', code3:'SLE',numericCode:694,tld:'.sl'});
    list.push({ name: 'Singapore', code: 'SG', code3:'SGP',numericCode:702,tld:'.sg'});
    list.push({ name: 'Sint Maarten (Dutch part)', code: 'SX', code3:'SXM',numericCode:534,tld:'.sx'});
    list.push({ name: 'Slovakia', code: 'SK', code3:'SVK',numericCode:703,tld:'.sk'});
    list.push({ name: 'Slovenia', code: 'SI', code3:'SVN',numericCode:705,tld:'.si'});
    list.push({ name: 'Solomon Islands', code: 'SB', code3:'SLB',numericCode:90,tld:'.sb'});
    list.push({ name: 'Somalia', code: 'SO', code3:'SOM',numericCode:706,tld:'.so'});
    list.push({ name: 'South Africa', code: 'ZA', code3:'ZAF',numericCode:710,tld:'.za'});
    list.push({ name: 'South Georgia and the South Sandwich Islands', code: 'GS', code3:'SGS',numericCode:239,tld:'.gs'});
    list.push({ name: 'South Korea', code: 'KR', code3:'KOR',numericCode:410,tld:'.kr'});
    list.push({ name: 'South Sudan', code: 'SS', code3:'SSD',numericCode:728,tld:'.ss'});
    list.push({ name: 'Spain', code: 'ES', code3:'ESP',numericCode:724,tld:'.es'});
    list.push({ name: 'Sri Lanka', code: 'LK', code3:'LKA',numericCode:144,tld:'.lk'});
    list.push({ name: 'Sudan', code: 'SD', code3:'SDN',numericCode:729,tld:'.sd'});
    list.push({ name: 'Suriname', code: 'SR', code3:'SUR',numericCode:740,tld:'.sr'});
    list.push({ name: 'Svalbard', code: 'SJ', code3:'SJM',numericCode:744,tld:''});
    list.push({ name: 'Sweden', code: 'SE', code3:'SWE',numericCode:752,tld:'.se'});
    list.push({ name: 'Switzerland', code: 'CH', code3:'CHE',numericCode:756,tld:'.ch'});
    list.push({ name: 'Syrian Arab Republic', code: 'SY', code3:'SYR',numericCode:760,tld:'.sy'});
    list.push({ name: 'Taiwan', code: 'TW', code3:'TWN',numericCode:158,tld:'.tw'});
    list.push({ name: 'Tajikistan', code: 'TJ', code3:'TJK',numericCode:762,tld:'.tj'});
    list.push({ name: 'Tanzania, the United Republic of', code: 'TZ', code3:'TZA',numericCode:834,tld:'.tz'});
    list.push({ name: 'Thailand', code: 'TH', code3:'THA',numericCode:764,tld:'.th'});
    list.push({ name: 'Timor-Leste', code: 'TL', code3:'TLS',numericCode:626,tld:'.tl'});
    list.push({ name: 'Togo', code: 'TG', code3:'TGO',numericCode:768,tld:'.tg'});
    list.push({ name: 'Tokelau', code: 'TK', code3:'TKL',numericCode:772,tld:'.tk'});
    list.push({ name: 'Tonga', code: 'TO', code3:'TON',numericCode:776,tld:'.to'});
    list.push({ name: 'Trinidad and Tobago', code: 'TT', code3:'TTO',numericCode:780,tld:'.tt'});
    list.push({ name: 'Tunisia', code: 'TN', code3:'TUN',numericCode:788,tld:'.tn'});
    list.push({ name: 'Turkey', code: 'TR', code3:'TUR',numericCode:792,tld:'.tr'});
    list.push({ name: 'Turkmenistan', code: 'TM', code3:'TKM',numericCode:795,tld:'.tm'});
    list.push({ name: 'Turks and Caicos Islands', code: 'TC', code3:'TCA',numericCode:796,tld:'.tc'});
    list.push({ name: 'Tuvalu', code: 'TV', code3:'TUV',numericCode:798,tld:'.tv'});
    list.push({ name: 'Uganda', code: 'UG', code3:'UGA',numericCode:800,tld:'.ug'});
    list.push({ name: 'Ukraine', code: 'UA', code3:'UKR',numericCode:804,tld:'.ua'});
    list.push({ name: 'United Arab Emirates', code: 'AE', code3:'ARE',numericCode:784,tld:'.ae'});
    list.push({ name: 'United Kingdom of Great Britain and Northern Ireland', code: 'GB', code3:'GBR',numericCode:826,tld:'.gb .uk'});
    list.push({ name: 'United States Minor Outlying Islands', code: 'UM', code3:'UMI',numericCode:581,tld:''});
    list.push({ name: 'Uruguay', code: 'UY', code3:'URY',numericCode:858,tld:'.uy'});
    list.push({ name: 'Uzbekistan', code: 'UZ', code3:'UZB',numericCode:860,tld:'.uz'});
    list.push({ name: 'Vanuatu', code: 'VU', code3:'VUT',numericCode:548,tld:'.vu'});
    list.push({ name: 'Venezuela (Bolivarian Republic of)', code: 'VE', code3:'VEN',numericCode:862,tld:'.ve'});
    list.push({ name: 'Viet Nam', code: 'VN', code3:'VNM',numericCode:704,tld:'.vn'});
    list.push({ name: 'Virgin Islands (British)', code: 'VG', code3:'VGB',numericCode:92,tld:'.vg'});
    list.push({ name: 'Virgin Islands (U.S.)', code: 'VI', code3:'VIR',numericCode:850,tld:'.vi'});
    list.push({ name: 'Wallis and Futuna', code: 'WF', code3:'WLF',numericCode:876,tld:'.wf'});
    list.push({ name: 'Western Sahara', code: 'EH', code3:'ESH',numericCode:732,tld:''});
    list.push({ name: 'Yemen', code: 'YE', code3:'YEM',numericCode:887,tld:'.ye'});
    list.push({ name: 'Zambia', code: 'ZM', code3:'ZMB',numericCode:894,tld:'.zm'});
    list.push({ name: 'Zimbabwe', code: 'ZW', code3:'ZWE',numericCode:716,tld:'.zw'});
       
 
    return list;
  }




}