import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField,Box,Button,Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';

/* 
interface TestProps {
  //vm: App.ProjectListState;
} */

//class Test extends React.Component<TestProps, {}> {
class AccessDenied extends React.Component<App.GlobalState, {}> {


  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AccessDenied componentDidMount');
 
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AccessDenied componentDidUpdate');
  }


   
  public render() {
 
    var state = this.props;
    var vm = state.initialData;
    
  
     return <App.MainPage
     pageTitle="Access Denied"
     state={vm}
     hasDataLoaded={true}
 
   >
     <div>You do not have access to the page requested!</div>

      </App.MainPage>;

  }
};
export default connect(
  (state: App.AppState) => ({...state.globalData})
)(AccessDenied as any);
