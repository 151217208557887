
export class BaseStateModel  {
 
 
    public hasDataLoaded:boolean;
    public forceUpdateCount:number;
    isServiceCallPending: boolean = false;

    public apiErrorMessage:string='';
    
      
    constructor() { 
   
        this.hasDataLoaded = false;
        this.forceUpdateCount=0;
        this.apiErrorMessage = "";

    }
     
    


}