import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class MyAccountService extends App.BaseAppService  {
 

    private attachmentService:App.AttachmentService;

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
        this.attachmentService = new App.AttachmentService();
    } 

    public getJobListData(filter:Api.JobFilterVm|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/getJobListData?${urlPrams}`;
 
        this.get(apiUrl,
            App.ActionTypes.MY_ACCOUNT_JOBS_LIST_DATA_RECEIVED,
            App.ActionTypes.MY_ACCOUNT_API_ERROR 
            );

    } 
  

    public createJob(data:Api.Job) {
        console.log("MyAccountService.createJob");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/createJob`;
        this.post(apiUrl, data, 
            App.ActionTypes.MY_ACCOUNT_JOB_ADD_SUCCESS,
            App.ActionTypes.MY_ACCOUNT_API_ERROR
            );
    
    }
    public updateJob(data:Api.Job) {
        console.log("MyAccountService.updateJob");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/updateJob`;
        this.post(apiUrl, data, 
            App.ActionTypes.MY_ACCOUNT_JOB_UPDATE_SUCCESS,
            App.ActionTypes.MY_ACCOUNT_API_ERROR
            );
    
    }

    public deleteJob(jobId:number) {
        console.log("MyAccountService.deleteJob");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/deleteJob?jobId=${jobId}`;
        this.post(apiUrl, jobId, 
            App.ActionTypes.MY_ACCOUNT_JOB_DELETE_SUCCESS,
            App.ActionTypes.MY_ACCOUNT_API_ERROR
            );
 
    }
  
    
}
