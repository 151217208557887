import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import { Table, TableBody, TableCell, Link, TableContainer, Menu, Paper, MenuItem, TableRow, TableHead, Button, Icon } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import * as LIB from '_LIB';
//import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';

interface AdministrationJobsTableProps {
  state: App.AdministrationState;
  onChange?: Function;

}

export class AdministrationJobsTable extends React.PureComponent<AdministrationJobsTableProps> {

  public myListingsService: App.MyAccountService;

  constructor(props: any) {
    super(props);
    this.myListingsService = new App.MyAccountService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationJobsTable componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {

  }


  public onActionMenuItemClick(e: React.MouseEvent<HTMLElement>, key: string, id: any, rowIndex: number) {

    var vm = this.props.state.vm;

    this.setSelected(id);

    //var target:any = e.target;
    //console.log(e.currentTarget); 
        if(key=="Edit"){
        vm.isJobsEditOpen=true;
        }
        else if(key=="Delete"){
          vm.isJobsDeleteOpen=true;
         
        } 

        this.forceUpdate();

        if(this.props.onChange)
          this.props.onChange();
  }

  public getActionMenuItems(id: any, tableMeta: any, updateValue: any): any[] {
    //console.log('getActionMenuItems tableMeta:', tableMeta);
    //console.log('getActionMenuItems updateValue:', updateValue);

    var state = this.props.state;
    //var vm = state.vm;
    var jobs = state.jobs;

    var item = jobs?.find(x => x.jobId === id)
    var isReadOnly = false;

    /* 
        if(item!==undefined)
          isReadOnly = (item.workflowRecord.userPermissions.canEdit===false); */

    var result = [
      <MenuItem key="Edit"
        onClick={(e) => this.onActionMenuItemClick(e, 'Edit', id, tableMeta.rowIndex)}
      >Edit</MenuItem>,
      <MenuItem key="Delete"
          onClick={(e) => this.onActionMenuItemClick(e, 'Delete', id, tableMeta.rowIndex)}
        >Delete</MenuItem>
  
    ];

    return result;


  }

  public getTableColumns() {

    var readOnly = false;

    var pkColumn = LIB.MUITableHelper.getColumnSetup("ID", "jobId", false);
   // pkColumn.options.display = 'excluded';
    pkColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (

      <Link
        onClick={(e: any) => this.onActionMenuItemClick(e, 'Edit', value, tableMeta.rowIndex)}
        color="primary">{value}</Link>

    );

    var actionsColumn = LIB.MUITableHelper.getColumnSetup("Actions", "jobId", false);
    actionsColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (
      LIB.MUITableHelper.getActionsButton(value, tableMeta, updateValue,
        this.getActionMenuItems(value, tableMeta, updateValue)
      )
    );

    var nameColumn = LIB.MUITableHelper.getColumnSetup("Title", "title", false);
    nameColumn.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, { minWidth: 250 });
    }
 
    nameColumn.options.customBodyRender = (value:any, tableMeta: any, updateValue: any) => {

      var id = tableMeta.rowData[0];
      var item = this.props.state?.jobs?.find(x => x.jobId === id);

      if(item?.sortWeight!=undefined && item.sortWeight>0){

        return App.FeaturedManager.getFeaturedStar(value);
 
      }
     else 
       return value;

    };
 

    var visaTypeCol = LIB.MUITableHelper.getObjectChildPropertyColumn("Visa", "visaType", "name",false );

   // LIB.MUITableHelper.getColumnSetup("Alert", "name", false, "", this.getAlertColumnRenderer),
 
    var relocationCol = LIB.MUITableHelper.getObjectChildPropertyColumn("Relocation", "relocationType", "name");
    var dateUpdatedCol = LIB.MUITableHelper.getColumnSetupDateTime("Updated", "dateModified");

    var hotColumn = LIB.MUITableHelper.getColumnSetup("Hot", "isHot", true);
    hotColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => {
      if (value) {
        return <span title="HotList Job!"><Icons.Whatshot style={{ color: 'red' }} /></span>
      } 
    };

  
    var expColumn = LIB.MUITableHelper.getColumnSetup("Experience", "experience", true);
    expColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any)  => {

     
      if(LIB.Common.isNullOrEmpty(value))
          return '';

      return value+'+ years'

    };


    var skillsColumn = LIB.MUITableHelper.getColumnSetup("Skills", "skills");
    skillsColumn.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, { width: '100rem' });
    }
    skillsColumn.options.customBodyRender = (value: string, tableMeta: any, updateValue: any) => {

      if (LIB.Common.isNullOrEmpty(value))
        return '';
      var str = LIB.StringHelper.maxLength(value, 80);
      return <div style={{ maxWidth: '15rem' }} title={value}>{str}</div>;

    };

    var resumeRequestsColumn = LIB.MUITableHelper.getColumnSetup("Resume Requests", "resumeRequestCount", true);
    resumeRequestsColumn.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, { width:100 });
    }
    var sortWeightColumn = LIB.MUITableHelper.getColumnSetup("Sort Weight", "sortWeight", true);
    sortWeightColumn.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, { width:100 });
    }

    var titleColumn =   LIB.MUITableHelper.getColumnSetup("Title", "title", true);
    titleColumn.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, {width: 200 });
    }
    titleColumn.options.customBodyRender = (value:string, tableMeta: any, updateValue: any) => {

      
      return <div style={{width:'10rem'}} >{value}</div>;
       
   };

   var companyCol = LIB.MUITableHelper.getObjectChildPropertyColumn("Company", "company", "companyName");

    //relocationCol.options.display = 'excluded';

    var columns = [
      pkColumn,
      nameColumn,
      //LIB.MUITableHelper.getColumnSetup("Name", "name"),
    //  titleColumn,
     
      hotColumn,
      //resumeRequestsColumn,
      sortWeightColumn,
      LIB.MUITableHelper.getColumnSetup("Status", "statusName", true),
      LIB.MUITableHelper.getColumnSetupShortDate("Expire Date", "expireDate"),
      LIB.MUITableHelper.getColumnSetupShortDate("Publish Date", "datePublished"),

      dateUpdatedCol,
      LIB.MUITableHelper.getColumnSetup("Modified By", "modifiedByUserName", true),
      LIB.MUITableHelper.getColumnSetup("Owner", "ownerFullName", true),
      companyCol,
      
      
      actionsColumn,
    ];


    return columns;
  }

  
  public getAlertColumnRenderer = (value: any, tableMeta: any, updateValue: any) => (
    <App.AlertTypeIcon name={value} />
  )

  public onRowSelectionChange(currentRowsSelected: any[], allRowsSelected: any[], rowsSelected?: any[]) {

    //console.log('onRowSelectionChange currentRowsSelected',currentRowsSelected);

    var vm = this.props.state.vm;
    var state = this.props.state;

    var dataIndex = currentRowsSelected[0].dataIndex;

     var item = state.jobs[dataIndex];
     vm.setSelectedJob(item);

    //todo  vm.selectedIndex = dataIndex;


  }
  
  public setSelected(id: any){
    var state = this.props.state;
    var vm = state.vm;
    var found = state.jobs.find(x => x.jobId === id);

    vm.setSelectedJob(found);
  }

  public onNameClick(id: any) {
    var state = this.props.state;
    var vm = state.vm;
    var sm = state.jobSm;

   this.setSelected(id);
 
    vm.isJobsEditOpen = true;

    if(this.props.onChange!==undefined)
        this.props.onChange();

    this.forceUpdate();

  }

  public onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) {

    //console.log('onRowClick rowData', rowData);
    //console.log('onRowClick rowMeta', rowMeta);
    
    var vm = this.props.state.vm;


    // vm.selectedRowIndex = rowMeta.rowIndex;
    //this.forceUpdate();

  }


  public onPagingChange(tableState:MUIDataTableState){

    var vm = this.props.state.vm;
 
    var searchFilter = this.props.state.jobSearchFilter;

    searchFilter.pageNum = tableState.page;
    searchFilter.maxRows = tableState.rowsPerPage;
    searchFilter.sortOrder = tableState.sortOrder.name;
    searchFilter.sortDirection = tableState.sortOrder.direction;
    searchFilter.isSearch=false;
  
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_JOBS_LIST_PAGE_DATA_REQUEST, searchFilter);
 
    
  }


  public render() {
    console.log('AdministrationJobsTable render');
    var state = this.props.state;
    var vm = state.vm;

  /*   if (LIB.Common.isNullOrEmpty(state) || !state.hasJobDataLoaded)
      return <LIB.Loading />;
 */
    var filter = state.jobSearchFilter;
    var rows = state.jobs;

    //@ts-ignore
    const oldRender = TableCell.render

    //@ts-ignore
    TableCell.render = function (...args) {
      const [ props, ...otherArgs ] = args
      if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [ propsWithoutEmpty, ...otherArgs ])
      } else {
        return oldRender.apply(this, args);
      }
    } 


    const options: MUIDataTableOptions  = {
      filterType: 'dropdown',
      download: false,
      print: false,
      //filter:false,
      selectableRows: 'single',
      onRowClick: this.onRowClick.bind(this),

      selectableRowsHideCheckboxes: true,
      selectableRowsOnClick: true,
      // rowsSelected:[vm.selectedRowIndex],
      selectToolbarPlacement: 'none',
      onRowSelectionChange: this.onRowSelectionChange.bind(this),
      search: false,
      rowsPerPage: 10,
     // rowsPerPageOptions: [10, 50, 100, 200],      
      rowsPerPageOptions: [],   
      jumpToPage: true,
       
      serverSide:true,
      count:state.jobsCount,
      
      onChangeRowsPerPage: (numberOfRows) => {
        console.log('onChangeRowsPerPage: ', numberOfRows);
        
      },
      onTableChange: (action, tableState) => {
        console.log('onTableChange action: ', action);
        console.log('onTableChange tableState: ', tableState);
 
        if(action=='changePage' || action=='sort'){
          this.onPagingChange(tableState)
        }
        

      }
      //,viewColumns:false

    };


 
    var maxWidth=LIB.MUITableHelper.getTableMaxWidthByWindowSize();
 
    return <div style={{maxWidth:maxWidth}}>
      <MUIDataTable
        title={""}
        data={rows}
        /*  data={rows.map(item => {
          return [
              item.projectId,
              item.type,
          ]
      })}  */
        
       
        
        columns={this.getTableColumns()}
        options={options}
       
      />



    </div>
  }
};

