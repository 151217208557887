import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';

// for AdminUsersController

export class AdminUsersService extends App.BaseAppService  {
 

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
    } 

        public getUsersListData(filter:Api.AdminUserFilterVm|null=null, onAfterStateSet:Function|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
       const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminUsers/getUsers?${urlPrams}`;
        console.log("testing : getUsersListData");
        
        this.get(apiUrl,
       App.ActionTypes.ADMINISTRATION_USERS_LIST_DATA_RECEIVED,
           App.ActionTypes.ADMINISTRATION_API_ERROR 

            );

    }
     
    public createUser(data:Api.AppUser) {
        console.log("AdminUsersService.createUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminUsers/createUser`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_USERS_ADD_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }
    public updateUser(data:Api.AppUser) {
        console.log("AdminUsersService.updateUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminUsers/updateUser`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_USERS_UPDATE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    public deleteUser(userId:number) {
        console.log("AdminUsersService.deleteUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminUsers/deleteUser?userId=${userId}`;
        this.post(apiUrl, userId, 
            App.ActionTypes.ADMINISTRATION_USERS_DELETE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    public changePassword(data:Api.AccountPasswordChangeSm) {
        console.log("AdminUsersService.changePassword");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminUsers/changePassword`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_USERS_PASSWORD_CHANGE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
    
    }

    public changeUserRoles(data:Api.AdminChangeUserRolesSm) {
        console.log("AdminUsersService.changeUserRoles");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminUsers/changeUserRoles`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_USERS_ROLE_CHANGE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
    
    }
    
 
}
