import * as LIB from '_LIB';
import moment from "moment";


export class DateHelper {


    public static getDateOrUndefined(dateValue: any):Date | undefined {
        if (LIB.Common.isNullOrEmpty(dateValue))
            return undefined;

        return dateValue;
    }

    public static getDateOnly(dateValue:any):string {
        if (LIB.Common.isNullOrEmpty(dateValue))
            return '';

        var str = dateValue.toString();
        var tIndex = str.indexOf("T");
        if(tIndex<0)
            return str;


        var dt = str.substring(0,tIndex);
        //console.log('dt:'+dt);

        return dt;
    }

    public static getFormattedDate(date:any=null, format: string, orCurrentDate:boolean=true): string {
        //console.log('...getFormattedDate...');
        if (LIB.Common.isNullOrEmpty(date) && orCurrentDate)
              date = new Date();
        else  if (LIB.Common.isNullOrEmpty(date))
             return '';      

        //console.log('...getFormattedDate date:');
        //console.log(date);

        var mDate = moment(date).format(format);
        var dateStr = mDate.toString();

        //console.log('...getFormattedDate dateStr:' + dateStr);

        return dateStr;
    }

    public static getFormattedDateWithAMPM(date:any=null): string {

        var format = 'MM/DD/YYYY hh:mm A';
        return DateHelper.getFormattedDate(date, format, false);
    }


    public static getFormattedShortDate(date:any=null): string {

        var format = 'MM/DD/YYYY';
        return DateHelper.getFormattedDate(date, format, false);
    }
 




}