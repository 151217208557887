import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Snackbar
} from '@mui/material';
import { Table, Card, CardContent, Paper, Container, Box, Backdrop, CircularProgress, InputAdornment, FormControl, FormHelperText } from '@mui/material';

import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface AdministrationKeywordsCreateModalProps {
  state: App.AdministrationState;
  onChange?: Function;
}


export class AdministrationKeywordsCreateModal extends React.Component<AdministrationKeywordsCreateModalProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationKeywordsCreateModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationKeywordsCreateModal componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onCloseClick() {
    console.log("onCloseClick");
    var state = this.props.state;
    var vm = state.vm;
    var sm = state.adminCreateKeywordSm;

    //reset to defaults

    sm.keyword = '';
    vm.isKeywordsAddOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

    if (this.props.onChange)
      this.props.onChange();

  }


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = state.adminCreateKeywordSm;

    var target: any = e.target;

    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    console.log('onInputChange key', key);
    //console.log('onInputChange item', item);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateKeyword(key, item);

    this.forceUpdate();
  }

  public onRegisterClick(e: React.ChangeEvent<{}>) {
    console.log('onRegisterClick');

    var state = this.props.state;
    var vm = state.vm;
    var sm = state.adminCreateKeywordSm;

    var isValid = this.validationManager.validateKeyword(null, sm);

    if (!isValid) {
      console.log('validateRegister NOT valid, ', App.AppBase.lastErrorMessage);
      this.forceUpdate();
      return;
    }

    //call api
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_KEYWORDS_ADD_REQUEST, sm);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }


  public onCheckClick(e: React.ChangeEvent<{}>) {
    console.log('onCheckClick');

    var state = this.props.state;
    var vm = state.vm;
    var sm = { ...state.adminCreateKeywordSm };

     //call api
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_KEYWORDS_ADD_REQUEST, sm);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }



  public getForm() {
    console.log('AdministrationKeywordsCreateModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var sm = state.adminCreateKeywordSm;

    console.log(sm);



    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    /* var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    }; */

    var formControl: CSSProperties = {
      margin: theme.spacing(2),
      //minWidth:'25rem',
      height: '70px',//set a fixed height so error helpertext does not trigger auto resize
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    /* var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    }; */

    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasKeywordDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
      //  errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      {

        <form >
          {/*   add div https://stackoverflow.com/questions/56156589/simple-material-ui-dialog-example-has-unwanted-scrollbar
 */}<div style={{ padding: 15 }}>
        
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="keyword"
                  label="Keyword"
                  value={sm.keyword || ''}
                  {...App.AppBase.getInputErrorProps("keyword")}
                  onChange={(e: any) => this.onInputChange(e)}
                  ///style={fullWidth}
                  fullWidth
                  style={formControl}
                  //variant="outlined"
                  inputProps={{ style: formInput }}
                />
              </Grid>

            
           
            </Grid >
          </div>
        </form>

      }

    </App.MainPage>;


  }

 
  public render() {
    console.log('AdministrationKeywordsCreateModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = vm.isKeywordsAddOpen;
    var sm = state.adminCreateKeywordSm;

    if (!isOpen)
      return;

    var title = "Create Keyword";

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >{title}</DialogTitle>
          <DialogContent style={{ height: "60vh", minHeight: 300, minWidth: 800 }} >

            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <Button
              variant="contained"
              //color="default"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</Button>

            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={this.onCheckClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Check</Button>


            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={this.onRegisterClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Add</Button>



          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 