import * as React from 'react';
import { createMuiTheme } from '@mui/material/styles';
import { Navigate, Outlet, Route, Router, useNavigate } from 'react-router';
import { BrowserRouter, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchRoutes, useLocation } from "react-router-dom"

import { Button, Backdrop, CircularProgress } from '@mui/material';
import { createStyles,  Theme } from '@mui/material/styles';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as App from 'AppReferences';
import ReactGA from "react-ga4";


//Pages
import Login from './Pages/User/Login/Login';
import ForgotPassword from './Pages/User/ForgotPassword/ForgotPassword';
import ForgotPasswordSuccess from './Pages/User/ForgotPassword/ForgotPasswordSuccess';
import Register from './Pages/User/Register/Register';
import RegisterInviteRequest from './Pages/User/Register/RegisterInviteRequest';
 
import Test from './Pages/Test/Test';
import PasswordReset from './Pages/User/PasswordReset/PasswordReset';
import PasswordResetSuccess from './Pages/User/PasswordReset/PasswordResetSuccess';
import ConfirmEmail from './Pages/Account/ConfirmEmail/ConfirmEmail';
import ConfirmEmailToken from './Pages/Account/ConfirmEmail/ConfirmEmailToken';
import NotFound from './Pages/Static/NotFound';
import AccessDenied from './Pages/Static/AccessDenied';
import RedirectPage from './Pages/Static/RedirectPage';


import JobList from './Pages/JobList/JobList';
import MyAccount from './Pages/MyAccount/MyAccount';
import MyAccountJobList from './Pages/MyAccount/MyAccountJobList/MyAccountJobList';




import Account from './Pages/Account/Account';


import './App.css'

import { useParams } from "react-router-dom";

import { Component } from "react";
import Administration from 'Pages/Admin/Administration';
import EmailUnsubscribe from 'Pages/User/Email/EmailUnsubscribe';
import useIsMobile from '_LIB/Components/Hooks/UseIsMobile';
 

//class MainApp extends React.Component<App.GlobalState, {}> {
const MainApp: React.FC<App.GlobalState> = (props) => {

    var globalDataService: App.GlobalDataService = new App.GlobalDataService();
    var location = useLocation();
    var navigator = useNavigate();
    var params = useParams();

    App.AppBase.navigator = navigator;
    App.AppBase.location = location;
    App.AppBase.params = params;

    //var routerState: App.RouterState;
    /* 
        constructor(props: any) {
    
            super(props);
            this.globalDataService = new App.GlobalDataService();
    
    
        } */


    /*   //Reset errors on route change 
// This method is called when the component is first added to the document
public componentDidMount() {
   console.log('App componentDidMount');
 

    App.AppBase.history.listen((location, action) => {
        var appState = App.AppBase.getState();
        let router = appState.router;
 
        if(router?.location?.pathname===location.pathname)
            return;
 
        console.log(`Route Change URL: ${location.pathname}${location.search}${location.hash}`);
 
        App.AppStore.dispatch(App.ActionTypes.ROUTE_CHANGE, { location, action });
 
        //console.log(`Route Change Action: ${action}`);
        var result = App.AppBase.resetErrorMessages();
        console.log(`Route Change resetErrorMessages`, result);
        if (result) { //if had errors, refresh to clear screen
            App.AppBase.setApiError('');
            this.forceUpdate();
        }
    });
 


}
*/


    /*   // This method is called when the route parameters change
      public componentDidUpdate() {
  
          var currentPage = App.RouteManager.getCurrentUrlPage();
  
          console.log('componentDidUpdate isUserAuthenticated', App.AppBase.isUserAuthenticated);
          console.log('componentDidUpdate currentPage:', currentPage);
  
          console.log('App componentDidMount params', this.props.params);
  
  
          /*    if (!App.AppBase.isUserAuthenticated && currentPage!="Login") {
                 App.RouteManager.routeToPath("Login");
                 return;
             } 
  
      } */

      React.useEffect(() => {
        console.log("location change",location) 

        var change =   {
            location:location,
            navigator:navigator,
            params: params
        };
         
        App.AppStore.dispatch(App.ActionTypes.ROUTE_CHANGE, change);


       }, [location])

      React.useEffect(() => {
        console.log("MainApp useEffect update");
   
        setInitialData(props);
      }, []);//Run only Once


      const checkMobile = useIsMobile();
      React.useEffect(() => {
         
        if(App.AppBase.isMobile !==checkMobile.isMobile){
 
            App.AppStore.dispatch(App.ActionTypes.GLOBAL_WINDOW_SIZE_CHANGED, checkMobile);
            console.log('isMobile: ', App.AppBase.isMobile);
        }

      }, [checkMobile]);


    function setInitialData(props: App.GlobalState) {
        console.log('setInitialData - isInitialDataCalled',App.AppBase.isInitialDataCalled);

        var state = props;

        if (!App.AppBase.isInitialDataCalled && App.AppBase.isUserAuthenticated) {

            console.log('setInitialData call service');
            App.AppBase.isInitialDataCalled = true;
            globalDataService.getInitialData(() => {
                console.log('setInitialData success...');

            });

        }

    }



    function getShowProgressPage(props: App.GlobalState) {

        var state = props;

        console.log('render getShowProgressPage');

        const classes: any = createStyles({
            backdrop: {
                zIndex: App.AppBase.theme.zIndex.drawer + 1,
                color: '#fff',
            }
        });

        return <App.MainLayout 
       
        errorMessage={state.initialData.apiErrorMessage || props.apiErrorMessage || props.globalUIState.apiErrorMessage}
        >

            <Backdrop style={classes.backdrop} open={true}  >
                <CircularProgress color="inherit" />
            </Backdrop>

        </App.MainLayout>;

    }

    function getApp(props: App.GlobalState) {

        console.log('getApp');

        var state = props;
        var currentPage = App.RouteManager.getCurrentUrlPage().toLowerCase();

        App.AppBase.resetErrorMessages();
        props.globalUIState.apiErrorMessage = "";


     //   console.log('getApp isUserAuthenticated', App.AppBase.isUserAuthenticated);
    //    console.log(`getApp currentPage |${currentPage}|`);
     //   console.log('getApp location', App.AppBase.history.location);
      

        var noLeftMenuPages: string[] = [ 'Account', 'Test', 'Jobs', 'MyAccount' ];

        if (App.AppBase.isUserAuthenticatedAndEmailConfirmed) {
            noLeftMenuPages.push(''); //push empty/default page only if valid user. 
        }

        
        var publicRoutes: any = [
            <Route key='Login' path='/Login' element={<Login />} />,
            <Route key='Register' path='/Register' element={<Register />} />,
            <Route key='RegisterInviteRequest' path='/RegisterInviteRequest' element={<RegisterInviteRequest />} />,
            <Route key='Test' path='/Test' element={<Test />} />,
            <Route key='AccessDenied' path="/AccessDenied" element={<AccessDenied />} />,
            <Route key='NotFound' path="/NotFound" element={<NotFound />} />,
            <Route key='PasswordReset' path='/User/PasswordReset' element={<PasswordReset />} />,
            <Route key='PasswordResetSuccess' path='/User/PasswordResetSuccess' element={<PasswordResetSuccess />} />,
            <Route key='ForgotPassword' path='/User/ForgotPassword' element={<ForgotPassword />} />,
            <Route key='ForgotPasswordSuccess' path='/User/ForgotPasswordSuccess' element={<ForgotPasswordSuccess />} />,
            <Route key='ConfirmEmailToken' path='/User/ConfirmEmailToken' element={<ConfirmEmailToken />} />,
            <Route key='ConfirmEmail' path='/Account/ConfirmEmail' element={<ConfirmEmail />} />,
            <Route key='EmailUnsubscribe' path='/EmailUnsubscribe' element={<EmailUnsubscribe />} />,

            <Route key='Jobs' path='/Jobs' element={<JobList />} />
        ];

        var publicRouteIndex = publicRoutes.findIndex((x: any) => {
            let path = x.props.path.toLowerCase();
          //   console.log('publicRoute path: ',path);
           //  console.log('publicRoute currentPage: ',currentPage);
             var result =  currentPage.startsWith(path);
           //  console.log('publicRoute result: ',result);
             return result;
        });

        var isPublicRoute = (publicRouteIndex> -1)

        console.log('isPublicRoute', isPublicRoute);
     //  console.log('publicRoute currentPage', currentPage);
      // console.log('publicRoute path', path);

      
        //*****************************PUBLIC ROUTES ******************************
        if (!App.AppBase.isUserAuthenticated) {
  
            return <App.MainLayout  >
                <Routes   >

                    <Route key='Login2' path='/' element={<Login />} />
                    {publicRoutes}
                    <Route  key='NotFound2'  path="*" element={<NotFound />} />

                </Routes>
            </App.MainLayout>;
        }

        console.log('App show private routes');


        //*****************************PRIVATE ROUTES ******************************
        return <App.MainLayout  >

            <Routes >
                {publicRoutes}


                {/*  ***************** Private Routes  ***************** */}
                <Route element={routeAuthorize()} >

                    <Route path='/' element={<Administration />} />

                    <Route path='/Account' element={<Account />} />
                    <Route path='/Account/:tab' element={<Account />} />

   
                    <Route key='Administration' path='/Administration' element={<Administration />} />
                    <Route key='Administration2' path='/Administration/:tab' element={<Administration />} />

                    <Route key='MyAccount' path='/MyAccount/Jobs' element={<MyAccountJobList />} />

  
                    
                </Route>


                {/*   <Route path="*" element={<RedirectPage />} />
              */}
                <Route key='NotFound3' path="*" element={<NotFound />} />

            </Routes>
        </App.MainLayout>;

    }

    const routeAuthorize = (role: App.UserRoleTypeEnum | null = null) => {

        console.log('routeAuthorize role:', role);

       
        // var page = App.RouteManager.getCurrentUrlPage().toLocaleLowerCase();
        var page = location.pathname.toLocaleLowerCase();
        var currentUser = App.AppBase.currentUser;
        var result: any = <Outlet />;
        var locationState:any = location.state;
        var isRedirect = locationState?.redirect;

        console.log('routeAuthorize page:', page);

      //  console.log('test22 routeAuthorize isUserAuthenticated:', App.AppBase.isUserAuthenticated);
      //  console.log('test22 routeAuthorize currentUser:', currentUser);


        //is Public route
        if (page.startsWith("/user") || page.startsWith("/login") || page.startsWith("/register") || page.startsWith("/email"))
            result = <Outlet />;
        else if (!App.AppBase.isUserAuthenticated) {
            result = <Navigate to="/Login" state={{redirect:true}} />;
        }
        else if (!currentUser?.isEmailConfirmed && !isRedirect) {
            result = <Navigate to="/Account/ConfirmEmail" state={{redirect:true}} />;
        }
        else if (!currentUser?.isProfileDetailsComplete && !isRedirect) {
            result = <Navigate to="/Account/Profile" state={{redirect:true}} />;
        }
        else if (!currentUser?.company?.isCompanyDetailsComplete && !isRedirect && page !== '/account/profile') {
            result = <Navigate to="/Account/Company" state={{redirect:true}} />;
        }
 
       // console.log('auth: routeAuthorize result:', result);

        return result;

    }
 
    var state = props;
    var authTokenkey = App.AppBase.authTokenKey;
    var appState = App.AppBase.getState();

    var currentUser = App.AppBase.currentUser;
    //console.log('App render appWebsiteUrl:', App.AppBase.appInfo.appWebsiteUrl);

    ReactGA.initialize('G-1HR8B81CBM');
    

    if (!state.isAppInfoDataLoaded) {

        if (!App.AppBase.isAppInfoDataCalled) {
            console.log('getAppInfoData call service');
            App.AppBase.isAppInfoDataCalled = true;
            globalDataService.getAppInfoData();
        }

        return getShowProgressPage(state);
    }

    return getApp(state);
}
export default connect(
    (state: App.AppState) => ({ ...state.globalData })
)(MainApp as any);
