import * as React from 'react';
import {CircularProgress, Backdrop} from '@mui/material';
import * as LIB from '_LIB';
import Alert from '@mui/material/Alert';


export class ValidationHelper {

 
 
     public static isValidEmail(email: any): boolean {

        if (email == null)
            return false;

        var regex = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/g;

        var result = regex.test(email);

        //console.log('isValidEmail result:',result);

        return result;
    }

    public static hasNumber(name: any):boolean {

        var regex = /[0-9]/g;

        var result = regex.test(name);

        return result;
    }

    
    public static hasLettersAndSpaceOnly(name: any):boolean {

        var regex = /^[a-zA-Z\s]*$/;  

        var result = regex.test(name);

        return result;
    }

    public static hasSymbolsExceptSpace(name: any):boolean {

        if (name == null)
            return false;

        var regex = /^[\w\s]+$/g;

        var result = regex.test(name);

        return result;
    }

    public static hasSpaces(name: any):boolean {

        var regex = /\s/g;

        var result = regex.test(name);

        return result;
    }

    
    public static hasUpperCaseLetter(string: any):boolean {

        if (string == null)
            return false;

        var regex = /[A-Z]/g;

        var result = regex.test(string);

        return result;
    }

    public static hasLowerCaseLetter(string: any):boolean {

        if (string == null)
            return false;

        var regex = /[a-z]/g;

        var result = regex.test(string);

        return result;
    }

    
    public static isValidPhoneNumber(phone: string) {


        if(phone?.length<10 || phone?.indexOf('/')>-1)
           return false;

        var regex = /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/;

        var result = regex.test(phone);

        return result;
    }

}