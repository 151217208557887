import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
///import { NavLink } from 'reactstrap';
import { Drawer, Toolbar, List, Divider, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material/';
import { BrowserRouter, Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';
import { CSSProperties } from "react";

import * as LIB from '_LIB';
//import { RouterState } from 'connected-react-router';

import './LeftMenu.css';
import Administration from 'Pages/Admin/Administration';
import AdministrationTreeMenu from './AdministrationTreeMenu';
import { AccountTreeMenu } from './AccountTreeMenu';
 



//import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const drawerWidth = 240;
/* 
interface LeftMenuProps {
 
    selectedItem: string;

}
 */
//class LeftMenu extends React.Component<App.GlobalState, {}> {
export const LeftMenu: React.FC<App.GlobalState> = (props) => {


    var selectedTab: string | undefined;
    var params = useParams();
    var location = useLocation();
    var locationState = location.state;


  /*   React.useEffect(() => {
        console.log("LeftMenu useEffect update");

    }); */

    var vm = props.globalUIState;
    console.log('LeftMenu globalUIState:',vm);

    var appPath = "";//App.AppBase.appInfo.appPath;

    // console.log('router: ');
    // console.log(this.props.location);

    // var selectedItem = App.AppBase.history?.location?.pathname;
    var selectedItem = App.AppBase.location?.pathname;
    var currentUser = App.AppBase.currentUser;

    //var selectedItem = this.props.location?.pathname;
    var myAccountMenuItem: any = null;
    var administrationMenuItem: any = null;

    /*     if (!currentUser?.isAuthenticated)
                return null;
      */

    if (currentUser?.isAdmin ||
        currentUser?.hasRole(App.UserRoleTypeEnum.BenchRecruiter) ||
        currentUser?.hasRole(App.UserRoleTypeEnum.Vendor)) {

            myAccountMenuItem = <ListItem
            key="MyAccount"
            component={RouterLink}
            to="/MyAccount"
            //onClick={()=>this.onLinkClick("/Test")}
            button
                selected={(selectedItem === "/MyAccount")}
        >
            <ListItemIcon>
                <Icons.Home />
            </ListItemIcon>
            <ListItemText primary="My Account" />
        </ListItem>

            myAccountMenuItem  = <AccountTreeMenu params={params} state={props} location={location}  />


    }

    if (currentUser?.isAdmin ||
        currentUser?.hasRole(App.UserRoleTypeEnum.Admin)) {

        administrationMenuItem =  <AdministrationTreeMenu />;

    }

    var ListItemStyle: CSSProperties = {
        paddingTop:4,
        paddingBottom:4, 
      };

      var variant:any = 'permanent';

      if(vm.isMobile)
      variant=undefined;

      console.log('LeftMenu render:',vm);

    return <div style={{ width: 280 }} >

        <Drawer
            className="LeftMenuDrawer"
             variant={variant}
            classes={{
                paper: "LeftMenuDrawerPaper",
            }}
            anchor="left"
            open={vm.isMobileMenuOpen}
        >
            <Toolbar />
            <div className="drawerContainer" />
            <Divider />

            <List component="nav" aria-label="main mailbox folders"
                className="LeftMenuList"
                onChange={(e: any) => console.log('Menu Change')}
                style={{marginTop:20}}
            >
 
                
                <ListItem
                    key="Jobs"
                    component={RouterLink}
                    to="/Jobs"
                    button
                    selected={(selectedItem === "/Jobs")}
                    
                >
                    <ListItemIcon style={{marginLeft:11 }}>
                        <Icons.Work />
                    </ListItemIcon>
                    <ListItemText primary="Hot Jobs" />
                </ListItem>

                {myAccountMenuItem}


            </List>


            <Divider />

             {administrationMenuItem}

        </Drawer>
    </div>;
}
//}
export default connect(
    (state: App.AppState) => ({ ...state.globalData })
)(LeftMenu as any);


