import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Menu, Button, Grid, Divider } from '@mui/material';
import * as App from 'AppReferences';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as LIB from '_LIB';


import { createStyles,  Theme } from '@mui/material/styles';

//import './AccountMenuItem.css'


interface AdminMenuItemProps {
  currentUser: App.AppUser;
  globalState: App.GlobalUIStateVm;
}

export class AdminMenuItem extends React.Component<AdminMenuItemProps, {}> {

  public adminDataService: App.AdminDataService;

  constructor(props: any) {
    super(props);
    this.adminDataService = new App.AdminDataService();

    // this.state = new AccountMenuItemState();

  }


  public onImpersonateClick(e: React.ChangeEvent<{}>) {


    var vm = this.props.globalState;
    vm.isAdminImpersonateModalOpen = true;
    //this.forceUpdate();

    App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);
    // App.AppBase.history.push("AddRequest");

  }


  public render() {

    var currentUser = this.props.currentUser;

    if(!App.AppBase.isUserAuthenticated)
      return null;

      //console.log('Admin currentUser', currentUser);
      //console.log('Admin userRoleIds', currentUser.userRoleIds);

    if (!currentUser?.isAdmin && !currentUser?.realUserIsAdmin)
      return null;

    return (
      <PopupState variant="popper" popupId="demo-popup-menu">
        {(popupState) => (
          <div  >

            <UI.IconButton
              aria-controls="menu-appbar"
             // aria-haspopup="true"
              title="Admin Menu"
              color="inherit"

              {...bindTrigger(popupState)}
            >

              <Icons.FontDownload />


            </UI.IconButton>



            <Menu  {...bindMenu(popupState)}
              id="createProjectBtnMenu"
              onClick={popupState.close}
             // getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}

            >
             {/*   {!currentUser.isImpersonating && ( */}
              <UI.MenuItem onClick={this.onImpersonateClick.bind(this)} >Impersonate</UI.MenuItem>
           {/*    )} */}

              {currentUser.isImpersonating && (
              <UI.MenuItem onClick={(e:any)=>{this.adminDataService.impersonationReset()}} >Stop Impersonation</UI.MenuItem>
              )}
            {/*   <UI.MenuItem   >Manage Users</UI.MenuItem>  */}
            </Menu>
          </div>
        )}
      </PopupState>
    );


  }
}

