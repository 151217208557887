import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Menu, Button, Grid, Divider } from '@mui/material';
import * as App from 'AppReferences';
//import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as LIB from '_LIB';
import { Tabs, Tab } from "@mui/material";


import { createStyles,  Theme } from '@mui/material/styles';
import { useParams, useLocation } from 'react-router';

//import './AccountMenuItem.css'


interface SmartTabsProps {
  tabList: string[];
  selectedTab?: string;
  forceUpdate:Function;
  startUrl:string;
  onSelectedChange:Function;
  children?: React.ReactNode;

}

//export class SmartTabs extends React.Component<SmartTabsProps, {}> {
export const SmartTabs: React.FC<SmartTabsProps> = (props) => {


  var selectedTab:string|undefined;
  var params = useParams();
  var location = useLocation();
  var locationState = location.state;
  const [forceUpdate] = React.useReducer((x) => x + 1, 0);

 /*  constructor(props: any) {
    super(props);

    // state = new AccountMenuItemState();
   // props.selectedTab = props.tabList[0].replace(" ", "");
  }
 */
 /*   // This method is called when the component is first added to the document
   public componentDidMount() {
    console.log("SmartTabs componentDidMount");
    checkSetSelected();
  }

    // This method is called when the route parameters change
    public componentDidUpdate() {
      console.log("SmartTabs componentDidUpdate");
      checkSetSelected();
    }
 */
    React.useEffect(() => {
      console.log("SmartTabs useEffect update");
 
      checkSetSelected();
    });

 
  function checkSetSelected() {
    console.log("SmartTabs checkSetSelected");
    var selectedTab = props.selectedTab;
    var tabList = props.tabList;
   
    var queryTab = params.tab;

     
    //var queryTab = App.RouteManager.getRouterQueryParam("tab");
    console.log("SmartTabs checkSetSelected URL Tab:", queryTab);
    console.log("SmartTabs checkSetSelected selectedTab:", selectedTab);

    if (!LIB.Common.isNullOrEmpty(queryTab) && queryTab !== selectedTab) {
      selectedTab = queryTab;
      props.onSelectedChange(selectedTab);
      //update();
    }
    else if (LIB.Common.isNullOrEmpty(selectedTab) && tabList.length>0) {
      
      selectedTab = tabList[0]?.replace(" ", "").replace(' ', '').trim();

      console.log(`SmartTabs NO URL tab, set to 1st: |${selectedTab}|`);

      props.onSelectedChange(selectedTab);
      //update();
    }


    //console.log('SmartTabs componentDidUpdate selectedTab 2', selectedTab);
    //console.log('SmartTabs componentDidUpdate queryTab', queryTab);
  }

  

  function onTabChange(e: React.ChangeEvent<{}>, newValue: number) {
    console.log("SmartTabs onTabChange");

    var id: string = (e.currentTarget as any).id;
    if (!LIB.Common.isNullOrEmpty(id))
       id = id.replace("Tab", "");
  
    var selectedTab2 = id;

    console.log('SmartTabs selectedTab2', selectedTab2);

  
    App.RouteManager.routeToPath(`${props.startUrl}${selectedTab2}`);

    //App.AppBase.history.push(`${props.startUrl}${selectedTab2}`);

    props.onSelectedChange(selectedTab2);

     
    /*   console.log('onTabChange new tab', id);
    console.log('onTabChange selectedTab', selectedTab);
    console.log('onTabChange selectedTabIndex', selectedTabIndex);
 */
    update();
  }

  function update(){
    console.log('SmartTabs update, tabs changed');
    //forceUpdate();
    props.forceUpdate();
  }

  
  function getTab(name: string) {
    var id = name.replace(" ", "");
    //console.log('getTab id:', id);

    return (
      <Tab
        id={id + "Tab"}
        label={<div style={{ width: "100%", textAlign: "left" }}>{name}</div>}
        style={{ color: "#0c71d6" }}
        key={id}
      />
    );

    return;
  }

 
    console.log("getTabs");

    var isMobile = App.AppBase.getIsMobile();

   

    var theme = App.AppBase.theme;
    const classes: any = {
      root: {
        //flexGrow: 1,
        backgroundColor: "white",
        display: "flex",
        // maxWidth:200
        // height: 224,
      },
      tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        height: "100%",
       // maxWidth:200,
        minHeight: "700px",
        overflow: 'visible',
      },
    };

    var state = props;
    var tabList = state.tabList;
     selectedTab = props.selectedTab;

    //  var selected = selectedTabIndex;
    var theme = App.AppBase.theme;
     


    var selectedIndex = tabList.findIndex((c) => c.replace(' ','') === selectedTab);
    if (selectedIndex < 0) {
      selectedIndex = 0;
    }
    
    //console.log("getTabs selectedIndex", selectedIndex);
   // console.log("getTabs selectedTab", selectedTab);
    //console.log("getTabs selectedTab", selectedTab);

    var orientation:any="vertical";
    if(isMobile){
      orientation="horizontal";
      classes.root.display = 'block';
      classes.tabs.minHeight = undefined;
    }
        

    return (
      <div style={classes.root}>
        <Tabs
          
          orientation={orientation}
          value={selectedIndex}
          onChange={onTabChange.bind(this)}
          style={classes.tabs}
        >
          
            {tabList.map((item) => getTab(item))}

        </Tabs>

        {props.children}
        
 
      </div>
    );
 
 
}

