import * as React from 'react';
import { Router} from 'react-router-dom';
import { History, } from 'history';

interface CustomRouterProps {
  history: History<any>;
  children?: React.ReactNode;
  basename?:any;
}
export class CustomRouter extends React.Component<CustomRouterProps, {}> {

  constructor(props: any) {
    super(props);

  }
  
  // This method is called when the component is first added to the document
  public componentDidMount() {

    var history = this.props.history;

    history.listen((location, action) => {
       console.log(`CustomRouter history Change:`, location, action);
     // console.log(`CustomRouter history:`, history);
      this.forceUpdate();
  });

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('componentDidUpdate:'+this.state.visible);
     
    
  } 
  public render() {

    var history = this.props.history;
    var action:any =  history.action;
    var location = history.location;
   // console.log('CustomRouter render');

   return <Router
      basename={this.props.basename}
      children={this.props.children}
      location={location}
      navigationType={action}
      navigator={history}
    />

  }
} 
