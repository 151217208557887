import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


interface ForgotPasswordProps {
  //vm: App.ProjectListState;
}

//export class ForgotPassword extends React.PureComponent<ForgotPasswordProps> {
class ForgotPassword extends React.Component<App.GuestUserState, {}> {

  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    //console.log('ForgotPassword componentDidMount');

    if (App.AppBase.isUserAuthenticated) {
      App.AuthManager.logout();
      this.forceUpdate();
    }


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    //console.log('ForgotPassword componentDidUpdate');
  }



  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    var state = this.props;
    var vm = state.vm;
    var sm = state.forgotPasswordSm;


    var target: any = e.target;

    var key = LIB.FormHelper.setInputChangeData(e.target, sm);

    console.log('onInputChange key', key);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateForgotPassword(key, sm);

    this.forceUpdate();
  }


  public onSubmitClick(e: React.ChangeEvent<{}>) {
    console.log('onSubmitClick');

    var state = this.props;
    var vm = state.vm;
    var sm = state.forgotPasswordSm;

    var isValid = this.validationManager.validateForgotPassword(null, sm);

    if (!isValid) {
      console.log('validateForgotPassword NOT valid');
      this.forceUpdate();
      return;
    }

    //call api
    App.AppStore.dispatch(App.ActionTypes.GUESTUSER_FORGOT_PASSWORD_REQUEST, sm);

    //this.forceUpdate();

  }


  public render() {

    console.log("ForgotPassword Render", this.props);
    
    var state = this.props;
    var vm = state.vm;
    var sm = state.forgotPasswordSm;

    const classes: any = createStyles({
      backdrop: {
        zIndex: App.AppBase.theme.zIndex.drawer + 1,
        color: '#fff',
      }
    });

    var formInput: CSSProperties = {
      height: 50
    };

    var formControl: CSSProperties = {
      marginTop: 10,
      width: '100%'
    };

    /*   if (App.AppBase.isUserAuthenticationInProgress  ) {
       
       return <Backdrop style={classes.backdrop} open={true}  >
         <CircularProgress color="inherit" />
       </Backdrop>
     }   */


    return <App.MainPage
      state={vm}
      hasDataLoaded={true}
      loading={state.isServiceCallPending}
      //loading={true}
      showContentWhenInProgress={true}
      showModalForLoading={true}
    >

      <Grid container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ width: '97vw', marginTop: 120 }}

      >

        <Card style={{ padding: 20, width: 400, minHeight: 300 }}>
          <CardContent  >

            <Typography variant="h5" gutterBottom style={{ textAlign: 'center', marginBottom: 25 }}>
              Forgot Password
            </Typography>
            <div style={{ marginTop: 10, marginBottom: 10 }}>

              Enter your email to receive an email with password reset link

            </div>

            <div>
              <TextField
                name="email"
                label="Email"
                value={sm.email}
                {...App.AppBase.getInputErrorProps("email")}
                onChange={(e: any) => this.onInputChange(e)}
                ///style={fullWidth}
                fullWidth
                style={formControl}
                //variant="outlined"
                InputProps={{
                  className: 'standardFormInput30',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icons.PersonOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </div>


            <div className='standardFormInput30'>
              <Button variant="contained" color="primary" style={{ width: '100%' }}
                onClick={(e) => this.onSubmitClick(e)}
              >Send</Button>
            </div>

            <div style={{ marginTop: 30, textAlign: 'center' }}>
              <RouterLink to="/Login" color="primary">Return to Login</RouterLink>
            </div>


          </CardContent>
        </Card>

      </Grid >
    </App.MainPage>
      ;

  }
};
export default connect(
  (state: App.AppState) => ({ ...state.guestUserData })
)(ForgotPassword as any);

