import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';

// for AdminUsersController

export class EmailService extends App.BaseAppService  {
 

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
    } 

        public unsubscribe(sm:Api.UnsubscribeSm|null=null, onAfterStateSet:Function|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(sm);
       const apiUrl = `${App.AppBase.appApiBaseUrl}/Email/unsubscribe?${urlPrams}`;
         
        this.get(apiUrl);

    }
      
    
 
}
