import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import { Alert } from '@mui/material';


interface AccountProfileProps {
  state: App.AccountState;
  children?: React.ReactNode;

}

export class AccountProfile extends React.Component<AccountProfileProps> {

  public validationManager: App.ValidationManager;
  public profileManager: App.ProfileManager;
  public jobService: App.JobService;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();
    this.profileManager = new App.ProfileManager();
  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AccountProfile componentDidMount');
    /* 
        var profile = this.props.state.profile;
        var user = App.AppBase.currentUser;
    
        //  console.log('AccountProfile profile',profile);
        //  console.log('AccountProfile user',user);
    
        if (LIB.Common.isNullOrEmpty(profile.userId)) {
    
          this.resetData();
          this.forceUpdate();
        } */


  }

  public resetData() {
    console.log('AccountProfile resetData');
    var profile = this.props.state.profile;

    profile.setData(this.props.state.profileOriginal);
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    //console.log('AccountProfile componentDidUpdate');


  }

  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    const item = this.props.state.profile;
    var target: any = e.target;

    // item.firstName='bb';
    // console.log('onInputChange value', target.value);
    // console.log('onInputChange item', item);

    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    //console.log('onInputChange key', key);
    //console.log('onInputChange value2', target.value);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateAccountProfileEdit(key, item);

    console.log('onInputChange item', item);

    this.forceUpdate();
  }


  public onDateChange(date: any | null, value?: string | null) {

    console.log(date);
    console.log(value);

  }



  public onAutocompleteChange(name: string, value: any, details: any) {
    console.log('onAutocompleteChange');

    const item = this.props.state.profile;

    var selected = {
      name: name,
      value: value
    }

    console.log('onAutocompleteChange selected', selected);


    var key = LIB.FormHelper.setInputChangeData(selected, item);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateAccountProfileEdit(key, item);


    this.forceUpdate();
  }

  public onCloseClick() {

    this.resetData();
    App.RouteManager.routeToPath('/')

  }


  public onSaveClick(e: React.ChangeEvent<{}>) {
    console.log('onSaveClick');


    var state = this.props.state;
    var vm = state.vm;
    var profile = state.profile;

    var isValid = this.validationManager.validateAccountProfileEdit(null, profile);

    if (!isValid) {
      console.log('validateAddCandidateModal NOT valid');
      this.forceUpdate();
      return;
    }

    var sm = new Api.AccountUpdateProfileSm();
    sm.profile = profile.getAppUserVm();
    App.AppStore.dispatch(App.ActionTypes.ACCOUNT_PROFILE_UPDATE_REQUEST, sm);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }



  public getIncompleteAlert() {
    var state = this.props.state;
    var item = state.profile;
 
    console.log('getIncompleteAlert hasDataLoaded', state.hasDataLoaded);
    console.log('getIncompleteAlert isProfileDetailsComplete', item.isProfileDetailsComplete);


    if(state.hasDataLoaded && !item.isProfileDetailsComplete)
         return <Alert severity="error">Please complete your profile to Continue! </Alert>;

    return null;
  }

  public render() {

    //console.log('ProjectScopeForm render');

    var state = this.props.state;
    var item = state.profile;

    var user = state.profile;//App.AppBase.currentUser;
    //console.log('render user',user);

    //if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || LIB.Common.isNullOrEmpty(item))
    //  return null;//<LIB.Loading />;

    var theme = App.AppBase.theme;

    var formControl: CSSProperties = {
      margin: theme.spacing(2),
      //minWidth:'25rem',
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };
    var fullWidth: CSSProperties = {
      margin: theme.spacing(2),
      paddingRight: 20,
      marginLeft: 0


    };

    var formInput: CSSProperties = {

      height: 50,
      //padding:15
      // padding:'20px, 16px'
    };

    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20 }

    };

    var textFieldStyle = { paddingTop: 20, marginBottom: 10 };


    var formControlHalf = App.StyleHelper.getFormControlHalfWidthStyle();

    var countries = LIB.LocationCountryHelper.getCountries();


    return <div style={{ width: '40vw', marginLeft: 10 }}>

      {this.getIncompleteAlert()}

      <h2 >Profile</h2>

      <form >

        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} >
            <TextField
              name="firstName" label="First Name"
              value={user.firstName || ''}
              {...App.AppBase.getInputErrorProps('firstName')}
              onChange={this.onInputChange.bind(this)}
              style={formControl}
              inputProps={{ style: formInput }}
            />
          </Grid>
          <Grid item xs={12} sm={6}

          >
            <TextField
              name="lastName" label="Last Name"
              value={user.lastName || ''}
              {...App.AppBase.getInputErrorProps('lastName')}
              onChange={this.onInputChange.bind(this)}
              style={formControl}
              inputProps={{ style: formInput }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} >
            <TextField
              name="email" label="Email"
              value={user.email || ''}
              {...App.AppBase.getInputErrorProps('email')}
              style={formControl}
              inputProps={{ style: formInput }}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}
          >
            <TextField
              name="phone" label="Phone"
              value={user.phone || ''}
              {...App.AppBase.getInputErrorProps('phone')}
              onChange={this.onInputChange.bind(this)}
              style={formControl}
              inputProps={{ style: formInput }}

            />
          </Grid>
        </Grid>


      {/*   <App.FullAddress
          address={user.address}
          city={user.city}
          state={user.state}
          zipCode={user.zipCode}
          country={user.country}
          onInputChange={this.onInputChange.bind(this)}
          onAutocompleteChange={this.onAutocompleteChange.bind(this)}
        />
 */}

  

        <div >
          <FormControlLabel
            control={
              <Checkbox
                name="enableDailyEmailHotJobs"
                color="primary"
                {...App.AppBase.getInputErrorProps('enableDailyEmailHotJobs')}
                checked={user.enableDailyEmailHotJobs}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />
            }
            style={{ marginLeft: 0 }}
            labelPlacement="start"
            label="Receive Daily Hot Jobs Emails?"
          />

          <Tooltip arrow placement="right" className='formHelperTooltip'
            title="By enabling this, you will receive daily updated list of Hot Jobs that are seeking partners to fill quickly fill those roles."

          >
            <Icons.Info style={{ marginLeft: 0, marginBottom: -8 }} htmlColor='#ccccc' />
          </Tooltip>


        </div>


        <div style={{ marginTop: 50 }}>
          <Button
            id="btnCancel"
            variant="contained"
            //color="default"
            disabled={!user.isProfileDetailsComplete}
            onClick={() => this.onCloseClick()}
          >Close</Button>

          <Button
            id="btnSave"
            color="primary"
            variant="contained"
            style={{ marginLeft: 15 }}
            onClick={this.onSaveClick.bind(this)}

          >Save</Button>

        </div>

      </form>


      {this.props.children}
    </div>;


  }
};
