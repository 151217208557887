import * as App from 'AppReferences';
import { type } from 'os';
 
export enum ActionTypes {

    ERROR_API_ERROR = "ERROR_API_ERROR", 


    GLOBAL_INITIAL_DATA_RECEIVED = "GLOBAL_INITIAL_DATA_RECEIVED", 
    GLOBAL_LOG_API_RESULT = "GLOBAL_LOG_API_RESULT", 
    GLOBAL_UI_STATE_CHANGED = "GLOBAL_UI_STATE_CHANGED", 
    GLOBAL_UI_SELECT_LEFT_MENU = "GLOBAL_UI_SELECT_LEFT_MENU", 
    GLOBAL_API_ERROR = "GLOBAL_API_ERROR", 
    GLOBAL_APPINFO_DATA_RECEIVED="GLOBAL_APPINFO_DATA_RECEIVED",
    GLOBAL_WINDOW_SIZE_CHANGED = "GLOBAL_WINDOW_SIZE_CHANGED", 
 
    ITEMLIST_NEW_VIDEO = "ITEMLIST_NEW_VIDEO", 
    ITEMLIST_CHANGE_FILTER = "ITEMLIST_CHANGE_FILTER",
    ITEMLIST_CHANGE_COLUMNS = "ITEMLIST_CHANGE_COLUMNS",
    ITEMLIST_PAGE_DATA_RECEIVED  = "ITEMLIST_PAGE_DATA_RECEIVED", 
    ITEMLIST_API_ERROR  = "ITEMLIST_API_ERROR", 
    ITEMLIST_CREATE_VIDEO_SUCCESS  = "ITEMLIST_CREATE_VIDEO_SUCCESS", 
    ITEMLIST_YTDETAIL_DATA_RECEIVED  = "ITEMLIST_YTDETAIL_DATA_RECEIVED", 
    ITEMLIST_UPDATE_VIDEO  = "ITEMLIST_UPDATE_VIDEO", 
    ITEMLIST_CHANNEL_EMAIL_UPDATED  = "ITEMLIST_CHANNEL_EMAIL_UPDATED", 
    ITEMLIST_SETTINGS_CHANGED  = "ITEMLIST_SETTINGS_CHANGED", 
 
    GUESTUSER_API_ERROR = "GUESTUSER_API_ERROR", 
    GUESTUSER_GET_PAGE_DATA  = "GUESTUSER_GET_PAGE_DATA", 
    GUESTUSER_PAGE_DATA_RECEIVED  = "GUESTUSER_PAGE_DATA_RECEIVED", 
    GUESTUSER_LOGIN_REQUEST = "GUESTUSER_LOGIN_REQUEST", 
    GUESTUSER_LOGIN_SUCCESS = "GUESTUSER_LOGIN_SUCCESS", 
    GUESTUSER_REGISTER_SUCCESS  = "GUESTUSER_REGISTER_SUCCESS", 
    GUESTUSER_REGISTER_REQUEST = "GUESTUSER_REGISTER_REQUEST", 
    GUESTUSER_FORGOT_PASSWORD_REQUEST  = "GUESTUSER_FORGOT_PASSWORD_REQUEST", 
    GUESTUSER_FORGOT_PASSWORD_SUCCESS  = "GUESTUSER_FORGOT_PASSWORD_SUCCESS", 
    GUESTUSER_PASSWORD_RESET_REQUEST  = "GUESTUSER_PASSWORD_RESET_REQUEST", 
    GUESTUSER_PASSWORD_RESET_SUCCESS  = "GUESTUSER_PASSWORD_RESET_SUCCESS", 

    GUESTUSER_CONFIRM_EMAIL_SUCCESS  = "GUESTUSER_CONFIRM_EMAIL_SUCCESS", 

    USER_RESEND_CONFIRM_EMAIL_SUCCESS  = "USER_RESEND_CONFIRM_EMAIL_SUCCESS", 

    ADMIN_USER_PAGE_DATA_RECEIVED  = "ADMIN_USER_PAGE_DATA_RECEIVED", 
    ADMIN_USER_API_ERROR  = "ADMIN_USER_API_ERROR", 
    ADMIN_USER_IMPERSONATE_USER_SUCCESS  = "ADMIN_USER_IMPERSONATE_USER_SUCCESS", 
    ADMIN_USER_RESET_SESSION_SUCCESS  = "ADMIN_USER_RESET_SESSION_SUCCESS", 

   /*  USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST", 
    USER_LOGOUT_SUCCESS = "USER_LOGOUT_REQUEST", 
    USER_API_ERROR = "USER_API_ERROR",  */

    ACCOUNT_API_ERROR = "ACCOUNT_API_ERROR", 
    ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_REQUEST  = "ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_REQUEST", 
    ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_SUCCESS  = "ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_SUCCESS", 
    ACCOUNT_CONFIRM_EMAIL_TOKEN_REQUEST  = "ACCOUNT_CONFIRM_EMAIL_TOKEN_REQUEST", 
    ACCOUNT_CONFIRM_EMAIL_TOKEN_SUCCESS  = "ACCOUNT_CONFIRM_EMAIL_TOKEN_SUCCESS", 
    ACCOUNT_CONFIRM_EMAIL_TOKEN_ERROR  = "ACCOUNT_CONFIRM_EMAIL_TOKEN_ERROR", 
    
    ACCOUNT_GET_PAGE_DATA_REQUEST  = "ACCOUNT_GET_PAGE_DATA_REQUEST", 
    ACCOUNT_GET_PAGE_DATA_SUCCESS  = "ACCOUNT_GET_PAGE_DATA_SUCCESS", 
 
    ACCOUNT_LOGOUT_REQUEST   = "ACCOUNT_LOGOUT_REQUEST", 
    ACCOUNT_LOGOUT_SUCCESS  = "ACCOUNT_LOGOUT_SUCCESS",  

    ACCOUNT_PROFILE_UPDATE_REQUEST  = "ACCOUNT_PROFILE_UPDATE_REQUEST", 
    ACCOUNT_PROFILE_UPDATE_SUCCESS  = "ACCOUNT_PROFILE_UPDATE_SUCCESS", 

    ACCOUNT_PASSWORD_CHANGE_REQUEST  = "ACCOUNT_PASSWORD_CHANGE_REQUEST", 
    ACCOUNT_PASSWORD_CHANGE_SUCCESS  = "ACCOUNT_PASSWORD_CHANGE_SUCCESS", 

    ACCOUNT_COMPANY_UPDATE_REQUEST  = "ACCOUNT_COMPANY_UPDATE_REQUEST", 
    ACCOUNT_COMPANY_UPDATE_SUCCESS  = "ACCOUNT_COMPANY_UPDATE_SUCCESS", 
   
    JOB_LIST_PAGE_DATA_REQUEST = "JOB_LIST_PAGE_DATA_REQUEST",
    JOB_LIST_PAGE_DATA_RECEIVED  = "JOB_LIST_PAGE_DATA_RECEIVED", 
    JOB_LIST_API_ERROR  = "JOB_LIST_API_ERROR",
    JOB_LIST_PAGE_RESET = "JOB_LIST_PAGE_RESET",
    JOB_LISTING_CANDIDATE_LIST_DATA_REQUEST = "JOB_LISTING_CANDIDATE_LIST_DATA_REQUEST",
    JOB_LISTING_CANDIDATE_LIST_DATA_RECEIVED = "JOB_LISTING_CANDIDATE_LIST_DATA_RECEIVED",
  
    MY_ACCOUNT_JOBS_LIST_DATA_RECEIVED  = "MY_ACCOUNT_JOBS_LIST_DATA_RECEIVED", 
    MY_ACCOUNT_API_ERROR   = "MY_ACCOUNT_API_ERROR", 
 
    MY_ACCOUNT_JOB_ADD_REQUEST  = "MY_ACCOUNT_JOB_ADD_REQUEST", 
    MY_ACCOUNT_JOB_ADD_SUCCESS  = "MY_ACCOUNT_JOB_ADD_SUCCESS", 
    MY_ACCOUNT_JOB_UPDATE_REQUEST  = "MY_ACCOUNT_JOB_UPDATE_REQUEST", 
    MY_ACCOUNT_JOB_UPDATE_SUCCESS  = "MY_ACCOUNT_JOB_UPDATE_SUCCESS", 
    MY_ACCOUNT_JOB_DELETE_REQUEST  = "MY_ACCOUNT_JOB_DELETE_REQUEST", 
    MY_ACCOUNT_JOB_DELETE_SUCCESS  = "MY_ACCOUNT_JOB_DELETE_SUCCESS", 
 
    
    ADMINISTRATION_USERS_ADD_REQUEST  = "ADMINISTRATION_USERS_ADD_REQUEST", 
    ADMINISTRATION_USERS_ADD_SUCCESS  = "ADMINISTRATION_USERS_ADD_SUCCESS", 
    ADMINISTRATION_USERS_UPDATE_REQUEST  = "ADMINISTRATION_USERS_UPDATE_REQUEST", 
    ADMINISTRATION_USERS_UPDATE_SUCCESS  = "ADMINISTRATION_USERS_UPDATE_SUCCESS", 
    ADMINISTRATION_USERS_DELETE_REQUEST  = "ADMINISTRATION_USERS_DELETE_REQUEST", 
    ADMINISTRATION_USERS_DELETE_SUCCESS  = "ADMINISTRATION_USERS_DELETE_SUCCESS",

    ADMINISTRATION_USERS_LIST_DATA_RECEIVED  = "ADMINISTRATION_USERS_LIST_DATA_RECEIVED", 
    ADMINISTRATION_API_ERROR   = "ADMINISTRATION_API_ERROR", 
    ADMINISTRATION_USERS_LIST_PAGE_RESET = "ADMINISTRATION_USERS_LIST_PAGE_RESET",

    ADMINISTRATION_USERS_LIST_PAGE_DATA_REQUEST = "ADMINISTRATION_USERS_LIST_PAGE_DATA_REQUEST",
    ADMINISTRATION_USERS_LIST_PAGE_DATA_RECEIVED = "ADMINISTRATION_USERS_LIST_PAGE_DATA_RECEIVED",

    ADMINISTRATION_USERS_PASSWORD_CHANGE_REQUEST = "ADMINISTRATION_USERS_PASSWORD_CHANGE_REQUEST",
    ADMINISTRATION_USERS_PASSWORD_CHANGE_SUCCESS = "ADMINISTRATION_USERS_PASSWORD_CHANGE_SUCCESS",

    ADMINISTRATION_USERS_ROLE_CHANGE_REQUEST = "ADMINISTRATION_USERS_ROLE_CHANGE_REQUEST",
    ADMINISTRATION_USERS_ROLE_CHANGE_SUCCESS = "ADMINISTRATION_USERS_ROLE_CHANGE_SUCCESS",


    ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_REQUEST  = "ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_REQUEST", 
    ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_SUCCESS  = "ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_SUCCESS", 

    ADMINISTRATION_DAILY_EMAILS_SEND_HOT_JOBS_REQUEST  = "ADMINISTRATION_DAILY_EMAILS_SEND_HOT_JOBS_REQUEST", 

    ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_RECEIVED ="ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_RECEIVED",
    ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_REQUEST ="ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_REQUEST",
    ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_RESET = "ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_RESET",

    ADMINISTRATION_EMAILTEMPLATES_ADD_REQUEST = "ADMINISTRATION_EMAILTEMPLATES_ADD_REQUEST",
    ADMINISTRATION_EMAILTEMPLATES_ADD_SUCCESS = "ADMINISTRATION_EMAILTEMPLATES_ADD_SUCCESS",

    ADMINISTRATION_EMAILTEMPLATES_UPDATE_REQUEST = "ADMINISTRATION_EMAILTEMPLATES_UPDATE_REQUEST",
    ADMINISTRATION_EMAILTEMPLATES_UPDATE_SUCCESS = "ADMINISTRATION_EMAILTEMPLATES_UPDATE_SUCCESS",
    
    ADMINISTRATION_EMAILTEMPLATES_DELETE_REQUEST = "ADMINISTRATION_EMAILTEMPLATES_DELETE_REQUEST",
    ADMINISTRATION_EMAILTEMPLATES_DELETE_SUCCESS = "ADMINISTRATION_EMAILTEMPLATES_DELETE_SUCCESS",

    ADMINISTRATION_PROSPECTS_LIST_DATA_RECEIVED = "ADMINISTRATION_PROSPECTS_LIST_DATA_RECEIVED",
    ADMINISTRATION_PROSPECTS_LIST_PAGE_DATA_REQUEST = "ADMINISTRATION_PROSPECTS_LIST_PAGE_DATA_REQUEST",
    ADMINISTRATION_PROSPECTS_LIST_PAGE_RESET = "ADMINISTRATION_PROSPECTS_LIST_PAGE_RESET",

    ADMINISTRATION_PROSPECTS_ADD_REQUEST = "ADMINISTRATION_PROSPECTS_ADD_REQUEST",
    ADMINISTRATION_PROSPECTS_ADD_SUCCESS = "ADMINISTRATION_PROSPECTS_ADD_SUCCESS",

    ADMINISTRATION_PROSPECTS_UPDATE_REQUEST = "ADMINISTRATION_PROSPECTS_UPDATE_REQUEST",
    ADMINISTRATION_PROSPECTS_UPDATE_SUCCESS = "ADMINISTRATION_PROSPECTS_UPDATE_SUCCESS",

    ADMINISTRATION_PROSPECTS_IMPORT_REQUEST = "ADMINISTRATION_PROSPECTS_IMPORT_REQUEST",
    ADMINISTRATION_PROSPECTS_IMPORT_SUCCESS = "ADMINISTRATION_PROSPECTS_IMPORT_SUCCESS",

    ADMINISTRATION_PROSPECTS_DELETE_REQUEST = "ADMINISTRATION_PROSPECTS_DELETE_REQUEST",
    ADMINISTRATION_PROSPECTS_DELETE_SUCCESS = "ADMINISTRATION_PROSPECTS_DELETE_SUCCESS",
 
    ADMINISTRATION_KEYWORDS_LIST_DATA_RECEIVED = "ADMINISTRATION_KEYWORDS_LIST_DATA_RECEIVED",
    ADMINISTRATION_KEYWORDS_LIST_PAGE_DATA_REQUEST = "ADMINISTRATION_KEYWORDS_LIST_PAGE_DATA_REQUEST",
    ADMINISTRATION_KEYWORDS_LIST_PAGE_RESET = "ADMINISTRATION_KEYWORDS_LIST_PAGE_RESET",

    ADMINISTRATION_KEYWORDS_ADD_REQUEST = "ADMINISTRATION_KEYWORDS_ADD_REQUEST",
    ADMINISTRATION_KEYWORDS_ADD_SUCCESS = "ADMINISTRATION_KEYWORDS_ADD_SUCCESS",

    ADMINISTRATION_KEYWORDS_UPDATE_REQUEST = "ADMINISTRATION_KEYWORDS_UPDATE_REQUEST",
    ADMINISTRATION_KEYWORDS_UPDATE_SUCCESS = "ADMINISTRATION_KEYWORDS_UPDATE_SUCCESS",

    ADMINISTRATION_KEYWORDS_IMPORT_REQUEST = "ADMINISTRATION_KEYWORDS_IMPORT_REQUEST",
    ADMINISTRATION_KEYWORDS_IMPORT_SUCCESS = "ADMINISTRATION_KEYWORDS_IMPORT_SUCCESS",

    ADMINISTRATION_KEYWORDS_DELETE_REQUEST = "ADMINISTRATION_KEYWORDS_DELETE_REQUEST",
    ADMINISTRATION_KEYWORDS_DELETE_SUCCESS = "ADMINISTRATION_KEYWORDS_DELETE_SUCCESS",


   
    ADMINISTRATION_JOBS_LIST_PAGE_DATA_REQUEST = "ADMINISTRATION_JOBS_LIST_PAGE_DATA_REQUEST",
    ADMINISTRATION_JOBS_LIST_PAGE_DATA_RECEIVED ="ADMINISTRATION_JOBS_LIST_PAGE_DATA_RECEIVED",
    ADMINISTRATION_JOBS_LIST_PAGE_RESET = "ADMINISTRATION_JOBS_LIST_PAGE_RESET",

    ADMINISTRATION_JOBS_ADD_REQUEST = "ADMINISTRATION_JOBS_ADD_REQUEST",
    ADMINISTRATION_JOBS_ADD_SUCCESS = "ADMINISTRATION_JOBS_ADD_SUCCESS",

    ADMINISTRATION_JOBS_UPDATE_REQUEST = "ADMINISTRATION_JOBS_UPDATE_REQUEST",
    ADMINISTRATION_JOBS_UPDATE_SUCCESS = "ADMINISTRATION_JOBS_UPDATE_SUCCESS",

    ADMINISTRATION_JOBS_DELETE_REQUEST = "ADMINISTRATION_JOBS_DELETE_REQUEST",
    ADMINISTRATION_JOBS_DELETE_SUCCESS = "ADMINISTRATION_JOBS_DELETE_SUCCESS",

    
    ATTACHMENT_API_ERROR = "ATTACHMENT_API_ERROR", 
    ATTACHMENT_UPLOAD_REQUEST  = "ATTACHMENT_UPLOAD_REQUEST", 
    ATTACHMENT_UPLOAD_SUCCESS  = "ATTACHMENT_UPLOAD_SUCCESS", 

    ROUTE_CHANGE  = "ROUTE_CHANGE", 

} 

 