import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';


export class AdminJobsService extends App.BaseAppService  {
 

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
    } 

        public getJobsListData(filter:Api.JobFilterVm|null=null, onAfterStateSet:Function|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
       const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminJob/getJobListData?${urlPrams}`;
        console.log("testing : getUsersListData");
        
        this.get(apiUrl,
       App.ActionTypes.ADMINISTRATION_JOBS_LIST_PAGE_DATA_RECEIVED,
           App.ActionTypes.ADMINISTRATION_API_ERROR 

            );

    }
     
    public createJob(data:Api.Job) {
        console.log("AdminJobsService.createUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminJob/createJob`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_JOBS_ADD_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }
    public updateJob(data:Api.Job) {
        console.log("AdminJobsService.updateUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminJob/updateJob`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_JOBS_UPDATE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    public deleteJob(jobId:number) {
        console.log("AdminJobsService.deleteUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminJob/deleteJob?jobId=${jobId}`;
        this.post(apiUrl, jobId, 
            App.ActionTypes.ADMINISTRATION_JOBS_DELETE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    

    
 
}
