import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';


export class AttachmentService extends App.BaseAppService  {
 

    constructor() { 

        super();

    }
      
  /*   public candidateUpload(sm:Api.Attachment, requestType:string=App.ActionTypes.ATTACHMENT_UPLOAD_REQUEST, errorType:string=App.ActionTypes.ATTACHMENT_API_ERROR) {

        this.upload(sm, App.ActionTypes.MY_ACCOUNT_CANDIDATE_UPLOAD_SUCCESS,App.ActionTypes.MY_ACCOUNT_CANDIDATE_API_ERROR);
    } */
  
    public upload(sm:Api.Attachment,
        successType:string=App.ActionTypes.ATTACHMENT_UPLOAD_SUCCESS, 
        errorType:string=App.ActionTypes.ATTACHMENT_API_ERROR
        ) {

        if(LIB.Common.isNullOrEmpty(sm?.file)){
            var err = "AttachmentService.upload sm or sm.file is empty!";
            console.error(err);
            //App.AppStore.dispatch(errorType,err);
            return;
        }


        console.log("AttachmentService.upload sm",sm);
        console.log("AttachmentService.upload file",sm.file);

        var formData = new FormData();
        formData.append('file', sm.file as any);
 
        LIB.FormDataHelper.setData(sm,formData);

        console.log("AttachmentService.upload formData",formData);


        const apiUrl = `${App.AppBase.appApiBaseUrl}/Attachment/upload`;
        this.post(apiUrl, formData, 
            successType,
            errorType
           ,'',null,null,false,true
            );
 
    }
    

    public uploadApi(sm:Api.Attachment|undefined, onSuccess: Function, onError: Function) {
        console.log("AttachmentService.upload sm",sm);

        if(sm==undefined)
         return ;

        var formData = new FormData();

        if(sm.file!=undefined)
        formData.append('file', sm.file);

      //  formData.append('attachmentId', sm.attachmentId.toString());
        
        if(sm.recordId!==undefined)
        formData.append('recordId', sm.recordId.toString());
        
        formData.append('fileName', sm.fileName);
       // formData.append('filePath', sm.filePath);


       // formData = LIB.FormDataHelper.setData(sm,formData);

        console.log("AttachmentService.upload formData",formData);
 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Attachment/upload`;

        console.log("AttachmentService.upload apiUrl",apiUrl);

        this.callApi(apiUrl, formData, onSuccess, onError,'POST',false,true);
    }
   
     
 

}