import * as React from 'react';
import * as LIB from '_LIB';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import { CSSProperties } from "react";
import * as App from 'AppReferences';


interface FullAddressProps {
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  onInputChange: Function;
  onAutocompleteChange: Function;
}


export class FullAddress extends React.Component<FullAddressProps, {}> {

  //public title:string;

  constructor(props: any) {
    super(props);
  }


  // This method is called when the component is first added to the document
  public componentDidMount() {


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('componentDidUpdate:'+this.state.visible);


  }


  public render() {

    console.log("FullAddress.render");

    var country = this.props.country;

    var getInputErrorProps = App.AppBase.getInputErrorProps;

   // if (LIB.Common.isNullOrEmpty(country))
   //   country = 'US';

    var theme = App.AppBase.theme;

    var formControl: CSSProperties = {
      margin: theme.spacing(2),
      //minWidth:'25rem',
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };
    var fullWidth: CSSProperties = {
      margin: theme.spacing(2),
      paddingRight: 20,
      marginLeft: 0


    };

    var formInput: CSSProperties = {

      height: 50,
      //padding:15
      // padding:'20px, 16px'
    };



    var countries = LIB.LocationCountryHelper.getCountries();
    var states = LIB.LocationStateHelper.getUSAStates();

    return <div>


      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} >
          <TextField
            name="address" label="Address"
            value={this.props.address || ''}
            {...getInputErrorProps('address')}
            onChange={this.props.onInputChange.bind(this)}
            style={formControl}
            inputProps={{ style: formInput }}

          />


        </Grid>

        <Grid item xs={12} sm={6}  >

          <App.FormAutocomplete
            label="Country"
            name="country"
            data={countries}
            labelProperty="name"
            valueProperty='code'
            onChange={this.props.onAutocompleteChange.bind(this)}
            value={countries.find(c => c.code === country) || ''}
          // textFieldStyle={textFieldStyle}
          />

        </Grid>


      </Grid>

      <Grid container spacing={2}>

        <Grid item xs={12} sm={5}
        >
          <TextField
            name="city" label="City"
            value={this.props.city || ''}
            {...getInputErrorProps('city')}
            onChange={this.props.onInputChange.bind(this)}
            style={formControl}
            inputProps={{ style: formInput }}
          />

        </Grid>

        <Grid item xs={12} sm={4}
        >

          <App.FormAutocomplete
            label="State"
            name="state"
            data={states}
            labelProperty="key"
            valueProperty='value'
            onChange={this.props.onAutocompleteChange.bind(this)}
            value={states.find(c => c.value === this.props.state) || ''}
          // textFieldStyle={textFieldStyle}
            hidden={country !== 'US'}
          />

          <div hidden={country === 'US'}>
            <TextField
              name="state" label="State"
              value={this.props.state || ''}
              {...getInputErrorProps('state')}
              onChange={this.props.onInputChange.bind(this)}
              style={formControl}
              inputProps={{ style: formInput }}

            />
          </div>
        </Grid>

        <Grid item xs={12} sm={3}  >
          <TextField
            name="zipCode" label="Zip/Postal Code"
            value={this.props.zipCode || ''}
            {...getInputErrorProps('zipCode')}
            onChange={this.props.onInputChange.bind(this)}
            style={formControl}
            inputProps={{ style: formInput }}
          />
        </Grid>


      </Grid>




    </div>;



  }
}
