import * as App from 'AppReferences';
import * as LIB from '_LIB';

export class GlobalState  extends LIB.BaseStateModel {
 
    public isInitialDataLoaded:boolean;
    public isAppInfoDataLoaded:boolean;
    public initialData:App.InitialDataVm;
    public isServiceCallPending:boolean;
    public globalUIState:App.GlobalUIStateVm;

    public params?:any;


     
    
    constructor() { 
 
        super();
        this.isInitialDataLoaded=false;
        this.isServiceCallPending = false;
        this.initialData= new App.InitialDataVm();
        this.globalUIState = new App.GlobalUIStateVm();
    }
      

}