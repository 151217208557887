import * as LIB from '_LIB';
import moment from "moment";


export class FormDataHelper {

 
  public static getFormData(data: any): FormData {
 
   var to = new FormData();

   var result = FormDataHelper.setData(data,to);

   return result;

  }
  
  public static setData(from: any, to: FormData): FormData {
 
   // console.log("FormDataHelper.setData");

  //  var toKeys = Object.keys(from)
    for (var key in from) {

     // if (toKeys.indexOf(key) === -1)
     //   continue;
        
      try {
 
       // console.log("FormDataHelper.setData typeof: "+key, typeof from[key]);
      //  console.log("FormDataHelper.setData value: "+key, from[key]);

         /* if(typeof from[key] === 'object' && from[key]!=undefined){
          to.append(key,from[key]);
        }   */
        if(typeof from[key] === 'object'){
          continue;
        }  
        else if(from[key]==undefined)
           to.append(key,'');
        else         
         to.append(key, from[key].toString());
 
      } catch(err) {

        console.error("FormDataHelper.setData: ",err);

       }

    }
 
    return to;
  }

}