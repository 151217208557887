import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdministrationJobReducers {


    constructor() {

    }

    public static getReducers(state: App.AdministrationState | undefined, action: App.AppAction): App.AdministrationState {
        var str = "";

        var result = new App.AdministrationState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ADMINISTRATION_JOBS"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);


        var adminJobsService = new App.AdminJobsService();

        // const action = incomingAction as KnownAction;
        switch (action.type) {
            case App.ActionTypes.ADMINISTRATION_JOBS_LIST_PAGE_DATA_REQUEST: {
                console.log('ADMINISTRATION_JOBS_LIST_PAGE_DATA_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var searchFilter = action.payload;
                
                // prospectsService.getProspectsListData(searchFilter);
                adminJobsService.getJobsListData(searchFilter);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_JOBS_LIST_PAGE_DATA_RECEIVED: {
                console.log('ADMINISTRATION_JOBS_LIST_DATA_RECEIVED:');
                result = { ...state };
                console.log("payload", action.payload);
                if (LIB.Common.isNullOrEmpty(result.jobSearchFilter?.keyword)) {
                    result.jobsFullList = action.payload.jobs;
                }
                result.jobs = action.payload.jobs;
                result.jobsCount = action.payload.jobsCount;
                result.jobStatusTypes = action.payload.jobStatusTypes;
                result.jobTypes = action.payload.jobTypes;
                result.listingStatusTypes = action.payload.listingStatusTypes;
                result.listingStatusTypes = action.payload.listingStatusTypes;
               
                

                result.isServiceCallPending = false;
                result.hasJobDataLoaded = true;
                console.log("result", result);
                break;
            }

            
            case App.ActionTypes.ADMINISTRATION_JOBS_LIST_PAGE_RESET: {
                console.log('ADMINISTRATION_JOBS_LIST_PAGE_RESET:');
                result = { ...state };
                console.log("payload", action.payload);
                result.jobs = result.jobsFullList; 
                result.jobSearchFilter.keyword = '';
              //  result.isServiceCallPending = false;
                console.log("result" , result);
                break;
            }
            case App.ActionTypes.ADMINISTRATION_JOBS_UPDATE_REQUEST: {
                console.log('ADMINISTRATION_JOBS_UPDATE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var data = action.payload;
                adminJobsService.updateJob(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_JOBS_UPDATE_SUCCESS: {
                console.log('ADMINISTRATION_JOBS_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = { ...action.payload };

                App.AdministrationManager.updateJob(item, result.jobs);

                vm.apiErrorMessage = ''; //to clear any api error msg
                vm.isJobsEditOpen = false;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;
                vm.setSelectedJob(item);//

                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_JOBS_ADD_REQUEST: {
                console.log('ADMINISTRATION_JOBS_ADD_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var data = action.payload;
                adminJobsService.createJob(data);
                result.isServiceCallPending = true;
                //result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_JOBS_ADD_SUCCESS: {
                console.log('ADMINISTRATION_JOBS_ADD_SUCCESS:', action.payload);

                result = { ...state };
                var vm = result.vm;
                var item = { ...action.payload };
                //  var item = vm.;

                console.log("item pushed: ", (item));
                // console.log(item.constructor.name); 

                result.jobs.splice(0, 0, item); //push at the beginning
                vm.apiErrorMessage = '';
                vm.isJobsAddOpen = false;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;

                vm.setSelectedJob(); //
                console.log("vm.setSelectedJob();:, ", vm.setSelectedJob);
                // result.adminCreateProspectSm = new Api.Prospect();


                result.forceUpdateCount++;
                break;
            }

            case App.ActionTypes.ADMINISTRATION_JOBS_DELETE_REQUEST: {
                console.log('ADMINISTRATION_JOBS_DELETE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var itemId = action.payload;

                adminJobsService.deleteJob(itemId);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_JOBS_DELETE_SUCCESS: {
                console.log('ADMINISTRATION_JOBS_DELETE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

                var itemId: any = vm.selectedJob?.jobId;
                var success = action.payload;

                if (success)
                    App.AdministrationManager.deleteJobById(itemId, result.jobs);

                vm.isJobsDeleteOpen = false;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;
                //vm.setSelectedUser();

                result.forceUpdateCount++;
                break;
            }




            default:
                result = state;
                //return result;
                break;
        }
        console.log('ADMINISTRATION_JOBS_DATA_RECEIVED result2:', result);
        //  console.log('AdministrationJob_DATA_RECEIVED action:',action);


        if (action.postActionCallBack) {
            console.log("postactioncallback");
            action.postActionCallBack(result);
        }
        return result;
    }



}