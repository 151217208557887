import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class UserManager {


  constructor() {

  }
  
  public static deleteById(userId: number, data: App.UserRecord[]): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.id === userId);
    data.splice(index, 1);
    return true;
  } 

  public static getById(userId: number, users: App.UserRecord[]) {

    if (!Array.isArray(users) || LIB.Common.isNullOrEmpty(userId) )
      return null;

    var found = users.find((item) => item.id === userId);
    
    //console.log('getById', found, userId, users );
    //console.log('getById2',  users.find((item)=>item.id === 1108));
 
    if(LIB.Common.isNullOrEmpty(found))
      return null;
 
    return found;
  }

  
 
  public static update(item: Api.AppUser, data: Api.AppUser[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.userId == undefined || item?.userId < 1)
      return false;

    let index = data.findIndex((c) => c.userId === item.userId);
    if (index < 0)
      return false;
      
    data[index] = item;
  
    return true;
  }

   




}