
import * as Api from 'app-api';

export class AppInfoVm  {

    appBaseUrl:string;
    appApiBaseUrl:string;
    appWebsiteUrl:string;
    appPath:string;
    appName:string = "InfluencerWork";
    appVersion:string;
    appReleaseDate:string;
    appBaseFileBrowseUrl:string;
    envName:string;
    dashboardEmbedUrl:string;
    supportEmail:string;
     
    constructor() { 

       
    }
     


}