import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';
import { Table, TableBody, TableCell, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';


export class JobService extends App.BaseAppService {


    constructor() {

        super();
        //this.isAccessTokenRequired = true;
    }

    public getJobListData(filter: Api.JobFilterVm | null = null, onAfterStateSet: Function | null = null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Job/getJobListData?${urlPrams}`;

        this.get(apiUrl,
            App.ActionTypes.JOB_LIST_PAGE_DATA_RECEIVED,
            App.ActionTypes.JOB_LIST_API_ERROR,
            "", null, onAfterStateSet
        );

    }
 

    public showSuccessMessage() {

        var msg = <div>
            Successfully Sent
            <br />
            Go to
            <RouterLink style={{ marginRight: 5, marginLeft: 5 }}
                to={'/MyAccount/CandidateSubmits'}
                //onClick={(e: any) => this.onNameClick(id)}
                color="primary">My Candidate Submits</RouterLink>
            to see your Candidate Submits and follow-up
        </div>

        App.AppBase.showMessage(msg, false, 8000);


      /*   var vm = new App.GlobalUIStateVm();
        vm.selectLeftMenuItem = '/MyAccount/CandidateSubmits';
        App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_SELECT_LEFT_MENU, vm);
 */
        var state = App.AppBase.getState();
        var vm = state.globalData.globalUIState;
        vm.countCandidateSubmits++;
        vm.selectLeftMenuItem = '/MyAccount/CandidateSubmits';
        App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);
 

    }
/* 
    public submitCandidate(sm: Api.CandidateRequestResumeSm) {

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Job/submitCandidate`;

        this.post(apiUrl, sm,
            App.ActionTypes.JOB_LIST_SUBMIT_CANDIDATE_SUCCESS,
            App.ActionTypes.JOB_LIST_API_ERROR, '',
            () => this.showSuccessMessage()
        );
    }
 */


}
