import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class GlobalDataService extends App.BaseAppService  {
 

    constructor() { 

        super();

    }

    public getAppInfoData() {

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        console.log('getAppInfoData: apiBaseUrl',apiBaseUrl);
        console.log('getAppInfoData: apiBaseUrl2',App.AppBase.appApiBaseUrl);
       
       // var srv = new Api.GlobalDataService();
       // srv.getInitialData().then(x=>)
        const apiUrl = `${App.AppBase.appApiBaseUrl}/GlobalData/getAppInfoData`;
 
        this.get(apiUrl, App.ActionTypes.GLOBAL_APPINFO_DATA_RECEIVED,
            App.ActionTypes.GLOBAL_API_ERROR
            ,"",null,null,false //token not required 
            );
    }
     
    public getInitialData(onAfterSuccess:Function|null=null) {

        const apiUrl = `${App.AppBase.appApiBaseUrl}/GlobalData/getInitialData`;
 
        this.get(apiUrl, App.ActionTypes.GLOBAL_INITIAL_DATA_RECEIVED,
            App.ActionTypes.GLOBAL_API_ERROR,
            "",onAfterSuccess
            );
    }


}