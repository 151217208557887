import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdministrationKeywordReducers {


    constructor() {

    }

    public static getReducers(state: App.AdministrationState | undefined, action: App.AppAction): App.AdministrationState {
        var str = "";

        var result = new App.AdministrationState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ADMINISTRATION_KEYWORDS"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

      // var adminUsersService = new App.AdminUsersService();
        var keywordsService = new App.KeywordsService();

        
        // const action = incomingAction as KnownAction;
        switch (action.type) {
 
            case App.ActionTypes.ADMINISTRATION_KEYWORDS_LIST_PAGE_DATA_REQUEST: {
                console.log('ADMINISTRATION_KEYWORDS_LIST_PAGE_DATA_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var searchFilter = action.payload;
                keywordsService.getKeywordsListData(searchFilter);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }

            case App.ActionTypes.ADMINISTRATION_KEYWORDS_LIST_DATA_RECEIVED: {
                console.log('ADMINISTRATION_KEYWORDS_LIST_DATA_RECEIVED:');
                result = { ...state };
                console.log("payload", action.payload);
                if (LIB.Common.isNullOrEmpty(result.keywordSearchFilter?.keyword)) {
                    result.keywordsFullList = action.payload.keywords;
                }
                result.keywords = action.payload.keywords; 
                result.keywordsCount = action.payload.keywordsCount; 

                
                result.isServiceCallPending = false;
               result.hasKeywordDataLoaded = true;
                console.log("result" , result);
                break;
            }
            case App.ActionTypes.ADMINISTRATION_KEYWORDS_LIST_PAGE_RESET: {
                console.log('ADMINISTRATION_KEYWORDS_LIST_PAGE_RESET:');
                result = { ...state };
                console.log("payload", action.payload);
                result.keywords = result.keywordsFullList;
                result.keywordSearchFilter.keyword = '';
              //  result.isServiceCallPending = false;
             //  result.hasKeywordDataLoaded = true;
                console.log("result" , result);
                break;
            }
            
             case App.ActionTypes.ADMINISTRATION_KEYWORDS_UPDATE_REQUEST: {
                console.log('ADMINISTRATION_KEYWORDS_UPDATE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                keywordsService.update(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_KEYWORDS_UPDATE_SUCCESS: {
                console.log('ADMINISTRATION_KEYWORDS_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = {... action.payload };
               
                App.AdministrationManager.update(item,result.appUsers);
                
                vm.apiErrorMessage=''; //to clear any api error msg
                vm.isKeywordsEditOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                vm.setSelectedUser(item);//
                  
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_KEYWORDS_ADD_REQUEST: {
                console.log('ADMINISTRATION_KEYWORDS_ADD_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                keywordsService.create(data);
                result.isServiceCallPending = true;
                //result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_KEYWORDS_ADD_SUCCESS: {
                console.log('ADMINISTRATION_KEYWORDS_ADD_SUCCESS:', action.payload);

                result = { ...state };
                var vm = result.vm; 
                var item = {... action.payload };
                              //  var item = vm.;

                console.log("item pushed: ", (item));
               // console.log(item.constructor.name); 

                result.keywords.splice(0, 0, item); //push at the beginning
               vm.apiErrorMessage='';
                vm.isKeywordsAddOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;

                vm.setSelectedUser(); //
                console.log("vm.setSelectedUser();:, ", vm.selectedUser);
                result.adminCreateKeywordSm = new Api.Keyword();
  
                
               result.forceUpdateCount++;
                break;
            }
              
            case App.ActionTypes.ADMINISTRATION_KEYWORDS_DELETE_REQUEST: {
                console.log('ADMINISTRATION_KEYWORDS_DELETE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var itemId = action.payload;

                keywordsService.deleteKeyword(itemId);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_KEYWORDS_DELETE_SUCCESS: {
                console.log('ADMINISTRATION_KEYWORDS_DELETE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

                var itemId:any = vm.selectedKeyword?.keywordId;
                var success = action.payload;
 
                if(success)
                    App.AdministrationManager.deleteKeywordById(itemId,result.keywords);
                
                vm.isKeywordDeleteOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                //vm.setSelectedUser();
                  
                result.forceUpdateCount++;
                break;
            }   
           

            case App.ActionTypes.ADMINISTRATION_KEYWORDS_IMPORT_REQUEST: {
                console.log('ADMINISTRATION_KEYWORDS_IMPORT_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                keywordsService.import(data, 
                    (() => App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_KEYWORDS_LIST_PAGE_DATA_REQUEST)));
                result.isServiceCallPending = true;
                //result.forceUpdateCount++;
                break;
            }

            case App.ActionTypes.ADMINISTRATION_KEYWORDS_IMPORT_SUCCESS: {
                console.log('ADMINISTRATION_KEYWORDS_IMPORT_SUCCESS:', action.payload);

                result = { ...state };
                var vm = result.vm; 
             //   var item = {... action.payload };
              //  console.log("item pushed: ", (item));
               // console.log(item.constructor.name); 

              //  result.keywords.splice(0, 0, item); //push at the beginning
               vm.apiErrorMessage='';
                vm.isKeywordsBulkImportOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;

             //   vm.setSelectedUser(); //
              //  console.log("vm.setSelectedUser();:, ", vm.selectedUser);
                result.adminBulkImportKeywordsSm = new Api.BulkKeywordsSm();

                var msg = `Successfully Imported, New Keyword Count:${action.payload}`;
                App.AppBase.showMessage(msg);

               result.forceUpdateCount++;
                break;
            }

            
            
            default:
                result = state;
                //return result;
                break;
        }
        console.log('ADMINISTRATION_KEYWORDS_DATA_RECEIVED result2:',result);
      //  console.log('AdministrationKeyword_DATA_RECEIVED action:',action);


        if (action.postActionCallBack){
            console.log("postactioncallback");
                action.postActionCallBack(result);
            }
        return result;
    }



}