import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


import { AdministrationJobsTable } from './AdministrationJobsTable';
import { AdministrationJobsAddEditModal } from './AdministrationJobsAddEditModal';



class AdministrationJobs extends React.Component<App.AdministrationState, {}> {

  public adminJobsService: App.AdminJobsService;

  constructor(props: any) {
    super(props);
    this.adminJobsService = new App.AdminJobsService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationJobs componentDidMount');

    if (!this.props.hasJobDataLoaded) {
      console.log('AdministrationJobs getAdministrationJobsData');

      App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_JOBS_LIST_PAGE_DATA_REQUEST, null);

     
    }


  }
 

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationJobs componentDidUpdate');

  }

  public onInputChange(newVal: any) {
    console.log('onInputChange:' + newVal);


  }


  public onCreateClick() {
    console.log('onCreateClick');
    var state = this.props;
    var vm = state.vm;

    vm.setSelectedJob();

    var item = vm.selectedJob;

    vm.isJobsAddOpen = true;

    console.log("set ownerId to curentuser");
    item.ownerId = App.AppBase.currentUser.userId;
    
    this.forceUpdate();

  }

  
  public onDeleteConfirmClick() {
    console.log('onDeleteConfirmClick');
 
    var state = this.props;
    var vm = state.vm;
  
    var itemId = vm.selectedJob?.jobId;

    console.log('onDeleteConfirmClick itemId',itemId);

    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_JOBS_DELETE_REQUEST,itemId);
  
    vm.isActionInProgress=true;

    this.forceUpdate();
 
  }

  public onSearchClick() {
    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.jobSearchFilter;
    searchFilter.isSearch=true;
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_JOBS_LIST_PAGE_DATA_REQUEST, searchFilter);

  }

  public render() {

   // console.log('AdministrationJobs render');

    var state = this.props;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;
    var searchFilter = state.jobSearchFilter;


    // console.log('AdministrationJobs hasJobDataLoaded', state.hasJobDataLoaded);
    // console.log('AdministrationJobs isServiceCallPending',this.myListingsService.isServiceCallPending);
    // console.log('AdministrationJobs vm.isActionInProgress',vm.isActionInProgress);


    var createBtn: any = null;

    if (currentUser.hasRole(App.UserRoleTypeEnum.BenchRecruiter)) {
      createBtn = <Button
                  id="btnCreate"
                  variant="contained"
                  style={{ marginBottom: 10 }}
                  color="primary"

                  onClick={() => this.onCreateClick()}
                >Create</Button>
    }


    return <App.MainPage
      pageTitle="Administration > Jobs"
      state={vm}
      hasDataLoaded={state.hasJobDataLoaded}
      loading={(state.isServiceCallPending && !vm.isActionInProgress)}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      errorMessage={state.apiErrorMessage}
      isChildPage={true}
    >
      <div style={{   width: '85vw' }} >
      {<LIB.MSearchBar
          filter={searchFilter}
          onSearchClick={() => this.onSearchClick()}
          isSearchInProgress={state.isServiceCallPending}
        />}

        <div style={{ textAlign: 'left' }} >

          {createBtn}

        </div>

        <AdministrationJobsTable state={state} onChange={() => this.forceUpdate()} />



        <AdministrationJobsAddEditModal state={state} onChange={() => this.forceUpdate()} />


      </div>



      <LIB.ConfirmModal 
    visible={vm.isJobsDeleteOpen}
    onCancelClick={ ()=>{vm.isJobsDeleteOpen=false;this.forceUpdate();}}
    onConfirmClick={()=>this.onDeleteConfirmClick()}
    title={`Confirm Delete: (${vm.selectedJob?.jobId}) ${vm.selectedJob?.title}`}
    content={`Are you sure you want to delete "${vm.selectedJob?.title}" job?`}
    confirmButtonLabel="Delete" 
    disabled={vm.isActionInProgress}
    />
 

    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.administrationState })
)(AdministrationJobs as any);

