import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class RouterStateReducers {


    constructor() {

    }

    public static getActionHandlersReducers(state: App.RouterState | undefined, action: App.AppAction): App.RouterState {
        var str = "";

        var result = new App.RouterState(App.AppBase.history);

        if (state === undefined) {
            return result;
        }

      //  console.log('ROUTE_CHANGE, action', action);

        if (!action.type.startsWith("ROUTE"))
            return state;

        var appState = action.appState;
       

        // const action = incomingAction as KnownAction;
        switch (action.type) {
 
            case App.ActionTypes.ROUTE_CHANGE: {
                console.log('ROUTE_CHANGE, action', action.payload);
                console.log(action.payload);
                result = { ...state };
                result = state;
                result.action = action.payload.action;
                result.location = action.payload.location;
                result.history = action.payload.history;

             //   App.AppBase.resetErrorMessages();
             //   appState.globalData.globalUIState.apiErrorMessage = "";

              
                break;
            }
            default:
                result = state;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;
    }



}