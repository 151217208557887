import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';


export class KeywordsService extends App.BaseAppService  {
 

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
    } 

        public getKeywordsListData(filter:Api.KeywordFilterVm|null=null, onAfterStateSet:Function|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
       const apiUrl = `${App.AppBase.appApiBaseUrl}/Keywords/getKeywords?${urlPrams}`;
        console.log("testing : getKeywordsListData");
        
        this.get(apiUrl,
       App.ActionTypes.ADMINISTRATION_KEYWORDS_LIST_DATA_RECEIVED,
           App.ActionTypes.ADMINISTRATION_API_ERROR 

            );

    }
     
     public create(data:Api.Keyword) {
        console.log("KeywordsService.create");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Keywords/create`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_KEYWORDS_ADD_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    public update(data:Api.Keyword) {
        console.log("KeywordsService.update");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Keywords/update`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_KEYWORDS_UPDATE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    public import(data:Api.BulkKeywordsSm, onAfterSuccess:Function|null=null) {
        console.log("KeywordsService.import");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Keywords/import`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_KEYWORDS_IMPORT_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR, '', onAfterSuccess
            );
 
    }

    public deleteKeyword(keywordId:number) {
        console.log("KeywordsService.deleteKeyword");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Keywords/deleteKeyword?keywordId=${keywordId}`;
        this.post(apiUrl, keywordId, 
            App.ActionTypes.ADMINISTRATION_KEYWORDS_DELETE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    
    
 
}
