import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Snackbar, TextareaAutosize
} from '@mui/material';
import { Table, Card, CardContent, Paper, Container, Box, Backdrop, CircularProgress, InputAdornment, FormControl, FormHelperText } from '@mui/material';

import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';



interface AdministrationKeywordsBulkImportModalProps {
  state: App.AdministrationState;
  onChange?: Function;
}


export class AdministrationKeywordsBulkImportModal extends React.Component<AdministrationKeywordsBulkImportModalProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
     console.log('AdministrationKeywordsBulkImportModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
     console.log('AdministrationKeywordsBulkImportModal componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onCloseClick() {
    console.log("onCloseClick");
    var state = this.props.state;
    var vm = state.vm;
    var bulkKeywords = state.adminBulkImportKeywordsSm;

    //reset to defaults
    bulkKeywords.keywordsText = "";

    vm.isKeywordsBulkImportOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

     if (this.props.onChange)
      this.props.onChange();

  }

  
  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange', e.target.name, e.target.value);

    var state = this.props.state;
    var vm = state.vm;
   var item = state.adminBulkImportKeywordsSm; 
   item.keywordsText = e.target.value;

     console.log("adminBulkImportKeywordsInput",  e.target.value, item, state.adminBulkImportKeywordsSm)
    var target: any = e.target;
 

    this.forceUpdate();
  }

  public  onBulkImportClick(e: React.ChangeEvent<{}>) {

    var state = this.props.state;
    var vm = state.vm;
    console.log('adminBulkImportKeywordsInput', state.adminBulkImportKeywordsSm);

    var sm = state.adminBulkImportKeywordsSm;
 
    var isValid = this.validationManager.validateBulkImportKeywords(null, sm);

    if (!isValid) {
      console.log('validateBulkImport NOT valid, ', App.AppBase.lastErrorMessage);
      this.forceUpdate();
      return;
    }

    //call api
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_KEYWORDS_IMPORT_REQUEST, sm ); 

    vm.isActionInProgress = true;

    this.forceUpdate();
  }

  public getForm() {
    console.log('AdministrationKeywordsBulkImportModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = state.adminBulkImportKeywordsSm;


    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    /* var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    }; */

    var formControl: CSSProperties = {
      margin: theme.spacing(2),
      //minWidth:'25rem',
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    /* var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    }; */
 

    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasKeywordDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
    //  errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
{     

        <form>
          {/*   add div https://stackoverflow.com/questions/56156589/simple-material-ui-dialog-example-has-unwanted-scrollbar
 */}      <div style={{ padding: 16, width: '30vw' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                {/* <textarea 
                  name="bulkKeywords"
                  value={item.keywordsText || "Enter one keyword per line"}
                  rows={20}
                  cols={40}
                  onChange={(e: any) => this.onInputChange(e)}
                /> */}
                <TextField
                  name="bulkKeywords"
                  label="Enter one keyword per line"
                  value={item.keywordsText || ""}
                  {...App.AppBase.getInputErrorProps('bulkKeywords')}
                  //error={...App.AppBase.getInputErrorProps('bulkKeywords')}
                //  helperText="{...App.AppBase.getInputErrorProps('bulkKeywords')}"
                  multiline
                  rows={7}
                 // cols={400}
                  onChange={(e: any) => this.onInputChange(e)}
                  variant="outlined"
                  fullWidth
                />


      
            

            <LIB.MCheckbox {...App.AppBase.getInputErrorProps('reset')}
                  formControlLabel={<FormControlLabel
                    control={
                      <Checkbox
                        name="reset"
                        color="primary"

                        checked={item.reset}
                        onChange={(e: any) => this.onInputChange(e)}
                        //style={fullWidth}
                        inputProps={{ style: formInput }}

                      />
                    }
                    style={{ marginLeft: -10, marginTop: 5 }}
                    labelPlacement="end"
                    label={<span style={{ fontSize: '16px' }}>
                      Reset
                    </span>}
                  />}
                >
                  
                </LIB.MCheckbox>

                </Grid>
              

              </Grid >

          </div>
        </form>

}

    </App.MainPage>;


  }


  /* public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  } */

  public render() {
    console.log('AdministrationKeywordsBulkImportModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = vm.isKeywordsBulkImportOpen;
    var sm = state.adminBulkImportKeywordsSm;

    if (!isOpen)
      return;

     var title = "Bulk Import Keywords";
    
    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >{title}</DialogTitle>
          <DialogContent >

            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <Button
              variant="contained"
              //color="default"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</Button>

            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={this.onBulkImportClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Import</Button>



          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 