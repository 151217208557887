import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdminUserVm extends LIB.BaseVmModel {

    public selectedUserRoleType:App.UserRoleType; 
    public selectedUserImpersonator:App.AppUser;
    public filteredImpersonateUserList:App.AppUser[];
   
    constructor() { 

        super();
        this.filteredImpersonateUserList=[];
        
    }
     


}