import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField,Box,Button,Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';

/* 
interface TestProps {
  //vm: App.ProjectListState;
} */

//class Test extends React.Component<TestProps, {}> {
class NotFound extends React.Component<App.GlobalState, {}> {


  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('Test componentDidMount');

    // LIB.LocalStorageHelper.removeByKeyword("msal");
    // LIB.LocalStorageHelper.removeByKeyword("Test.microsoftonline.com");



  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('Test componentDidUpdate');
  }


   
  public render() {

    console.log("Test Render, isUserAuthenticated", App.AppBase.isUserAuthenticated);

    var state = this.props;
    var vm = state.initialData;
    
  
     return <App.MainPage
     pageTitle="Page Not Found"
     state={vm}
     hasDataLoaded={true}
 
   >
     <div>Page Not Found, please go to home page!</div>

      </App.MainPage>  ;

  }
};
export default connect(
  (state: App.AppState) => ({...state.globalData})
)(NotFound as any);
