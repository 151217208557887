import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class MyAccountState  extends LIB.BaseStateModel {
 
  
    public vm:App.MyAccountVm;
 
    public jobs:Api.Job[];
 
    public jobStatusTypes:Api.JobStatusType[];
    public jobTypes:Api.JobType[];
 
   
    
    public jobSearchFilter:Api.JobFilterVm = new Api.JobFilterVm();

    public hasJobsDataLoaded:boolean=false;
    public hasCandidateDataLoaded:boolean=false;


    public jobSm:Api.Job = new Api.Job();
    public isCandidateCreateUpdatePending: boolean = false;

 
    public hasResumeRequestsLoaded:boolean=false;
    public hasCandidateSubmitsLoaded:boolean=false;



    constructor() { 
        
        super();
       
        this.vm = new App.MyAccountVm();

      
    }
     


}