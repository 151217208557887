import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Snackbar } from '@mui/material';
//import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as LIB from '_LIB';
import Alert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import { Checkbox, FormControlLabel, FormControl, FormHelperText } from '@mui/material';


import { createStyles,  Theme } from '@mui/material/styles';

//import './AccountMenuItem.css'
  
interface MCheckboxProps {
  formControlLabel:any;
  error?:boolean,
  helperText:string 
}

export class MCheckbox extends React.Component<MCheckboxProps, {}> {

  constructor(props: any) {
    super(props);
    //  this.state = { visible: false, isCancel:false };

  }
  
  // This method is called when the component is first added to the document
  public componentDidMount() {

    
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('componentDidUpdate:'+this.state.visible);

    
  } 
  
  public render() {
 
    return (
      <FormControl error={this.props.error} > 
      {this.props.formControlLabel}
       <FormHelperText>{this.props.helperText}</FormHelperText>
      </FormControl>
    );



  }
} 
