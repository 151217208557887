import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import { Table, Typography, NativeSelect, InputLabel, FormControl, Container, Button, Select, Backdrop, MenuItem, CircularProgress } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import { Tabs, Tab } from '@mui/material';
import * as Api from 'app-api';


import AdministrationUsers from './AdministrationUsers/AdministrationUsers';
import AdministrationRoles from './AdministrationRoles/AdministrationRoles';
import AdministrationEmailTemplates from './AdministrationEmailTemplates/AdministrationEmailTemplates';
import DailyEmails from './DailyEmails/DailyEmails';
import AdministrationProspects from './AdministrationProspects/AdministrationProspects';
import AdministrationJobs from './AdministrationJobs/AdministrationJobs';
import AdministrationKeywords from './AdministrationKeywords/AdministrationKeywords';

 

class Administration extends React.Component<App.AdministrationState, {}> {

  // public projectDataService: App.ProjectDataService;

  public validationManager: App.ValidationManager;


  constructor(props: any) {

    super(props);
    //  this.projectDataService = new App.ProjectDataService();
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('Administration componentDidMount');
    var vm = this.props.vm;

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('Administration componentDidUpdate');

  }




  public onTabChange(e: React.ChangeEvent<{}>, newValue: number) {
    console.log('onTabChange');

    //var project = this.props.project;

    // if (LIB.Common.isNullOrEmpty(project) || LIB.Common.isNullOrEmpty(project.aliasId))
    //   return;

    var id: string = (e.currentTarget as any).id;
    if (!LIB.Common.isNullOrEmpty(id))
      id = id.replace('Tab', '');

    var vm = this.props.vm;
    vm.selectedTabIndex = newValue;

    vm.selectedTab = id;
    //  App.AppBase.history.push(`MyListings?id=${project.aliasId}&tab=${vm.selectedTab}`);

    this.forceUpdate();
  }


  private getTabList(): string[] {

    var result: string[] = [];
    var currentUser = App.AppBase.currentUser;

    result.push("Users");
    result.push("Roles");
    result.push("Email Templates");
    result.push("Daily Emails");
    result.push("Prospects");
    result.push("Candidates");


    return result;

  }


  public getTabs() {
    console.log('getTabs');

    var theme = App.AppBase.theme;
    var vm = this.props.vm;
    var state = this.props;


    var selected = vm.selectedTabIndex;
    var theme = App.AppBase.theme;
    var tabList = this.getTabList();
    var startUrl = `/Administration/`;

    return <App.SmartTabs
      //  tabList={tabList}
      tabList={[]}
      selectedTab={vm.selectedTab}
      startUrl={startUrl}
      forceUpdate={() => { this.forceUpdate(); }}
      onSelectedChange={(newTab: string) => {
        console.log("onSelectedChange newTab", newTab);
        vm.selectedTab = newTab;
        this.forceUpdate();
      }}
    >
      {/* <LIB.TabPanel name="Home" selectedKey={vm.selectedTab}>
        Home
      
      </LIB.TabPanel> */}

      <LIB.TabPanel name="Users" selectedKey={vm.selectedTab}>
        <AdministrationUsers />
      </LIB.TabPanel>
      <LIB.TabPanel name="Roles" selectedKey={vm.selectedTab}>
        <AdministrationRoles />
      </LIB.TabPanel>
      <LIB.TabPanel name="EmailTemplates" selectedKey={vm.selectedTab}>
        <AdministrationEmailTemplates />
      </LIB.TabPanel>
      <LIB.TabPanel name="DailyEmails" selectedKey={vm.selectedTab}>
        <DailyEmails />
      </LIB.TabPanel>
      <LIB.TabPanel name="Prospects" selectedKey={vm.selectedTab}>
        <AdministrationProspects />
      </LIB.TabPanel>
      <LIB.TabPanel name="Keywords" selectedKey={vm.selectedTab}>
      <AdministrationKeywords />
      </LIB.TabPanel>
      <LIB.TabPanel name="Jobs" selectedKey={vm.selectedTab}>
      <AdministrationJobs />
      </LIB.TabPanel>


    </App.SmartTabs>;


  }

  public render() {
    console.log('Administration render');

    var state = this.props;
    var vm = this.props.vm;
    var currentUser = App.AppBase.currentUser;

    if (!currentUser?.isAdmin) {

      return;

    }

    return <App.MainPage
      pageTitle=''
      state={vm}
      //hasDataLoaded={true}
      // loading={(state.isServiceCallPending && !vm.isActionInProgress)}
      // inProgress={vm.isActionInProgress}
      // showContentWhenInProgress={(!state.isServiceCallPending || vm.isActionInProgress)}
      showError={false}
      showLeftMenu={true}
    >

      <div style={{marginLeft:-17}}> {/* adjust left margin since tab menu no longer used */}

        {this.getTabs()}

      </div>

    </App.MainPage>;

  }
};
export default connect(
  (state: App.AppState) => ({ ...state.administrationState })
)(Administration as any);
